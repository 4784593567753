
import * as React from 'react';

export interface IExcelPreviewImage { }
export const ExcelPreviewImage: React.FunctionComponent<IExcelPreviewImage> = (props) => {

    return (
        <div className="nag-excelPreviewOuter">
            <div className="nag-excelLogo"></div>
        </div>
    )
}