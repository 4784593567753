import { SearchResult } from ".";
import { Refiner } from "../api/sharePointSearch";

export const BEGIN_REFINEMENT_SEARCH = "BEGIN_REFINEMENT_SEARCH";
export const REFINEMENT_SEARCH_SUCCESS = "REFINEMENT_SEARCH_SUCCESS";
export const REFINEMENT_SEARCH_FAILED = "REFINEMENT_SEARCH_FAILED";
export const ADD_CONTENT_SEARCH_REFINER = "ADD_CONTENT_SEARCH_REFINER";
export const REMOVE_CONTENT_SEARCH_REFINER = "REMOVE_CONTENT_SEARCH_REFINER";
export const BEGIN_CONTENT_SEARCH = "BEGIN_CONTENT_SEARCH";
export const CONTENT_SEARCH_SUCCESS = "CONTENT_SEARCH_SUCCESS";
export const CONTENT_SEARCH_FAILED = "CONTENT_SEARCH_FAILED";
export const BEGIN_CONTENT_SEARCH_MORE = "BEGIN_CONTENT_SEARCH_MORE";
export const CONTENT_SEARCH_SUCCESS_MORE = "CONTENT_SEARCH_SUCCESS_MORE";
export const CONTENT_SEARCH_FAILED_MORE = "CONTENT_SEARCH_FAILED_MORE";
export const UPDATE_CONTENT_SEARCH_TERM = "UPDATE_CONTENT_SEARCH_TERM";
export const BEGIN_PROMOTED_CONTENT_SEARCH = "BEGIN_PROMOTED_CONTENT_SEARCH";
export const CLEAR_PROMOTED_RESULTS = "CLEAR_PROMOTED_RESULTS";
export const PROMOTED_CONTENT_SEARCH_SUCCESS = "PROMOTED_CONTENT_SEARCH_SUCCESS";
export const PROMOTED_CONTENT_SEARCH_FAILED = "PROMOTED_CONTENT_SEARCH_FAILED";

export interface AddContentSearchRefinerAction {
    type: typeof ADD_CONTENT_SEARCH_REFINER;
    refiner: string;
}

export interface RemoveContentSearchRefinerAction {
    type: typeof REMOVE_CONTENT_SEARCH_REFINER;
    refiner: string;
}

export interface BeginRefinementSearchAction {
    type: typeof BEGIN_REFINEMENT_SEARCH,
}

export interface RefinementSearchSuccessAction {
    type: typeof REFINEMENT_SEARCH_SUCCESS,
    resultCount: number,
    results: SearchResult[],
    resultRefiners: Refiner[],
    resultTerm: string,
    summary: string,
}

export interface RefinementSearchFailedAction {
    type: typeof REFINEMENT_SEARCH_FAILED
}

export interface BeginContentSearchMoreAction {
    type: typeof BEGIN_CONTENT_SEARCH_MORE,
}

export interface ContentSearchMoreSuccessAction {
    type: typeof CONTENT_SEARCH_SUCCESS_MORE,
    results: SearchResult[],
    moreResultsStatus?: "" | "Loading..."
}

export interface ContentSearchMoreFailedAction {
    type: typeof CONTENT_SEARCH_FAILED_MORE
}

export interface BeginContentSearchAction {
    type: typeof BEGIN_CONTENT_SEARCH,
    status: string
}

export interface ContentSearchSuccessAction {
    type: typeof CONTENT_SEARCH_SUCCESS,
    resultCount: number,
    results: SearchResult[],
    resultRefiners: Refiner[],
    resultTerm: string,
    summary: string,
    moreResultsStatus?: "" | "Loading..."
}

export interface ContentSearchFailedAction {
    type: typeof CONTENT_SEARCH_FAILED
}

export interface UpdateContentSearchTermAction {
    type: typeof UPDATE_CONTENT_SEARCH_TERM,
    query: string,
}

export interface BeginPromotedContentSearchAction {
    type: typeof BEGIN_PROMOTED_CONTENT_SEARCH
}

export interface PromotedContentSearchSuccessAction {
    type: typeof PROMOTED_CONTENT_SEARCH_SUCCESS,
    results: SearchResult[]
}

export interface PromotedContentSearchFailedAction {
    type: typeof PROMOTED_CONTENT_SEARCH_FAILED
}

export interface ClearPromotedResultsAction {
    type: typeof CLEAR_PROMOTED_RESULTS
}

export type ContentSearchActionTypes = BeginContentSearchAction
    | ContentSearchSuccessAction
    | ContentSearchFailedAction
    | BeginContentSearchMoreAction
    | ContentSearchMoreSuccessAction
    | ContentSearchMoreFailedAction
    | UpdateContentSearchTermAction
    | AddContentSearchRefinerAction
    | RemoveContentSearchRefinerAction
    | RefinementSearchSuccessAction
    | RefinementSearchFailedAction
    | BeginRefinementSearchAction
    | BeginPromotedContentSearchAction
    | PromotedContentSearchSuccessAction
    | PromotedContentSearchFailedAction
    | ClearPromotedResultsAction