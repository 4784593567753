import { Dictionary } from "../types";


export function joinTeam(token: string, groupId: string, userId: string): Promise<boolean> {

    return new Promise<boolean>((resolve, reject) => {

        const joinUrl = `https://graph.microsoft.com/v1.0/groups/${groupId}/members/$ref`;

        let payload: Dictionary<string> = {}
        payload["@odata.id"] = `https://graph.microsoft.com/v1.0/directoryObjects/${userId}`

        var headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", `application/json`);


        fetch(joinUrl, { headers: headers, method: "POST", body: JSON.stringify(payload) })
            .then(resp => {
                if (resp.ok) {
                    resolve(true);
                }
                else {
                    reject(resp.statusText);
                }
            },
            err => {
                reject(err);
            })
            .catch(reason => {
                reject(reason);
            });
    });
}