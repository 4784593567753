import doFetch from "./doFetch";
import { SharePointTenantProperties, SharePointField, SharePointClassification } from "../types/sharePointTenantProperties";


const getProperty = async (sharePointRootUrl: string, token: string, property: string): Promise<any> => {

    const url = `${sharePointRootUrl}/_api/web/GetStorageEntity('${property}')`;

    try {
        const text = await doFetch(url, token);

        var data = JSON.parse(text);

        return data.Value;

    }
    catch (err) {
        console.log(err);
        return null;
    }
};

interface IconPropsResult {
    iconName: string;
}

interface FieldResult {
    Type: "ManagedMetadata" | "Text" | "Boolean",
    StaticName: string,
    DisplayName: string,
    SearchAlias: string,
    TermSetId?: string,
    Required?: boolean,
    HiddenInWizard?: boolean,
    MultiSelect: boolean,
    Value: string | boolean | string[];
}

interface TemplateResult {
    key: string;
    text: string;
    iconProps?: IconPropsResult;
    fields: FieldResult[];
    description: string;
    hidden: boolean;
}

const mapField = (d: FieldResult): SharePointField => {
    return {
        type: d.Type,
        staticName: d.StaticName,
        displayName: d.DisplayName,
        searchAlias: d.SearchAlias,
        termSetId: d.TermSetId,
        required: d.Required || false,
        hiddenInWizard: d.HiddenInWizard || false,
        multiSelect: d.MultiSelect || false,
        defaultValue: d.Value
    }
}

const mapTemplate = (defaults: SharePointField[]) => (d: TemplateResult): SharePointClassification => {

    return {
        key: d.key,
        name: d.text,
        icon: d.iconProps ? d.iconProps.iconName : null,
        fields: d.fields ? d.fields.map(mapField) : defaults,
        description: d.description,
        hidden: d.hidden || false
    }
}

export const getSharePointTenantProperties = async (sharePointRootUrl: string, token: string): Promise<SharePointTenantProperties> => {

    return new Promise<SharePointTenantProperties>(async (resolve, reject) => {
        const [fieldText, templateText] = await Promise.all([
            getProperty(sharePointRootUrl, token, "FIVEP_FIELDS"),
            getProperty(sharePointRootUrl, token, "FIVEP_TEMPLATES"),
        ]);

        let defaultFields: SharePointField[] = [];

        if (fieldText && fieldText.length > 0) {
            const data: FieldResult[] = JSON.parse(fieldText);
            defaultFields = data.map(mapField);
        }

        let classifications: SharePointClassification[] = [];

        if (templateText && templateText.length > 0) {

            const data: TemplateResult[] = JSON.parse(templateText);

            classifications = data.map(mapTemplate(defaultFields));

            resolve({
                hasCollaborateConfiguration: true,
                lastRetrieved: new Date(),
                classifications: classifications,
                siteSetupFunctionUrl: await getProperty(sharePointRootUrl, token, "FIVEP_SITEFUNCTION")
            });

        }
        else {
            resolve({
                hasCollaborateConfiguration: false,
                lastRetrieved: new Date(),
                classifications: [],
                siteSetupFunctionUrl: null
            });
        }
    });
}