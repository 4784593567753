
export const BEGIN_GET_TERM_SET = "BEGIN_GET_TERM_SET";
export const GET_TERM_SET_SUCCESS = "GET_TERM_SET_SUCCESS";
export const GET_TERM_SET_FAILED = "GET_TERM_SET_FAILED";

export interface Term {
    id: string;
    name: string;
    //localCustomProperties?: { [key: string]: string };
    customProperties?: { [key: string]: string };
    parentId: string;
    depth: number;
    customSortOrder?: number;
    path: string;
    searchIdString?: string;
    isAvailableForTagging: boolean;
    isDeprecated: boolean;
    terms: Array<Term>;
}

export interface BeginGetTermSetAction {
    type: typeof BEGIN_GET_TERM_SET,
    id: string
}

export interface GetTermSetSuccessAction {
    type: typeof GET_TERM_SET_SUCCESS,
    id: string,
    data: Array<Term>
}

export interface GetTermSetFailedAction {
    type: typeof GET_TERM_SET_FAILED,
    id: string,
    error: string
}

export type TermSetActionTypes =
    BeginGetTermSetAction |
    GetTermSetSuccessAction |
    GetTermSetFailedAction
