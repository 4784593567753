
import * as React from 'react';
import { connect } from 'react-redux';

import {
    PrimaryButton,
} from 'msteams-ui-components-react'

import { TextField, SearchBox } from 'office-ui-fabric-react';
import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { updateContentSearchText, executeSearchThunk, executePromotedSearchThunk, clearPromotedResultsThunk } from '../actions/contentSearchActions';

const styles = {}

interface IDiscoverContentSearchBarProps {
    dispatch: Dispatch<any>;
    appState: AppState;
}

const DiscoverContentSearchBar: React.FunctionComponent<IDiscoverContentSearchBarProps> = (props: IDiscoverContentSearchBarProps) => {

    const { dispatch, appState } = props;
    const { searchTerm } = appState.discoverContent;

    const updateSearchText = (event: React.FormEvent<any>, value: string) => {
        dispatch(updateContentSearchText(value));
    }

    const handleKeyPress = (event: React.KeyboardEvent<any>) => {
        if (event.keyCode == 13 || event.charCode == 13) {
            event.preventDefault();
            event.stopPropagation();
            executeSearch();
        }
    }

    const clearSearch = () => {
        dispatch(updateContentSearchText(""));
        //dispatch(clearPromotedResultsThunk());
    }

    const executeSearch = () => {
        if (appState.discoverContent.searchTerm) {
            dispatch(executeSearchThunk());
            //dispatch(executePromotedSearchThunk());
        }
    }

    return (
        <div className="nag-gridrow">
            <div className="nag-gridcol-12">
                {/* <TextField autoComplete="off" 
                    placeholder="What are you looking for?" 
                    iconProps={{iconName: "Search"}} 
                    value={searchTerm} onKeyPress={(e) => handleKeyPress(e)} 
                    onChange={(e, v) => updateSearchText(e, v)} 
                    /> */}

                <SearchBox autoComplete="off" value={searchTerm} onClear={clearSearch}
                    onSearch={() => executeSearch()} onChange={(e, v) => updateSearchText(e, v)}
                    placeholder="What are you looking for?" />
            </div>
            {/* <div className="nag-gridcol-12-6">
                    <PrimaryButton disabled={searchTerm.length < 1} onClick={executeSearch}>Search</PrimaryButton>     
                </div>                 */}
        </div>)
}

const mapStateToProps = (state: AppState) => ({ appState: state });
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const connected = connect(mapStateToProps, mapDispatchToProps)(DiscoverContentSearchBar);

export { connected as DiscoverContentSearchBar };
export default connected;