import { EnvironmentActions, EnvironmentState, UPDATE_ENVIRONMENT } from "../types/environment";
import { ThemeStyle } from "msteams-ui-styles-core";

export const initialState: EnvironmentState = {
  sharePointRoot: "",
  loginHint: "",
  theme: "",
  themeType: ThemeStyle.Light,
  tenantId: "",
  groupId: null,
  teamId: null,
  teamName: null,
  sharePointSiteUrl: null,
  permissions: {
    allSitesRead: false,
    directoryReadAll: false,
    groupReadAll: false,
    groupReadWriteAll: false,
    sitesReadAll: false,
    sitesSearchAll: false,
    termStoreReadAll: false,
    userRead: false,
  }
}

export const environmentReducer = (state: EnvironmentState = initialState, action: EnvironmentActions): EnvironmentState => {

  switch (action.type) {
    case UPDATE_ENVIRONMENT:
      return {
        ...state,
        ...action.state
      }
    default:
      return state;
  }
}

export default environmentReducer;