import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../reducers';
import * as microsoftTeams from '@microsoft/teams-js';
import createConfig from '../config';
import { getApplicationToken, getGraphToken, getSharePointToken } from '../api/ssoTokens';
import { Stack, StackItem, Text } from 'office-ui-fabric-react';
import { PrimaryButton } from 'msteams-ui-components-react';
import { generateGap } from '../api/stackUtils';
import { LoadingGeneralComponent } from './LoadingGeneral';
import { updateEnvironment } from '../actions/environmentActions';
import { getEnvironment } from '../api/getEnvironment';

interface IAuthContainerProps {
    appState?: AppState;
    dispatch?: any;
    children: any;
}

interface IAuthContainerState {
    loading: boolean;
    error: string;
}

function openAuthDialog() {
    return new Promise((resolve, reject) => {
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/authstart",
            width: 600,
            height: 835,
            successCallback: (result) => {
                console.log("requestConsent success", result);
                let data = localStorage.getItem(result);
                //localStorage.removeItem(result);
                resolve(data);
            },
            failureCallback: (reason) => {
                console.log("requestConsent failure callback", reason);
                reject(reason);
            }
        });
    });
}

class AuthContainer extends React.Component<IAuthContainerProps, IAuthContainerState>{

    constructor(props: IAuthContainerProps) {
        super(props);

        this.state = {
            loading: true,
            error: "",
        }
    }

    public componentDidMount() {

        this.ensureLoggedIn();
    }

    private ensureLoggedIn = async () => {

        microsoftTeams.getContext(async (context) => {
            
            var request: microsoftTeams.authentication.AuthTokenRequest = {

                successCallback: async (teamsToken) => {

                    try {
                        const config = createConfig();
                        const [, , , environment] = await Promise.all([
                            getGraphToken(context.tid, context.userObjectId, teamsToken),
                            getSharePointToken(context.tid, context.userObjectId, teamsToken, `https://${context.teamSiteDomain}`),
                            getApplicationToken(context.tid, context.userObjectId, teamsToken, config.clientId),
                            getEnvironment()
                        ]);

                        this.props.dispatch(updateEnvironment(environment));

                        this.setState({ loading: false });
                    }
                    catch (error) {
                        this.setState({ loading: false, error });
                    }
                },
                failureCallback: (error) => {
                    this.setState({ loading: false, error });
                },
                resources: [],
            }
            microsoftTeams.authentication.getAuthToken(request);
        });

    }

    private startConsent = () => {

        openAuthDialog()
            .then(result => {

                console.log("request consent success", result);
                this.setState({ error: null, loading: true });

                //testing a delay to ensure consent is propagated in AAD?? before another attempt to get a token.
                //not sure if this will work.
                setTimeout(() => {

                    this.ensureLoggedIn();

                }, 2000);


            }).catch(error => {


                console.log("request consent error", error);

                this.setState({ error });
            });
    }

    private startInteractiveLogin = () => {

        openAuthDialog()
            .then(result => {
                console.log("interactive login success", result);
                this.setState({ error: null, loading: true });
                this.ensureLoggedIn();

            }).catch(error => {

                console.log("request consent error", error);
                this.setState({ error });
            });
    }


    private refreshPage = () => {
        window.location.reload();
    }

    public render() {
        const { loading, error } = this.state;

        const gap = generateGap(10);

        if (loading) {
            //return <div></div>
            return <LoadingGeneralComponent message="Loading..." delay={100} />
        }
        else if (error) {
            if (error == "invalid_grant" || error == "consent_required") {
                return <div className="nag-contentWidth">
                    <Stack tokens={gap}>
                        <StackItem>
                            <h2>Approval Required</h2>
                        </StackItem>
                        <StackItem>
                            <Text>This application requires approval by a global administrator for your organisation. Click the button below in order to provide admin consent.</Text>
                        </StackItem>
                        <StackItem>
                            <PrimaryButton className="nag-button" onClick={this.startConsent}>Provide Admin Consent</PrimaryButton>
                        </StackItem>
                    </Stack>
                </div>
            }
            else if (error == "interactive_login") {
                return <div className="nag-contentWidth">
                    <Stack tokens={gap}>
                        <StackItem>
                            <Text>Login required. Please click below to login</Text>
                        </StackItem>
                        <StackItem>
                            <PrimaryButton className="nag-button" onClick={this.startInteractiveLogin}>Login</PrimaryButton>
                        </StackItem>
                    </Stack>
                </div>
            }
            else if (error == "CancelledByUser") {
                return <div className="nag-contentWidth">
                    <Stack tokens={gap}>
                        <StackItem>
                            <Text>Operation cancelled. Please try again.</Text>
                        </StackItem>
                        <StackItem>
                            <PrimaryButton className="nag-button" onClick={this.refreshPage}>Refresh page</PrimaryButton>
                        </StackItem>
                    </Stack>
                </div>
            }
            else {
                return <div className="nag-contentWidth">
                    <Stack tokens={gap}>
                        <StackItem>
                            <Text>Unexpected Error! ({error})</Text>
                        </StackItem>
                    </Stack>
                </div>
            }
        }
        else {
            return <div>{this.props.children}</div>
        }
    }
}

export interface IContainerProps {

}

const mapStateToProps = (state: AppState) => ({ appState: state });

const connected = connect(mapStateToProps, null)(AuthContainer);

export default connected;
export { connected as AuthContainer };