import * as microsoftTeams from '@microsoft/teams-js';

export function navigateToTeam(internalId: string): void {

    microsoftTeams.executeDeepLink(`https://teams.microsoft.com/_#/conversations/General?threadId=${internalId}&ctx=channel`, (status: boolean, reason: string) => {
        if (!status) {
            console.log("Fail navigateToTeam", reason);
        }
    });
}

export function navigateToTeamFiles(internalId: string): void {

    microsoftTeams.executeDeepLink(`https://teams.microsoft.com/_#/files/General?threadId=${internalId}&ctx=channel`, (status: boolean, reason: string) => {
        if (!status) {
            console.log("Fail navigateToTeamFiles", reason);
        }
    })
}