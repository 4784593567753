import { Dictionary } from ".";

export interface TeamState {
  name: string;
  id: string;
  email: string;
  description: string;
  created: Date;
  isTeam: boolean;
  isMember?: boolean;
  isPrivate?: boolean;
  isOwner?: boolean;
  members?: [];
  isArchived?: boolean;
  visibility: string;
  internalId?: string;
  // imageData?: string;
  discoverable?: boolean;
  metaData?: IMetadata;
  joinStatus: string;
}

export interface ITeamsDataItem {
  id: string;
  resourceProvisioningOptions: string[];
  mail: string;
  displayName: string;
  description: string;
  createdDateTime: string;
  visibility: string;
  extensions?: ITeamsExtensionData[]
}

export interface IDiscoverySettings {
  showInTeamsSearchAndSuggestions: boolean
}

export interface ITeamsDataItem2 {
  id: string;
  internalId: string;
  discoverySettings: IDiscoverySettings;
  displayName: string;
  description: string;
  isArchived: boolean;
}

export interface IMetadata extends _.Dictionary<string> { }

export interface ITeamsExtensionData {
  id: string;
  extensionName: string;
  config?: object[];
}

export interface ITeamImage {
  id: string;
  base64Data: string;
  lastUpdated: Date;
}

export interface ITeamsDataResponse {
  "@odata.nextLink": string;
  value: ITeamsDataItem[];
}

export interface KeyedTeamState {
  [key: string]: TeamState
}

export const BEGIN_RETRIEVE_TEAMS = "BEGIN_RETRIEVE_TEAMS";
export const RETRIEVE_TEAMS_SUCCESS = "RETRIEVE_TEAMS_SUCCESS";
export const RETRIEVE_TEAMS_FAILED = "RETRIEVE_TEAMS_FAILED";

export const BEGIN_RETRIEVE_TEAMS_IN_BACKGROUND = "BEGIN_RETRIEVE_TEAMS_IN_BACKGROUND";
export const RETRIEVE_TEAMS_IN_BACKGROUND_SUCCESS = "RETRIEVE_TEAMS_IN_BACKGROUND_SUCCESS";
export const RETRIEVE_TEAMS_IN_BACKGROUND_FAILED = "RETRIEVE_TEAMS_IN_BACKGROUND_FAILED";

export const UPDATE_TEAM_STATE = "UPDATE_TEAM_STATE";
export const UPDATE_TEAM_IMAGE = "UPDATE_TEAM_IMAGE";
export const UPDATE_TEAM_BULK = "UPDATE_TEAM_BULK";

export const REMOVE_DELETED_TEAMS = "REMOVE_DELETED_TEAMS";

export interface RemoveDeletedTeamAction {
  type: typeof REMOVE_DELETED_TEAMS;
  ids: string[];
}

export interface BeginRetrieveTeamsInBackgroundAction {
  type: typeof BEGIN_RETRIEVE_TEAMS_IN_BACKGROUND,
}

export interface RetrieveTeamsInBackgroundSuccessAction {
  type: typeof RETRIEVE_TEAMS_IN_BACKGROUND_SUCCESS,
}

export interface RetrieveTeamsInBackgroundFailedAction {
  type: typeof RETRIEVE_TEAMS_IN_BACKGROUND_FAILED
}

export interface BeginRetrieveTeamsAction {
  type: typeof BEGIN_RETRIEVE_TEAMS,
}

export interface RetrieveTeamsSuccessAction {
  type: typeof RETRIEVE_TEAMS_SUCCESS,
}

export interface RetrieveTeamsFailedAction {
  type: typeof RETRIEVE_TEAMS_FAILED
}

export interface UpdateTeamStateAction {
  type: typeof UPDATE_TEAM_STATE,
  team: TeamState
}

export interface UpdateTeamImageAction {
  type: typeof UPDATE_TEAM_IMAGE,
  image: ITeamImage,
  // teamId: string,
  // teamImage: string
}

export interface UpdateTeamBulkAction {
  type: typeof UPDATE_TEAM_BULK,
  teams: TeamState[]
}


export type TeamsActionTypes =
  BeginRetrieveTeamsAction |
  RetrieveTeamsSuccessAction |
  RetrieveTeamsFailedAction |
  BeginRetrieveTeamsInBackgroundAction |
  RetrieveTeamsInBackgroundFailedAction |
  RetrieveTeamsInBackgroundSuccessAction |
  UpdateTeamStateAction |
  UpdateTeamImageAction |
  UpdateTeamBulkAction |
  RemoveDeletedTeamAction



