import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';

export interface IFileLogoProps {
    fileExtension: string;
}

export const FileLogo: React.FunctionComponent<IFileLogoProps> = (props) => {

    const { fileExtension } = props;

    let logo = "TextDocument";
    let className = "nag-text";

    switch (fileExtension) {
        case "ppt":
        case "pptx":
            logo = "PowerPointLogo";
            className = "nag-powerpoint";
            break;
        case "xls":
        case "xlsx":
            logo = "ExcelLogo";
            className = "nag-excel";
            break;
        case "doc":
        case "docx":
            logo = "WordLogo";
            className = "nag-word";
            break;
        case "pdf":
            logo = "PDF";
            break;
    }

    const iconStyle = {
        fontSize: "18px",
    }

    return <Icon className={className} style={iconStyle} iconName={logo} />

    //    return <div>YO {fileExtension}</div>

}