import * as React from 'react';
import { FileTile } from './FileTile';
import { AppState } from '../reducers';
//import { IPlaceholderFile } from '../reducers/recentFiles';
import { ISuggestedFileState } from '../api/getSuggestedFiles';
import { SearchResult } from '../types';

export interface ISuggestedFilePlaceholderProps {
    appState: AppState,
    files: SearchResult[]
}

export interface ISuggestedFilePlaceholderState {

}

export class SuggestedFilePlaceholder extends React.Component<ISuggestedFilePlaceholderProps, ISuggestedFilePlaceholderState> {

    public render() {
        const { appState, files } = this.props;
        const tiles = files.map(f => <FileTile key={f.uniqueId} file={f} />)

        return <div className=""><div className="nag-gridrow">{tiles}</div></div>
    }
}