import { getSSOSharePointToken } from "../api/ssoTokens";
import { getEnvironment } from "../api/getEnvironment";
import * as _ from "lodash";

export const getSharePointSiteProperties = async (siteUrl?: string, token?: string): Promise<{ [key: string]: string }> => {
    const spSiteUrl = siteUrl || await (await getEnvironment()).sharePointSiteUrl;
    const spToken = token || await getSSOSharePointToken();
    const url = `${spSiteUrl}/_api/web/AllProperties`;

    const fetchParams = {
        method: "get",
        headers: {
            "Accept": "application/json",
            "Content-Type": "text/xml",
            "Authorization": `Bearer ${spToken}`
        }
    };

    return fetch(url, fetchParams)
        .then(res => res.ok && res.json())
        .then((data: { [key: string]: string }) => _.mapKeys(data, (val, key) => key.replace(/_x005f_/g, "_")));
}