import * as React from 'react';
import { SearchResult, Dictionary } from '../types';
import { FileLogo } from './FileLogo';
import * as _ from 'lodash';
//import { IPlaceholderFile } from '../reducers/recentFiles';

export interface IFileTileProps {
    file: SearchResult
}

const formatExtras = (extras: Dictionary<string>): string => {
    return _.values(extras).filter(v => v && v.length).join(", ");
}

import { openInTeams } from '../api/openFile';
import { ExcelPreviewImage } from './ExcelPreviewImage';

export const FileTile: React.FunctionComponent<IFileTileProps> = (props) => {

    const style = {

        backgroundImage: `url('data:image/png;base64,${props.file.previewImageData}')`,
        backgroundRepeat: "no-repeat",
    }

    const image = props.file.previewImageData ?
        <div className="nag-previewImageInner" style={style}></div>
        :
        <ExcelPreviewImage />

    const { file } = props;
    const { fileType, itemName, siteTitle, extraProperties } = file;

    return <div className="nag-threeTile" >
        <div className="nag-filetile" onClick={() => openInTeams(file)}>
            <div className="nag-previewImageOuter">
                {image}
            </div>
            <div className="nag-tileContentOuter">

                <div className="nag-tileContentInner">

                    <div className="nag-fileAndIcon"><FileLogo fileExtension={fileType} /> {itemName}</div>
                    <div className="nag-fileProperties">
                        <div className="nag-title">{siteTitle}</div>
                        <div className="nag-meta">{formatExtras(extraProperties)}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}