export type LicenseStatus = "Valid" | "Error" | "Inactive" | "NoLicense" | "Unknown";

interface ILicenseState {
    status: LicenseStatus;
}

export const licenseInit: ILicenseState = {
    status: "Unknown"
}

export interface ILicenseCheckResult {
    tenantId: string;
    licenseStatus: LicenseStatus;
    message: string;
}

export const SET_LICENSE_STATUS = "SET_LICENSE_STATUS";

export interface SetLicenseStatusAction {
    type: typeof SET_LICENSE_STATUS,
    status: LicenseStatus
}

type LicenseActions = SetLicenseStatusAction

export const licenseReducer = (state: ILicenseState = licenseInit, action: LicenseActions): ILicenseState => {

    switch (action.type) {
        case SET_LICENSE_STATUS:
            return {
                ...state,
                status: action.status
            }
        default:
            return state;
    }
}

export default licenseReducer;