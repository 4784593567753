import {
    IUserPreferencesState, UserPreferencesActions, SET_USER_PREFERENCE_CONTENT_COLUMNS,
    SET_USER_PREFERENCE_TEAMS_COLUMNS, CLEAR_USER_PREFERENCE_TEAMS_COLUMNS,
    CLEAR_USER_PREFERENCE_CONTENT_COLUMNS
} from "../types/userPreferences";

const initialState: IUserPreferencesState = {
    discoverContentColumns: null,
    discoverTeamsColumns: {
        __default: null
    }
}

export const userPreferencesReducer = (state: IUserPreferencesState = initialState,
    action: UserPreferencesActions): IUserPreferencesState => {
    switch (action.type) {
        case SET_USER_PREFERENCE_CONTENT_COLUMNS:
            return {
                ...state,
                discoverContentColumns: action.state
            }
        case SET_USER_PREFERENCE_TEAMS_COLUMNS:
            return {
                ...state,
                discoverTeamsColumns: {
                    ...state.discoverTeamsColumns,
                    [action.classification || "__default"]: action.state
                }
            }
        case CLEAR_USER_PREFERENCE_TEAMS_COLUMNS:
            return {
                ...state,
                discoverTeamsColumns: null
            }
        case CLEAR_USER_PREFERENCE_CONTENT_COLUMNS:
            return {
                ...state,
                discoverContentColumns: null
            }
        default:
            return state;
    }
}

export default userPreferencesReducer;