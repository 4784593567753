import * as React from 'react';
import * as _ from 'lodash';
import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { connect } from 'react-redux';

import { DiscoverContentSearchBar } from './DiscoverContentSearchBar';

import { DiscoverContentTable } from './DiscoverContentTable';

import { getRecentFilesThunk, getTrendingFilesThunk } from '../actions/suggestedFilesActions';
import { SectionHeading } from './SectionHeading';
import { SuggestedFilePlaceholder } from './SuggestedFilePlaceholder';
import { DiscoverContentRefiners } from './DiscoverContentRefiners';
import { EmptySearchPanel } from './EmptySearchPanel';
import { ShimmerSearch } from './ShimmerSearch';

export interface IDiscoverContentState {

}

export interface IDiscoverContentProps {
    appState: AppState;
    dispatch: Dispatch<any>
}

class DiscoverContentComponent extends React.Component<IDiscoverContentProps, IDiscoverContentState> {

    public constructor(props: IDiscoverContentProps, state: IDiscoverContentState) {
        super(props, state);
    }

    public componentDidMount() {

        const { dispatch } = this.props;
        dispatch(getRecentFilesThunk());
        dispatch(getTrendingFilesThunk());

    }

    public render() {

        const { appState, dispatch } = this.props;
        const { results, searchTerm, resultSummary, resultTerm, searchStatus, promotedResults } = appState.discoverContent;
        const { tenantProperties } = appState;

        var contents = null;

        if (!searchTerm || searchTerm.length == 0) {
            contents = (
                <React.Fragment>
                    <SectionHeading heading="Suggested for you" />
                    <SuggestedFilePlaceholder appState={appState} files={appState.trendingFiles.files} />
                    <SectionHeading heading="Your recent files" />
                    <DiscoverContentTable showAuthor={false} dispatch={dispatch} results={appState.recentFiles.files} tenantProperties={tenantProperties} appState={appState} />
                </React.Fragment>
            );
        }
        else if (searchStatus && searchStatus.length) {
            contents = <ShimmerSearch />;
        }
        else if (resultTerm && resultTerm.length) {
            if (results.length) {
                contents = (
                    <React.Fragment>
                        <DiscoverContentRefiners dispatch={dispatch} appState={appState} />
                        {
                            promotedResults.length > 0 &&
                            <React.Fragment>
                                <SectionHeading heading="Promoted results" />
                                {/* TODO */}
                            </React.Fragment>
                        }
                        <DiscoverContentTable showAuthor={true} dispatch={dispatch} results={results} tenantProperties={tenantProperties} appState={appState} />
                    </React.Fragment>
                );
            }
            else {
                contents = <EmptySearchPanel />;
            }
        }
        else {
            contents = (
                <React.Fragment>
                    <SectionHeading heading="Suggested for you" />
                    <SuggestedFilePlaceholder appState={appState} files={appState.trendingFiles.files} />
                    <SectionHeading heading="Your recent files" />
                    <DiscoverContentTable showAuthor={false} dispatch={dispatch} results={appState.recentFiles.files} tenantProperties={tenantProperties} appState={appState} />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <div className="nag-filterWidth">
                    <DiscoverContentSearchBar />
                </div>
                <div className="nag-contentWidth">
                    {contents}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: AppState) => ({ appState: state });
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const discoverConnected = connect(mapStateToProps, mapDispatchToProps)(DiscoverContentComponent);

export { discoverConnected as DiscoverContent };
export default discoverConnected;