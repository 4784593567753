
import doFetch from './doFetch';
import { IUserInfoState, IUserGraphData } from '../types/user';

export function getMe(token: string): Promise<IUserInfoState> {

    return new Promise<IUserInfoState>((resolve, reject) => {

        const url = `https://graph.microsoft.com/v1.0/me`;

        doFetch(url, token)
            .then(text => {
                const data: IUserGraphData = JSON.parse(text);

                const user: IUserInfoState = {
                    id: data.id,
                    mail: data.mail,
                    displayName: data.displayName,
                    userPrincipalName: data.userPrincipalName
                }

                resolve(user);
            },
            err => {
                reject(err);
            })
            .catch(reason => {
                reject(reason);
            });
    });
}