import * as React from 'react';
import { TeamState, ITeamImage } from '../types/teams';

import createConfig from '../config';
import { Dictionary } from 'lodash';
import { AppState } from '../reducers';
import { connect } from 'react-redux';
import { Dispatch, AnyAction } from 'redux';
import { retrieveTeamImageThunk } from '../actions/teamsActions';
import { ThunkAction } from 'redux-thunk';

export interface ITeamLogoProps {
    team: TeamState;
    appState: AppState;
    className?: string;
    dispatch: Dispatch<any>;
}

export interface ITeamLogoState {
    teamId: string;
    imageData: string;
}

class TeamLogo extends React.Component<ITeamLogoProps, ITeamLogoState>
{

    constructor(props: ITeamLogoProps, state: ITeamLogoState) {
        super(props, state);

        const config = createConfig();
        this.state = {
            teamId: props.team.id,
            imageData: config.defaultTeamLogo
        }
    }

    // public componentWillReceiveProps(props: ITeamLogoProps){


    // }

    public componentDidUpdate(lastProps: ITeamLogoProps) {

        const config = createConfig();
        const { id } = this.props.team;
        if (this.state.teamId != id || this.state.imageData == config.defaultTeamLogo) {
            const image = this.props.appState.teamImages[id];

            if (image && image.base64Data) {
                this.setState((prev) => { return { ...prev, imageData: image.base64Data, teamId: id } })
            }
        }
    }

    public componentDidMount() {

        const { dispatch } = this.props;
        const { id } = this.props.team;
        const image = this.props.appState.teamImages[id];

        if (image && image.base64Data) {
            this.setState((prev) => { return { ...prev, imageData: image.base64Data } })
        }
        else {
            setTimeout(() => {
                dispatch(retrieveTeamImageThunk(id));
            }, 0);
        }
    }

    public render() {
        const { className } = this.props;
        return <img className={className} src={`data:image/jpeg;base64,${this.state.imageData}`} />
    }
}

const mapStateToProps = (state: AppState) => ({ appState: state });
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const teamLogoConnected = connect(mapStateToProps, mapDispatchToProps)(TeamLogo);

export { teamLogoConnected as TeamLogo };
export default teamLogoConnected;