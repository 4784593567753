import { AppState } from "../reducers";
import { TeamState } from "../types/teams";
import * as _ from 'lodash/fp';

export const getSuggestedTeams = (appState: AppState): TeamState[] => {

    const teams = _.values(appState.teams);

    return _.pipe(
        _.values,
        _.filter(t => !t.isMember && t.visibility == "Public"),
        _.sortBy(t => t.created),
        _.reverse,
        _.take(6)
    )(teams);
}