import * as React from 'react';
import { IStackProps, IStackTokens, Stack, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { SectionHeading } from './SectionHeading';

export interface ILoadingGeneralComponentProps {
    message: string,
    delay?: number,
}

export interface ILoadingGeneralComponentState {
    visible: boolean;
}

class LoadingGeneralComponent extends React.Component<ILoadingGeneralComponentProps, ILoadingGeneralComponentState> {

    private mounted: boolean = false;
    private timerId: number;

    constructor(props: ILoadingGeneralComponentProps) {
        super(props);

        this.state = {
            visible: false
        }
    }

    public componentWillUnmount() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
        this.mounted = false;
    }

    public componentDidMount() {
        this.mounted = true;

        const { delay } = this.props;

        if (delay && delay > 0) {
            this.timerId = window.setTimeout(() => {
                if (this.mounted) {
                    this.setState({ visible: true });
                }
            }, delay);
        }
        else {
            this.setState({ visible: true });
        }
    }

    public render() {

        const rowProps: IStackProps = {
            horizontal: true,
            verticalAlign: "center"
        }

        const tokens: IStackTokens = { childrenGap: 10 }
        const { message } = this.props;
        const { visible } = this.state;

        if (visible) {

            return <Stack {...rowProps} tokens={tokens}>
                <Spinner className="nag-loadingSpinner" size={SpinnerSize.large} />
                <SectionHeading heading={message}></SectionHeading>
            </Stack>
        }
        else {
            return <div></div>
        }
    }
}

export { LoadingGeneralComponent }
