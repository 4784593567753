
import * as _ from 'lodash';
import createConfig from '../config';
import { TeamState, ITeamsDataResponse, ITeamsDataItem, ITeamsDataItem2, ITeamImage } from '../types/teams';
import doFetch from './doFetch';
import { SharePointTenantProperties } from '../types/sharePointTenantProperties';
import { IUserGraphData } from '../types/user';

// const getMockTeamData = (): Promise<TeamState[]> => {

//     const data:TeamState[] = [];

//     for(var i = 1; i <= 10000; i++){
//         data.push({
//             name: `Test team ${i}`,
//             description: `Description of test team`,
//             isTeam: true,
//             isMember: false,
//             visibility: "Public",
//             id: `aaaaa-${i}`,
//             created: new Date(),
//             email: `test${i}@5pk.it`,
//             metaData: {
//                 classification: (i % 3 == 0 ? "Project": i % 2 ? "Classified" : "Collaboration")
//             },
//             joinStatus: ""
//         });
//     }

//     return Promise.resolve(data);

// }

interface loadResult {
    groups: TeamState[];
    nextUrl: string;
}

const loadUrl = (url: string, accessToken: string, tenantProperties: SharePointTenantProperties): Promise<loadResult> => {
    return new Promise<loadResult>(async (resolve, reject) => {

        try {

            let result: loadResult = { groups: [], nextUrl: null };

            var text: string = await doFetch(url, accessToken);

            const data: ITeamsDataResponse = JSON.parse(text);

            if (data) {
                if (data.value) {
                    var teamsData: TeamState[] = data.value.map((o: ITeamsDataItem, index: number) => {

                        var team: TeamState = {
                            id: o.id,
                            name: o.displayName,
                            email: o.mail,
                            description: o.description,
                            created: new Date(o.createdDateTime),
                            visibility: o.visibility,
                            isTeam: true,
                            metaData: {},
                            joinStatus: null
                        };

                        if (o.extensions && o.extensions.length) {
                            const e: any = o.extensions[0];

                            const classification = e["classification"];

                            if (classification) {

                                team.metaData["classification"] = classification;

                                const c = tenantProperties.classifications.find(f => f.name == classification);

                                if (c) {
                                    const { fields } = c;
                                    if (fields) {
                                        fields.forEach(f => team.metaData[f.displayName] = e[f.staticName]);
                                    }
                                }
                            }
                        }
                        return team;
                    });

                    result.groups = teamsData;

                }
            }

            if (data["@odata.nextLink"]) {
                result.nextUrl = data["@odata.nextLink"];
            }

            resolve(result);
        }
        catch (ex) {
            reject(ex);
        }

    });
}

export const getAllGroups = (accessToken: string, tenantProperties: SharePointTenantProperties): Promise<TeamState[]> => {


    return new Promise<TeamState[]>(async (resolve, reject) => {

        const config = createConfig();

        let url = tenantProperties.hasCollaborateConfiguration ?
            `https://graph.microsoft.com/beta/groups?$top=999&$expand=extensions($filter=id eq '${config.extensionsKey}')&$filter=resourceProvisioningOptions/Any(x:x eq 'Team')&$select=extensions,id,createdDateTime,displayName,description,visibility,mail` :
            `https://graph.microsoft.com/beta/groups?$top=999&$filter=resourceProvisioningOptions/Any(x:x eq 'Team')&$select=id,createdDateTime,displayName,description,visibility,mail`;

        try {
            let groups: TeamState[] = [];

            console.log("beginning get all groups");

            var s = (new Date()).getTime();

            do {
                const data = await loadUrl(url, accessToken, tenantProperties);

                groups = [...groups, ...data.groups];

                if (!data.nextUrl)//|| groups.length >= 500)  //TODO - remove restriction
                {
                    break;
                }
                url = data.nextUrl;
            }
            while (true);

            var e = (new Date()).getTime();
            console.log("complete get all groups: ", groups.length, e - s);

            // if(groups.length > 2000){
            //     groups = groups.slice(0, 2000);
            // }

            resolve(groups);
        }
        catch (reason) {
            reject(reason);
        }
    });
}

export const getMyTeamIds = (token: string) => {

    return new Promise<string[]>((resolve, reject) => {

        console.log("get my team ids")
        //const url = `https://graph.microsoft.com/beta/me/memberOf?$select=id,resourceProvisioningOptions&$top=999`;
        const url = `https://graph.microsoft.com/v1.0/me/joinedTeams?$select=id`;

        doFetch(url, token)
            .then((text: string) => {

                const data: ITeamsDataResponse = JSON.parse(text);
                if (data && data.value) {
                    // const teamsData = data.value
                    //                         .filter(x => x.resourceProvisioningOptions 
                    //                                         && x.resourceProvisioningOptions.find(i => i === "Team"))
                    //                         .map(x => x.id);

                    const teamsData = data.value
                        .map(x => x.id);

                    console.log("get my team ids ok")
                    resolve(teamsData);
                }
                else {
                    resolve([]);
                }
            })
            .catch((reason: any) => {
                console.log("get my team ids error", reason);
                reject(reason);
            });
    });
}

export const getTeamInfo = (token: string, teamId: string): Promise<ITeamsDataItem2> => {

    return new Promise<ITeamsDataItem2>((resolve, reject) => {

        const url = `https://graph.microsoft.com/v1.0/teams/${teamId}`;

        doFetch(url, token).then((text: string) => {

            const td = JSON.parse(text) as ITeamsDataItem2;

            const r: ITeamsDataItem2 = {
                id: td.id,
                internalId: td.internalId,
                displayName: td.displayName,
                description: td.description,
                discoverySettings: td.discoverySettings,
                isArchived: td.isArchived,
            }
            resolve(r);
        }).catch(err => {
            resolve(null);
        });
    });
}

// const getTeamInfoBatched = (token: string, teamIds: string[]): Promise<ITeamsDataItem2[]> => {

//     return new Promise<ITeamsDataItem2[]>((resolve, reject) => {

//         Promise.all(teamIds.map(i => getTeamInfo(token, i)))
//             .then((results: ITeamsDataItem2[]) => {
//                 resolve(results.filter(i => i != null));
//             });
//     });
// }

export const getTeamImage = (token: string, teamId: string): Promise<ITeamImage> => {

    const emptyImage: ITeamImage = {
        id: teamId,
        base64Data: null,
        lastUpdated: null
    }

    return new Promise<ITeamImage>((resolve, reject) => {

        const url = `https://graph.microsoft.com/v1.0/groups/${teamId}/photos/48x48/$value`;

        var headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        fetch(url, { headers: headers })
            .then((resp: any) => {
                if (resp.ok) {
                    return resp.arrayBuffer();
                }
                else {
                    resolve(emptyImage);
                }
            })
            .then((buffer: ArrayBuffer) => {

                const base64 = bufferToBase64(buffer);

                const r: ITeamImage = {
                    id: teamId,
                    base64Data: base64,
                    lastUpdated: new Date()
                }
                resolve(r);
            })
            .catch(err => {
                resolve(emptyImage);
            });
    });
}

// const getTeamImageBatched = (token: string, teamIds: string[]): Promise<ITeamImage[]> => {

//     return new Promise<any[]>((resolve, reject) => {

//         Promise.all(teamIds.map(i => getTeamImage(token, i)))
//             .then((results: ITeamImage[]) => {
//                 resolve(results);
//             })
//             .catch(err => {
//                 reject(err);
//             });
//     });
// }

export const getGroupOwners = (token: string, groupId: string): Promise<IUserGraphData[]> => {

    return new Promise<IUserGraphData[]>((resolve, reject) => {
        const url = `https://graph.microsoft.com/v1.0/groups/${groupId}/owners`;

        doFetch(url, token, "json").then((response: any) => {
            if (response && response.value) {
                resolve(response.value.map((v: any) => ({
                    id: v.id,
                    mail: v.mail,
                    displayName: v.displayName,
                    userPrincipalName: v.userPrincipalName
                } as IUserGraphData)))
            }
            else {
                resolve([]);
            }
        }).catch(err => {
            reject(err);
        });
    });
}

const bufferToBase64 = (buffer: ArrayBuffer): string => {

    const base64 = Buffer.from(buffer).toString('base64');
    return base64;
}