//import { AppActions } from '../types';
import * as _ from 'lodash';

import {
    TeamState, TeamsActionTypes, BEGIN_RETRIEVE_TEAMS, RETRIEVE_TEAMS_SUCCESS,
    RETRIEVE_TEAMS_FAILED, RETRIEVE_TEAMS_IN_BACKGROUND_SUCCESS,
    UPDATE_TEAM_STATE, UPDATE_TEAM_IMAGE, UPDATE_TEAM_BULK, REMOVE_DELETED_TEAMS
} from '../types/teams';
import { Dictionary } from '../types';
import { JOIN_TEAM_SUCCESS, JoinTeamsActions, BEGIN_JOIN_TEAM } from '../types/joinTeam';
import { ClearStateAction, CLEAR_STATE } from '../types/clearState';

const initialState: Dictionary<TeamState> = {}

type teamsReducerActionTypes = TeamsActionTypes | JoinTeamsActions | ClearStateAction

const removeTeamById = (teams: Dictionary<TeamState>, ids: string[]): Dictionary<TeamState> => {
    return _.omitBy(teams, (t) => ids.indexOf(t.id) >= 0);
}

export const teamsReducer = (state: Dictionary<TeamState> = initialState, action: teamsReducerActionTypes): Dictionary<TeamState> => {

    switch (action.type) {
        case REMOVE_DELETED_TEAMS:
            return removeTeamById(state, action.ids);
        case CLEAR_STATE:
            return initialState;
        case BEGIN_RETRIEVE_TEAMS:
            return state;
        case RETRIEVE_TEAMS_IN_BACKGROUND_SUCCESS:
        case RETRIEVE_TEAMS_SUCCESS:
            return state;
        case RETRIEVE_TEAMS_FAILED:
            return state;
        case BEGIN_JOIN_TEAM:
            return {
                ...state,
                [action.teamId]: {
                    ...state[action.teamId],
                    joinStatus: "Joining"
                }
            }
        case JOIN_TEAM_SUCCESS:
            return {
                ...state,
                [action.teamId]: {
                    ...state[action.teamId],
                    isMember: true,
                    joinStatus: ""
                }
            }
        case UPDATE_TEAM_STATE:
            return {
                ...state,
                [action.team.id]: {
                    ...state[action.team.id],
                    ...action.team
                }
            }
        case UPDATE_TEAM_BULK: {
            return bulkMerge(state, action.teams);
        }
    }

    return state;
}

const bulkMerge = (state: Dictionary<TeamState>, merges: TeamState[]): Dictionary<TeamState> => {

    const keyed = _.keyBy(merges, (m => m.id));
    const merged = _.merge({ ...state }, keyed)

    return merged;
}

export default teamsReducer;