import * as React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { ThemeStyle } from 'msteams-ui-styles-core';
import { AppState } from '../reducers';
import { getContext, TeamsThemeContext, Surface } from 'msteams-ui-components-react';
import { getThemeFromString } from '../api/getEnvironment';
import { css } from 'office-ui-fabric-react';

interface IThemeContainerProps {
    appState?: AppState;
    dispatch?: any;
    children: any;
}

interface IThemeContainerState {
    loading: boolean;
    fontSize: number;
    theme: ThemeStyle;
    isModal: boolean;
}

class ThemeContainer extends React.Component<IThemeContainerProps, IThemeContainerState>{

    constructor(props: IThemeContainerProps) {
        super(props);

        this.state = {
            loading: true,
            fontSize: 16,
            theme: ThemeStyle.Light,
            isModal: window.location.pathname === "/configureteam"
        };
    }

    public componentDidMount() {
        microsoftTeams.initialize(() => {
            microsoftTeams.registerOnThemeChangeHandler(this.updateTheme);

            microsoftTeams.getContext(async (context: microsoftTeams.Context) => {

                this.updateTheme(context.theme);
                this.setState({ loading: false });
            });
        });
    }

    protected updateTheme = (themeStr?: string): ThemeStyle => {
        const { isModal } = this.state;
        const theme = getThemeFromString(themeStr);
        this.setState({ theme });

        document.body.className = css(`nag-theme${theme}`, isModal && 'is-modal');

        return theme;
    }

    public render() {
        const context = getContext({
            baseFontSize: this.state.fontSize,
            style: this.state.theme
        });

        const { loading } = this.state;

        if (loading) {
            return <div className="nag-outer">
                <TeamsThemeContext.Provider value={context}>
                    <Surface>
                        <div className="nag-root" dir="ltr">
                        </div>
                    </Surface>
                </TeamsThemeContext.Provider>
            </div>
        }
        else {
            return <div className="nag-outer">
                <TeamsThemeContext.Provider value={context}>
                    <Surface>
                        <div className="nag-root" dir="ltr">
                            {this.props.children}
                        </div>
                    </Surface>
                </TeamsThemeContext.Provider>
            </div>
        }
    }
}

export { ThemeContainer };
