import * as React from 'react';
import { IDropdownOption, Dropdown, SearchBox, Rectangle } from 'office-ui-fabric-react';

interface IClearableDropDownProps {
    onChanged: (option: IDropdownOption) => void;
    options: IDropdownOption[];
    selectedKey: string;
    placeholder: string;
}

export const ClearableDropdown: React.FunctionComponent<IClearableDropDownProps> = (props) => {

    const { onChanged, options, selectedKey, placeholder } = props;

    const clear = () => {
        onChanged(null);
    }

    const ddChange = (event: React.FormEvent, option: IDropdownOption) => {
        onChanged(option);
    }

    if (selectedKey && selectedKey.length) {
        return <SearchBox readOnly={true} onClear={clear} onChange={() => { }} value={selectedKey} className="nag-search-no-icon" />
    }
    else {
        return <Dropdown placeholder={placeholder} onChange={ddChange} selectedKey={selectedKey} options={options} />
    }
}