
export const SET_AUTH_STATE = "SET_AUTH_STATE"
export const SET_AUTH_STATE_ERROR = "SET_AUTH_STATE_ERROR"

export interface SetAuthStateErrorAction {
    type: typeof SET_AUTH_STATE_ERROR,
    error: string;
    status: AuthStatus;
}

export interface SetAuthStateAction {
    type: typeof SET_AUTH_STATE,
    status: AuthStatus,
    message: string
}

export type AuthStateActions = SetAuthStateAction | SetAuthStateErrorAction;

export const setAuthStateError = (error: string): SetAuthStateErrorAction => {
    return {
        type: SET_AUTH_STATE_ERROR,
        status: "Error",
        error
    }
}

export const setAuthState = (status: AuthStatus, message: string = ""): SetAuthStateAction => {
    return {
        type: SET_AUTH_STATE,
        status,
        message
    }
}

export type AuthStatus = "Unknown" | "Logging In" | "Login Required" | "Logged In" | "Error" | "Switch User";

interface AuthState {
    status: AuthStatus;
    error: string;
    message: string;
}

const initialState: AuthState = {
    status: "Unknown",
    error: "",
    message: "",
}

const authReducer = (state: AuthState = initialState, action: AuthStateActions): AuthState => {

    switch (action.type) {
        case SET_AUTH_STATE:
            return {
                ...state,
                status: action.status,
                message: action.message
            }
        case SET_AUTH_STATE_ERROR:
            return {
                ...state,
                status: action.status,
                error: action.error
            }
        default:
            return state;
    }
}

export { authReducer }
export default authReducer;