
export interface IUserPreferencesState {
    discoverContentColumns: string[];
    discoverTeamsColumns: { [key: string]: string[] };
}

export const SET_USER_PREFERENCE_CONTENT_COLUMNS = "SET_USER_PREFERENCE_CONTENT_COLUMNS";
export const SET_USER_PREFERENCE_TEAMS_COLUMNS = "SET_USER_PREFERENCE_TEAMS_COLUMNS";
export const CLEAR_USER_PREFERENCE_CONTENT_COLUMNS = "CLEAR_USER_PREFERENCE_CONTENT_COLUMNS";
export const CLEAR_USER_PREFERENCE_TEAMS_COLUMNS = "CLEAR_USER_PREFERENCE_TEAMS_COLUMNS";

export interface SetUserPreferenceContentColumnsAction {
    type: typeof SET_USER_PREFERENCE_CONTENT_COLUMNS;
    state: string[];
}

export interface SetUserPreferenceTeamsColumnsAction {
    type: typeof SET_USER_PREFERENCE_TEAMS_COLUMNS;
    state: string[];
    classification: string;
}

export interface ClearUserPreferenceContentColumnsAction {
    type: typeof CLEAR_USER_PREFERENCE_CONTENT_COLUMNS;
}

export interface ClearUserPreferenceTeamsColumnsAction {
    type: typeof CLEAR_USER_PREFERENCE_TEAMS_COLUMNS;
}

export type UserPreferencesActions = SetUserPreferenceContentColumnsAction
    | SetUserPreferenceTeamsColumnsAction
    | ClearUserPreferenceContentColumnsAction
    | ClearUserPreferenceTeamsColumnsAction
