import {
    ContentSearchActionTypes,
    UpdateContentSearchTermAction, UPDATE_CONTENT_SEARCH_TERM,
    BeginContentSearchAction, BEGIN_CONTENT_SEARCH,
    ContentSearchSuccessAction, CONTENT_SEARCH_SUCCESS,
    ContentSearchFailedAction, CONTENT_SEARCH_FAILED,
    BeginContentSearchMoreAction, BEGIN_CONTENT_SEARCH_MORE,
    ContentSearchMoreSuccessAction, CONTENT_SEARCH_SUCCESS_MORE,
    ContentSearchMoreFailedAction, CONTENT_SEARCH_FAILED_MORE,
    AddContentSearchRefinerAction, ADD_CONTENT_SEARCH_REFINER,
    RemoveContentSearchRefinerAction, REMOVE_CONTENT_SEARCH_REFINER,
    BeginRefinementSearchAction, BEGIN_REFINEMENT_SEARCH,
    RefinementSearchSuccessAction, REFINEMENT_SEARCH_SUCCESS,
    RefinementSearchFailedAction, REFINEMENT_SEARCH_FAILED,
    BeginPromotedContentSearchAction, BEGIN_PROMOTED_CONTENT_SEARCH,
    PromotedContentSearchSuccessAction, PROMOTED_CONTENT_SEARCH_SUCCESS,
    PromotedContentSearchFailedAction, PROMOTED_CONTENT_SEARCH_FAILED,
    ClearPromotedResultsAction, CLEAR_PROMOTED_RESULTS
} from "../types/contentSearch";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppState } from "../reducers";
import { AnyAction } from "redux";
import * as _f from 'lodash/fp';

import { searchSharePoint, ISharePointSearchResult } from '../api/sharePointSearch';
import { SearchResult, Dictionary } from "../types";
import { extractMetaSearchMappings } from "../api/extractMetaDictionary";
import { getSSOSharePointToken } from "../api/ssoTokens";
//import { getSharePointToken } from "../api/authenticate";

export const addContentSearchRefiner = (refiner: string): AddContentSearchRefinerAction => {
    return {
        type: ADD_CONTENT_SEARCH_REFINER,
        refiner,
    }
}

export const removeContentSearchRefiner = (refiner: string): RemoveContentSearchRefinerAction => {
    return {
        type: REMOVE_CONTENT_SEARCH_REFINER,
        refiner
    }
}


export const updateContentSearchText = (query: string): UpdateContentSearchTermAction => {
    return {
        type: UPDATE_CONTENT_SEARCH_TERM,
        query
    }
}

const beginRefinementSearch = (): BeginRefinementSearchAction => {
    return {
        type: BEGIN_REFINEMENT_SEARCH
    }
}

const refinementSearchSuccess = (result: ISharePointSearchResult): RefinementSearchSuccessAction => {
    return {
        type: REFINEMENT_SEARCH_SUCCESS,
        results: result.results,
        resultRefiners: result.resultRefiners.refiners,
        summary: result.summary,
        resultTerm: result.resultTerm,
        resultCount: result.resultCount
    }
}

const refinementSearchFailed = (): RefinementSearchFailedAction => {
    return {
        type: REFINEMENT_SEARCH_FAILED
    }
}

const beginContentSearchMore = (): BeginContentSearchMoreAction => {
    return {
        type: BEGIN_CONTENT_SEARCH_MORE,
    }
}

const contentSearchMoreSuccess = (result: ISharePointSearchResult): ContentSearchMoreSuccessAction => {
    return {
        type: CONTENT_SEARCH_SUCCESS_MORE,
        results: result.results,
    }
}

const contentSearchMoreFailed = (): ContentSearchMoreFailedAction => {
    return {
        type: CONTENT_SEARCH_FAILED_MORE
    }
}

const beginContentSearch = (status: string): BeginContentSearchAction => {
    return {
        type: BEGIN_CONTENT_SEARCH,
        status
    }
}

const contentSearchSuccess = (result: ISharePointSearchResult): ContentSearchSuccessAction => {
    return {
        type: CONTENT_SEARCH_SUCCESS,
        results: result.results,
        resultRefiners: result.resultRefiners.refiners,
        summary: result.summary,
        resultTerm: result.resultTerm,
        resultCount: result.resultCount
    }
}

const contentSearchFailed = (): ContentSearchFailedAction => {
    return {
        type: CONTENT_SEARCH_FAILED
    }
}

const beginPromotedContentSearch = (): BeginPromotedContentSearchAction => {
    return {
        type: BEGIN_PROMOTED_CONTENT_SEARCH
    }
}

const promotedContentSearchSuccess = (result: ISharePointSearchResult): PromotedContentSearchSuccessAction => {
    return {
        type: PROMOTED_CONTENT_SEARCH_SUCCESS,
        results: result.results
    }
}

const promotedContentSearchFailed = (): PromotedContentSearchFailedAction => {
    return {
        type: PROMOTED_CONTENT_SEARCH_FAILED
    }
}

const clearPromotedResults = (): ClearPromotedResultsAction => {
    return {
        type: CLEAR_PROMOTED_RESULTS
    }
}

export type SearchThunkType = ThunkAction<Promise<void>, AppState, {}, ContentSearchActionTypes>
export type ThunkDispatchType = ThunkDispatch<AppState, {}, AnyAction>

export const addContentSearchRefinerThunk = (refiner: string): SearchThunkType => {
    return (dispatch: ThunkDispatchType, getState: () => AppState, extraArgument: {}): Promise<void> => {

        return new Promise<void>(async (resolve, reject) => {

            dispatch(beginRefinementSearch());

            dispatch(addContentSearchRefiner(refiner));

            const { discoverContent, environment } = getState();
            const { sharePointRoot, tenantId } = environment;
            const { searchTerm, searchRefiners } = discoverContent;

            const meta: Dictionary<string> = extractMetaSearchMappings(getState());

            const token = await getSSOSharePointToken();

            searchSharePoint(searchTerm, "", sharePointRoot, token, meta, searchRefiners)
                .then((result: ISharePointSearchResult) => {
                    dispatch(refinementSearchSuccess(result));
                    resolve();
                })
                .catch((reason: any) => {
                    dispatch(refinementSearchFailed());
                    reject();
                });

        });
    }
}

export const removeContentSearchRefinerThunk = (refiner: string): SearchThunkType => {
    return (dispatch: ThunkDispatchType, getState: () => AppState, extraArgument: {}): Promise<void> => {

        return new Promise<void>(async (resolve, reject) => {

            dispatch(beginRefinementSearch());

            dispatch(removeContentSearchRefiner(refiner));

            const { discoverContent, environment } = getState();
            const { sharePointRoot, tenantId } = environment;
            const { searchTerm, searchRefiners } = discoverContent;

            const meta: Dictionary<string> = extractMetaSearchMappings(getState());

            const token = await getSSOSharePointToken();

            searchSharePoint(searchTerm, "", sharePointRoot, token, meta, searchRefiners)
                .then((result: ISharePointSearchResult) => {
                    dispatch(refinementSearchSuccess(result));
                    resolve();
                })
                .catch((reason: any) => {
                    dispatch(refinementSearchFailed());
                    reject();
                });
        });
    }
}

export const executeSearchThunk = (): SearchThunkType => {

    return (dispatch: ThunkDispatchType, getState: () => AppState, extraArgument: {}): Promise<void> => {

        return new Promise<void>(async (resolve, reject) => {

            const { discoverContent, environment } = getState();
            const { sharePointRoot, tenantId } = environment;
            const { searchTerm, searchRefiners } = discoverContent;

            const status = `Searching SharePoint for documents matching "${searchTerm}"`;

            dispatch(beginContentSearch(status));

            const token = await getSSOSharePointToken();

            const meta: Dictionary<string> = extractMetaSearchMappings(getState());

            searchSharePoint(searchTerm, "", sharePointRoot, token, meta, searchRefiners)
                .then((result: ISharePointSearchResult) => {

                    dispatch(contentSearchSuccess(result));
                    resolve();
                })
                .catch((reason: any) => {

                    dispatch(contentSearchFailed());
                    reject();
                });
        })
    }
}

export const executeSearchMoreThunk = (): SearchThunkType => {

    return (dispatch: ThunkDispatchType, getState: () => AppState, extraArgument: {}): Promise<void> => {

        return new Promise<void>(async (resolve, reject) => {

            const { discoverContent, environment } = getState();
            const { sharePointRoot, tenantId } = environment;

            const { searchTerm, results, searchRefiners } = discoverContent;

            const startRow = results.length;

            dispatch(beginContentSearchMore());

            const token = await getSSOSharePointToken();

            const meta: Dictionary<string> = extractMetaSearchMappings(getState());

            searchSharePoint(searchTerm, "", sharePointRoot, token, meta, searchRefiners, startRow)
                .then((result: ISharePointSearchResult) => {
                    dispatch(contentSearchMoreSuccess(result));
                    resolve();
                })
                .catch((reason: any) => {
                    dispatch(contentSearchMoreFailed());
                    reject();
                });
        })
    }
}

export const executePromotedSearchThunk = (): SearchThunkType => {

    return (dispatch: ThunkDispatchType, getState: () => AppState, extraArgument: {}): Promise<void> => {

        return new Promise<void>(async (resolve, reject) => {

            const { discoverContent, environment } = getState();
            const { sharePointRoot, tenantId } = environment;
            const { searchTerm, searchRefiners } = discoverContent;

            dispatch(beginPromotedContentSearch());

            const token = await getSSOSharePointToken();

            const meta: Dictionary<string> = extractMetaSearchMappings(getState());

            searchSharePoint(searchTerm, "", sharePointRoot, token, meta, searchRefiners, 0, 6)
                .then((result: ISharePointSearchResult) => {
                    dispatch(promotedContentSearchSuccess(result));
                    resolve();
                })
                .catch((reason: any) => {
                    dispatch(promotedContentSearchFailed());
                    reject();
                });
        })
    }
}

export const clearPromotedResultsThunk = (): SearchThunkType => {
    return (dispatch: ThunkDispatchType, getState: () => AppState, extraArgument: {}): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            dispatch(clearPromotedResults());
        })
    }
}