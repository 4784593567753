import * as React from 'react';
import * as _ from 'lodash';
import { AppState } from '../reducers';
import { RefinerItem } from '../api/sharePointSearch';
import { PrimaryButton, Icon, DefaultButton } from 'office-ui-fabric-react';
import { addContentSearchRefinerThunk, removeContentSearchRefinerThunk } from '../actions/contentSearchActions';
import { Dispatch } from 'redux';
import { IDiscoverContentProps } from './DiscoverContent';
import createConfig from '../config';

const config = createConfig();

export interface IDiscoverContentRefinersProps {
    appState: AppState;
    dispatch: Dispatch<any>;
}

export interface IDiscoverContentRefinersState {
    expandRefiners: boolean;
}

export interface IRefinerButtonProps {
    refiner: string,
    item: RefinerItem,
    selected: boolean,
    disabled: boolean,
    dispatch: Dispatch<any>
}

export const RefinerButton: React.FunctionComponent<IRefinerButtonProps> = (props) => {

    const { dispatch, item, selected, refiner, disabled } = props;

    const action = () => {

        if (selected) {
            dispatch(removeContentSearchRefinerThunk(refiner));
        }
        else {
            dispatch(addContentSearchRefinerThunk(refiner));
        }
    }

    const classNames = "nag-refinerButton" + (selected ? " nag-selected" : "") + (disabled ? " nag-disabled" : "");

    const close = <div className="nag-refinerClose"><Icon iconName="Clear" /></div>;

    return <PrimaryButton onClick={action} disabled={disabled} className={classNames}>{`${item.value}`}{selected ? close : ` (${item.count})`}</PrimaryButton>
}

export class DiscoverContentRefiners extends React.Component<IDiscoverContentRefinersProps, IDiscoverContentRefinersState>{

    constructor(props: IDiscoverContentRefinersProps) {
        super(props);

        this.state = {
            expandRefiners: false
        }
    }

    private toggleExpandRefiners = () => {
        this.setState({ expandRefiners: !this.state.expandRefiners });
    }

    public render() {

        const refinerLimit = config.refinerDefaultLimit;
        const props = this.props;
        const { dispatch } = props;
        const { resultRefiners, searchRefiners, refinerSearchStatus } = props.appState.discoverContent;

        const disabled = refinerSearchStatus == "Refining";

        if (resultRefiners && resultRefiners.length) {
            const flattened = _.flatMap(resultRefiners, (r => r.items));

            let shownRefiners: RefinerItem[];
            let expandButton = null;

            // add a few extra for the initial comparison, so we don't have a tiny number like "Show 2 refiners", where we're better off just showing them
            const hasExcessRefiners = flattened.length > (refinerLimit + 4);

            if (hasExcessRefiners && !this.state.expandRefiners) {
                shownRefiners = _.take(flattened, refinerLimit);
                expandButton = <DefaultButton text={`Show ${flattened.length - refinerLimit} more refiners`} onClick={this.toggleExpandRefiners} />
            }
            else {
                shownRefiners = flattened
            }

            const buttons = shownRefiners.map((f, i) => {

                const refiner = `${f.field}: \"${f.value}\"`;

                const selected = searchRefiners.find(r => r == refiner) != null;

                return <RefinerButton key={i} refiner={refiner} disabled={disabled} selected={selected} item={f} dispatch={dispatch} />
            });


            return <div className="nag-refinerPanel">{buttons}{expandButton}</div>
        }
        else {
            return null;
        }
    }
}