import { Store, Dispatch, Middleware, MiddlewareAPI, Action } from 'redux';
import { AppState } from '../reducers';

// export interface MiddlewareLoggerParams<S is > {
//   store: MiddlewareAPI<S>;
// }
//TODO - look at improving typing
//https://stackoverflow.com/questions/45339448/how-do-you-create-strongly-typed-redux-middleware-in-typescript-from-reduxs-typ

const logger = (store: Store<AppState>) => (next: Dispatch) => (action: Action) => {
  console.group(action.type)
  console.info('dispatching', action)
  let result = next(action)
  console.log('next state', store.getState())
  console.groupEnd()
  return result
}

export default logger