import * as React from 'react';

import { AppState } from '../reducers';
import { Dispatch } from 'redux';
//import TeamsBaseComponent, { ITeamsBaseComponentProps, ITeamsBaseComponentState } from './TeamsBaseComponent';
import { connect } from 'react-redux';
import { DefaultButton, IconButton, IIconProps, IContextualMenuProps, IContextualMenuItem, Icon } from 'office-ui-fabric-react';
// import LoginButton from './LoginButton';
// import LogoutButton from './LogoutButton';
// import ClearStateButton from './ClearStateButton';
import { getSharePointTenantProperties } from '../api/getSharePointTenantProperties';

import DropDownDebug from './DropDownDebug';

export interface ISettingsState {
}

export interface ISettingsProps {
    appState: AppState;
    dispatch: Dispatch<any>
}

class SettingsComponent extends React.Component<ISettingsProps, ISettingsState> {

    public constructor(props: ISettingsProps, state: ISettingsState) {
        super(props, state);
    }

    // public componentWillMount() {

    //     const { sharePointRootUrl, sharePointToken, sharePointTokenExpiry, graphTokenExpiry } = this.props.appState.user;

    //     console.log("token expiries", graphTokenExpiry, sharePointTokenExpiry);

    // }

    // private loadTenant(){

    //     const { sharePointRootUrl, sharePointToken, sharePointTokenExpiry, graphTokenExpiry } = this.props.appState.user;

    //     getSharePointTenantProperties(sharePointRootUrl, sharePointToken).then(
    //         data => {
    //             console.log("DATA", data);
    //         }
    //     );
    // }

    public render() {
        const { tenantProperties } = this.props.appState;
        const { classifications, hasCollaborateConfiguration } = tenantProperties;

        return hasCollaborateConfiguration ?
            (
                <div>
                    <div className="nag-classificationDetailsIntro">
                        Here are all of the classifications and fields configured for your teams. Please contact your administrator should you require these to be updated.
                    </div>
                    {
                        classifications.map(classification =>
                            <div className="nag-classificationDetails">
                                <div className="nag-classificationDetailsTitle">
                                    <Icon iconName={classification.icon} />
                                    {classification.name}
                                </div>
                                <div className="nag-classificationDetailsFields">
                                    {
                                        classification.fields.map(field =>
                                            <div className="nag-classificationDetailsField">
                                                {field.displayName}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            )
            :
            (
                <div className="nag-noCollaborateConfigMessage">
                    Your tenant has not been configured for collaboration. Please <a target="_blank" href="https://fivep.com.au/microsoft-teams-navigator/">contact FiveP</a> if you're interested in understanding how to make the most of Navigator!
                </div>
            )
            ;

        // const { 
        //     //graphToken, sharePointToken, sharePointTokenExpiry, graphTokenExpiry, 
        //     lastLoginOnThisDevice } = this.props.appState.user;

        // return <div>
        //     <div>
        //     <ClearStateButton />
        //     <LoginButton />
        //     <LogoutButton />
        //     {/* <DefaultButton onClick={this.loadTenant.bind(this)}>Load tenant properties</DefaultButton> */}
        //     </div>
        //     <div>
        //         {/* <p>Last login: {lastLoginOnThisDevice.toString()} </p> */}
        //         {/* <p>Graph token: {graphToken} </p>
        //         <p>Graph token expiry: {graphTokenExpiry.toString()}</p>
        //         <p>SharePoint token: {sharePointToken} </p>
        //         <p>SharePoint token expiry: { sharePointTokenExpiry.toString() }</p> */}
        //     </div>
        // </div>
    }
}

const mapStateToProps = (state: AppState) => ({ appState: state });

const settingsConnected = connect(mapStateToProps, null)(SettingsComponent);

export { settingsConnected as Settings };
export default settingsConnected;