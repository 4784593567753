import { AppState } from "../reducers";
import { TeamState } from "../types/teams";
import * as _ from 'lodash/fp';

export const getMyTeams = (appState: AppState): TeamState[] => {

    const { teams } = appState;

    return _.pipe(
        _.values,
        _.filter(t => t.isMember),
        _.sortBy(t => t.created),
        _.reverse
    )(teams);
}