
export const BEGIN_JOIN_TEAM = "BEGIN_JOIN_TEAMS";
export const JOIN_TEAM_SUCCESS = "JOIN_TEAM_SUCCESS";
export const JOIN_TEAM_FAILED = "JOIN_TEAM_FAILED";


export interface BeginJoinTeamAction {
  type: typeof BEGIN_JOIN_TEAM,
  teamId: string
}

export interface JoinTeamSuccessAction {
  type: typeof JOIN_TEAM_SUCCESS,
  teamId: string
}

export interface JoinTeamFailedAction {
  type: typeof JOIN_TEAM_FAILED,
  teamId: string
}

export type JoinTeamsActions = BeginJoinTeamAction | JoinTeamSuccessAction | JoinTeamFailedAction