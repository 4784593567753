//import { AppActions } from '../types';
import * as _ from 'lodash';

import {
    FormField,
    ClassifyTeamState,
    TeamClassicationActionTypes,
    BeginClassifyTeamAction, BEGIN_CLASSIFY_TEAM,
    ClassifyTeamSuccessAction, CLASSIFY_TEAM_SUCCESS,
    ClassifyTeamFailedAction, CLASSIFY_TEAM_FAILED,
    BeginExcludeTeamAction, BEGIN_EXCLUDE_TEAM,
    ExcludeTeamSuccessAction, EXCLUDE_TEAM_SUCCESS,
    ExcludeTeamFailedAction, EXCLUDE_TEAM_FAILED,
    SET_SELECTED_CLASSIFICATION_TEMPLATE,
    SET_CLASSIFICATION_FIELD_VALUE,
    INITIALISE_CLASSIFICATION_FIELDS,
} from '../types/classifyTeam';

import { ClearStateAction, CLEAR_STATE } from '../types/clearState';

import {
    Term,
    TermSetActionTypes,
    BeginGetTermSetAction, BEGIN_GET_TERM_SET,
    GetTermSetSuccessAction, GET_TERM_SET_SUCCESS,
    GetTermSetFailedAction, GET_TERM_SET_FAILED,
} from '../types/sharePointTermSet';

import {
    fieldIsValid
} from '../api/fieldIsValid';

const initialState: ClassifyTeamState = {
    fields: {},
    termSets: {},
    selectedTemplate: null
};

export const classifyTeamReducer = (state = initialState, action: TeamClassicationActionTypes | TermSetActionTypes | ClearStateAction): ClassifyTeamState => {

    switch (action.type) {
        case CLEAR_STATE:
            return initialState;
        case BEGIN_CLASSIFY_TEAM:
            return {
                ...state,
                loading: true
            };
        case CLASSIFY_TEAM_SUCCESS:
        case CLASSIFY_TEAM_FAILED:
            return {
                ...state,
                loading: false
            };
        case BEGIN_EXCLUDE_TEAM:
            return {
                ...state,
                loading: true
            };
        case EXCLUDE_TEAM_SUCCESS:
        case EXCLUDE_TEAM_FAILED:
            return {
                ...state,
                loading: false
            };
        case GET_TERM_SET_SUCCESS:
            return {
                ...state,
                termSets: {
                    ...state.termSets,
                    [action.id]: action.data
                }
            }
        case SET_SELECTED_CLASSIFICATION_TEMPLATE:
            return {
                ...state,
                selectedTemplate: action.template
            }
        case INITIALISE_CLASSIFICATION_FIELDS:
            return {
                ...state,
                fields: action.fields
            }
        case SET_CLASSIFICATION_FIELD_VALUE:
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [action.fieldName]: {
                        ...state.fields[action.fieldName],
                        value: action.fieldValue,
                        isDirty: true,
                        isValid: fieldIsValid(state.fields[action.fieldName], action.fieldValue)
                    }
                }
            }
    }

    return state;
}

export default classifyTeamReducer;