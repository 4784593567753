import * as _f from 'lodash/fp';

import { SharePointClassification, SharePointField } from "../types/sharePointTenantProperties";

export const getMergedMetadataFields = (classifications: SharePointClassification[]): SharePointField[] => {

    return _f.pipe(
        _f.flatMap<SharePointClassification, SharePointField[]>((v) => v.fields),
        _f.values,
        _f.filter(f => f.type == "ManagedMetadata" || f.type == "Text"),
        _f.uniqBy(f => f.staticName),
    )(classifications);
}