import { ISuggestedFileState } from "../api/getSuggestedFiles";
import { SearchResult } from ".";
//import { IPlaceholderFile } from "../reducers/recentFiles";

export const BEGIN_RECENT_FILES_GET = "BEGIN_RECENT_FILES_GET";
export const RECENT_FILES_GET_SUCCESS = "RECENT_FILES_GET_SUCCESS";
export const RECENT_FILES_GET_FAILED = "RECENT_FILES_GET_FAILED";

export const BEGIN_TRENDING_FILES_GET = "BEGIN_TRENDING_FILES_GET";
export const TRENDING_FILES_GET_SUCCESS = "TRENDING_FILES_GET_SUCCESS";
export const TRENDING_FILES_GET_FAILED = "TRENDING_FILES_GET_FAILED";

export interface BeginRecentFilesGetAction {
    type: typeof BEGIN_RECENT_FILES_GET;
}

export interface BeginTrendingFilesGetAction {
    type: typeof BEGIN_TRENDING_FILES_GET;
}

export interface RecentFilesGetSuccessAction {
    type: typeof RECENT_FILES_GET_SUCCESS;
    files: SearchResult[];
}

export interface TrendingFilesGetSuccessAction {
    type: typeof TRENDING_FILES_GET_SUCCESS;
    files: SearchResult[];
}

export interface RecentFilesGetFailedAction {
    type: typeof RECENT_FILES_GET_FAILED;
}

export interface TrendingFilesGetFailedAction {
    type: typeof TRENDING_FILES_GET_FAILED;
}

export type PlaceholderFilesActions = BeginRecentFilesGetAction
    | BeginTrendingFilesGetAction
    | RecentFilesGetSuccessAction
    | RecentFilesGetFailedAction
    | TrendingFilesGetFailedAction
    | TrendingFilesGetSuccessAction