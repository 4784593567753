import * as React from 'react';
import { connect } from 'react-redux';
import { checkLicenseThunk } from '../actions/getSharePointTenantProperties';
import { AppState } from '../reducers';
import { LicensePage } from './LicensePage';

interface ILicenseCheckProps {
    children: React.ReactNode;
    appState?: AppState;
    dispatch?: any;
}

interface ILicenseCheckState {
}

class LicenseCheck extends React.Component<ILicenseCheckProps, ILicenseCheckState> {

    constructor(props: ILicenseCheckProps) {
        super(props);
    }

    public componentDidMount() {
        const { dispatch } = this.props;
        dispatch(checkLicenseThunk());
    }

    public render() {

        const licenseStatus = this.props.appState.license.status;

        if (licenseStatus == "Valid") {
            return <div>{this.props.children}</div>
        }
        else {
            return <LicensePage />;
        }
    }
}

const mapStateToProps = (state: AppState) => ({ appState: state });

const connected = connect(mapStateToProps, null)(LicenseCheck);

export { connected as LicenseCheck };
export default connected;