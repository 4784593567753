import * as React from 'react';
import { ShimmerThemed } from './ShimmerThemed';

interface IShimmerSearchProps { }

export const ShimmerSearch: React.FunctionComponent<IShimmerSearchProps> = (props) => {
    return (
        <div className="nag-shimmerContainer">
            <ShimmerThemed />
            <ShimmerThemed />
            <ShimmerThemed />
            <ShimmerThemed />
            <ShimmerThemed />
        </div>
    )
}