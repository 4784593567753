import * as React from 'react';
import { css } from 'office-ui-fabric-react';

export interface ISectionHeadingProps {
    heading: string;
    hasBorder?: boolean;
}

export const SectionHeading: React.FunctionComponent<ISectionHeadingProps> = (props) => {

    const { heading, hasBorder } = props

    return <h2 className={css("nag-sectionHeading", hasBorder && "has-border")}>{heading}</h2>
}

