import { BeginJoinTeamAction, BEGIN_JOIN_TEAM, JOIN_TEAM_SUCCESS, JOIN_TEAM_FAILED, JoinTeamFailedAction, JoinTeamSuccessAction, JoinTeamsActions } from "../types/joinTeam";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { AppState } from "../reducers";
import { AnyAction } from "redux";
import { beginRetrieveMe } from "./userActions";
import { joinTeam } from "../api/joinTeam";
import { getTeamInfo } from "../api/teams";
import { TeamState } from "../types/teams";
import { UPDATE_DISCOVER_TEAMS_METADATAFILTER } from "../types/discoverTeams";
import { updateTeamData } from "./teamsActions";
import { getSSOGraphToken } from "../api/ssoTokens";
import { getMe } from "../api/getMe";

export const beginJoinTeam = (teamId: string): BeginJoinTeamAction => ({
    type: BEGIN_JOIN_TEAM,
    teamId
});

export const joinTeamSuccess = (teamId: string): JoinTeamSuccessAction => ({
    type: JOIN_TEAM_SUCCESS,
    teamId
});

export const joinTeamFailed = (teamId: string): JoinTeamFailedAction => ({
    type: JOIN_TEAM_FAILED,
    teamId
});

export const joinTeamThunk = (groupId: string): ThunkAction<Promise<void>, AppState, {}, JoinTeamsActions> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> => {

        const appState = getState();
        const { teams } = appState;

        // const userState = getState().user;
        // const meId = userState.info.id;

        return new Promise<void>(async resolve => {

            try {
                dispatch(beginJoinTeam(groupId));

                const token = await getSSOGraphToken();

                const me = await getMe(token);

                console.log("G", groupId, me.id, token);

                const joinResult = await joinTeam(token, groupId, me.id);

                if (joinResult) {

                    //give a few seconds to update the team
                    setTimeout(async () => {

                        const teamData = await getTeamInfo(token, groupId);

                        const team: TeamState = {
                            ...teams[groupId],
                            internalId: teamData.internalId,
                            isArchived: teamData.isArchived
                        }

                        dispatch(updateTeamData(team));

                        dispatch(joinTeamSuccess(groupId));
                    }, 2000);
                } else {
                    dispatch(joinTeamFailed(groupId));
                }
            }
            catch (err) {
                console.log("catched", err);
                dispatch(joinTeamFailed(groupId));
            }
            resolve();
        });
    }
}
