import {
    PlaceholderFilesActions, BEGIN_RECENT_FILES_GET, BEGIN_TRENDING_FILES_GET,
    RECENT_FILES_GET_SUCCESS, RECENT_FILES_GET_FAILED
} from "../types/recentFiles";
import { SearchResult } from "../types";

export interface PlaceholderFilesState {
    status: "None" | "Loading" | "Loaded";
    files: SearchResult[];
}

export const initialState: PlaceholderFilesState = {
    status: "None",
    files: []
}

export const recentFilesReducer = (state: PlaceholderFilesState = initialState, action: PlaceholderFilesActions) => {

    switch (action.type) {
        case BEGIN_RECENT_FILES_GET:
            return state;
        case RECENT_FILES_GET_SUCCESS:
            return {
                ...state,
                files: action.files
            };
        case RECENT_FILES_GET_FAILED:
            return state;
    }
    return state;
}

export default recentFilesReducer;