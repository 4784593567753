import * as React from 'react';
import { IIconProps } from 'office-ui-fabric-react/lib/components/Icon';
import { IContextualMenuProps, IContextualMenuItem, IconButton } from 'office-ui-fabric-react';
import { TeamState } from '../types/teams';
import { joinTeam } from '../api/joinTeam';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { JoinTeamsActions } from '../types/joinTeam';
import { AppState } from '../reducers';
import { SearchResult } from '../types';

export interface IContentContextMenuProps {
    result: SearchResult,
    openInTeams: (item: SearchResult) => void,
    openInSharePoint: (item: SearchResult) => void
}

export const ContentContextMenu: React.FunctionComponent<IContentContextMenuProps> = (props) => {

    const iconProp: IIconProps = {
        iconName: "More"
    }

    const menuIconProps: IIconProps = {

    }

    const { result, openInTeams, openInSharePoint } = props;

    const menuItems: IContextualMenuItem[] = [
        {
            key: "navigate",
            name: "Open in Teams",
            iconProps: {
                iconName: "TeamsLogo"
            },
            onClick: () => openInTeams(result),
        },
        {
            key: "navigateToFiles",
            name: "Open in SharePoint",
            iconProps: {
                iconName: "SharePointLogo"
            },
            onClick: () => openInSharePoint(result),
        }]

    const menuProps: IContextualMenuProps = {
        items: menuItems
    }
    return <IconButton iconProps={iconProp} menuIconProps={menuIconProps} menuProps={menuProps} />
}