
export const isAuthRoute = (route: string) => {
    switch (route) {
        case "/tokencallback":
        case "/sharepointauth":
        case "/auth":
            return true;
        default:
            return false;
    }
}

export const isTokenCallback = (route: string) => {
    switch (route) {
        case "/tokencallback":
            return true;
        default:
            return false;
    }
}

export const isAuthCallback = (route: string) => {
    switch (route) {
        case "/authcallback":
            return true;
        default:
            return false;
    }
}

export const isAuthStart = (route: string) => {
    switch (route) {
        case "/authstart":
            return true;
        default:
            return false;
    }
}