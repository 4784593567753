import { ITenantProperties, TenantPropertiesActions, BEGIN_RETRIEVE_TENANT_PROPERTIES, RETRIEVE_TENANT_PROPERTIES_SUCCESS, RETRIEVE_TENANT_PROPERTIES_FAILED } from "../types/tenantProperties";
import { SharePointTenantProperties, UpdateSharePointTenantPropertiesAction, UPDATE_SHAREPOINT_TENANT_PROPERTIES } from "../types/sharePointTenantProperties";
import { ClearStateAction, CLEAR_STATE } from "../types/clearState";

const initialState: SharePointTenantProperties = {
    lastRetrieved: null,
    hasCollaborateConfiguration: false,
    classifications: [],
    siteSetupFunctionUrl: null
}

type tenantPropertiesReducerActions = UpdateSharePointTenantPropertiesAction | ClearStateAction;

export const tenantPropertiesReducer = (state: SharePointTenantProperties = initialState, action: tenantPropertiesReducerActions): SharePointTenantProperties => {

    switch (action.type) {
        case CLEAR_STATE:
            return initialState;
        case UPDATE_SHAREPOINT_TENANT_PROPERTIES:
            return action.data;
        default:
            return state;
    }
}

export default tenantPropertiesReducer;