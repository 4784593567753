import * as React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import { Shimmer, ShimmerElementType, IShimmerElement, IShimmerColors } from 'office-ui-fabric-react';
import { ThemeStyle } from 'msteams-ui-styles-core';
import { getThemeFromString } from '../api/getEnvironment';

export interface IShimmerThemedProps {

    elements?: IShimmerElement[],
}

export interface IShimmerThemedState {
    theme: ThemeStyle,
    loading: boolean,
}

class ShimmerThemed extends React.Component<IShimmerThemedProps, IShimmerThemedState> {

    constructor(props: IShimmerThemedProps) {
        super(props);

        this.state = {
            loading: true,
            theme: ThemeStyle.Light
        }
    }

    public componentDidMount() {

        //microsoftTeams.registerOnThemeChangeHandler(this.updateTheme);

        microsoftTeams.getContext(async (context: microsoftTeams.Context) => {
            this.updateTheme(context.theme);
            this.setState({ loading: false });
        });
    }

    protected updateTheme = (themeStr?: string): ThemeStyle => {
        const theme = getThemeFromString(themeStr);
        this.setState({ theme });
        return theme;
    }

    public render() {

        const { theme, loading } = this.state;

        if (loading) {
            return <div></div>
        }

        const { elements } = this.props;

        const items = elements ? elements : [
            { type: ShimmerElementType.line, height: 26, width: 36 },
            { type: ShimmerElementType.gap, width: '5%' },
            { type: ShimmerElementType.line, height: 26, width: '20%' },
            { type: ShimmerElementType.gap, width: '5%' },
            { type: ShimmerElementType.line, height: 26, width: '20%' },
            { type: ShimmerElementType.gap, width: '10%' },
            { type: ShimmerElementType.line, height: 26, width: '15%' },
            { type: ShimmerElementType.gap, width: '10%' },
            { type: ShimmerElementType.line, height: 26 }
        ]

        let colors: IShimmerColors = null;

        switch (theme) {
            case ThemeStyle.Dark:
                colors = {
                    shimmer: "#252424",
                    shimmerWave: "#484644",
                    background: "#2d2c2c",
                }
                break;
            case ThemeStyle.HighContrast:
                colors = {
                    shimmer: "white",
                    shimmerWave: "aqua",
                    background: "black",
                }
        }

        return <div className="nag-tableshimmer">
            <Shimmer
                shimmerColors={colors}
                shimmerElements={items}
            />
        </div>
    }
}
export { ShimmerThemed }