import { IStackItemStyles, IStackTokens } from "office-ui-fabric-react"

export const getPWidth = (pwidth: string): IStackItemStyles => {
    return {
        root: {
            width: pwidth
        }
    }
}

export const generateGap = (gap: number): IStackTokens => ({ childrenGap: gap });