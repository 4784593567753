
export const UPDATE_SHAREPOINT_TENANT_PROPERTIES = "RETRIEVE_SHAREPOINT_TENANT_PROPERTIES";

export interface SharePointField {
    type: "ManagedMetadata" | "Text" | "Boolean",
    staticName: string,
    displayName: string,
    searchAlias?: string,
    termSetId?: string,
    required?: boolean,
    hiddenInWizard: boolean,
    multiSelect?: boolean,
    defaultValue: string | boolean | string[],
}

export interface SharePointClassification {
    key: string;
    name: string;
    icon: string;
    fields: SharePointField[];
    description: string;
    hidden: boolean;
}

export interface SharePointTenantProperties {
    hasCollaborateConfiguration: boolean;
    lastRetrieved: Date;
    classifications: SharePointClassification[];
    siteSetupFunctionUrl: string;
}

export interface UpdateSharePointTenantPropertiesAction {
    type: typeof UPDATE_SHAREPOINT_TENANT_PROPERTIES,
    data: SharePointTenantProperties
}
