import { IMetadata } from "./teams";

export interface IDiscoverTeamsState {
    searchTerm: string;
    classification: string;
    metadata?: IMetadata;
    loadingState: "None" | "Loading" | "Error" | "BackgroundLoading" | "Loaded";
    lastLoaded: Date;
    lastBackgroundLoaded: Date;
    suggestedTeamIds: string[],
    suggestedTeamStatus: "None" | "Loaded" | "Loading",
    suggestedTeamLastUpdated: Date
}

export const UPDATE_SUGGESTED_TEAMS = "UPDATE_SUGGESTED_TEAMS";
export const UPDATE_DISCOVER_TEAMS_QUERY = "UPDATE_DISCOVER_TEAMS_QUERY";
export const UPDATE_DISCOVER_TEAMS_CLASSIFICATION = "UPDATE_DISCOVER_TEAMS_CLASSIFICATION";
export const UPDATE_DISCOVER_TEAMS_METADATAFILTER = "UPDATE_DISCOVER_TEAMS_METADATAFILTER";

export interface UpdateDiscoverTeamsQueryAction {
    type: typeof UPDATE_DISCOVER_TEAMS_QUERY,
    query: string
}

export interface UpdateDiscoverTeamsClassificationAction {
    type: typeof UPDATE_DISCOVER_TEAMS_CLASSIFICATION,
    query: string
}

export interface UpdateDiscoverTeamsMetadataFilterAction {
    type: typeof UPDATE_DISCOVER_TEAMS_METADATAFILTER,
    key: string,
    value: string
}

export interface UpdateSuggestedTeamsAction {
    type: typeof UPDATE_SUGGESTED_TEAMS,
    suggestedTeamIds?: string[],
    suggestedTeamStatus?: "None" | "Loaded" | "Loading",
    suggestedTeamLastUpdated?: Date
}

export type DiscoverTeamsActions = UpdateDiscoverTeamsQueryAction
    | UpdateDiscoverTeamsClassificationAction
    | UpdateDiscoverTeamsMetadataFilterAction
    | UpdateSuggestedTeamsAction;
