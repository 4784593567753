import { ThemeStyle } from "msteams-ui-styles-core";

export interface EnvironmentPermissions {
    allSitesRead: boolean;
    directoryReadAll: boolean;
    groupReadAll: boolean;
    groupReadWriteAll: boolean;
    sitesReadAll: boolean;
    sitesSearchAll: boolean;
    termStoreReadAll: boolean;
    userRead: boolean;
}

export interface EnvironmentState {
    sharePointRoot: string;
    tenantId: string;
    theme: string;
    themeType: ThemeStyle;
    loginHint: string;
    groupId: string;
    teamId: string;
    teamName: string;
    sharePointSiteUrl: string;
    permissions: EnvironmentPermissions;
}

export const UPDATE_ENVIRONMENT = "UPDATE_ENVIRONMENT";

export interface UpdateEnvironmentAction {
    type: typeof UPDATE_ENVIRONMENT;
    state: Partial<EnvironmentState>
}

export type EnvironmentActions = UpdateEnvironmentAction 