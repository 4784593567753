import { Term } from './sharePointTermSet';
import { SharePointField } from "./sharePointTenantProperties";

export const BEGIN_CLASSIFY_TEAM = "BEGIN_CLASSIFY_TEAM";
export const CLASSIFY_TEAM_SUCCESS = "CLASSIFY_TEAM_SUCCESS";
export const CLASSIFY_TEAM_FAILED = "CLASSIFY_TEAM_FAILED";

export const BEGIN_EXCLUDE_TEAM = "BEGIN_EXCLUDE_TEAM";
export const EXCLUDE_TEAM_SUCCESS = "EXCLUDE_TEAM_SUCCESS";
export const EXCLUDE_TEAM_FAILED = "EXCLUDE_TEAM_FAILED";

export const SET_SELECTED_CLASSIFICATION_TEMPLATE = "SET_SELECTED_CLASSIFICATION_TEMPLATE";
export const INITIALISE_CLASSIFICATION_FIELDS = "INITIALISE_CLASSIFICATION_FIELDS";
export const SET_CLASSIFICATION_FIELD_VALUE = "SET_CLASSIFICATION_FIELD_VALUE";

export enum SiteClassificationStatus {
  Unknown,
  InProgress,
  Complete,
  Error
}

export interface SiteClassificationProgress {
  status: SiteClassificationStatus;
  description: string;
  percentage: number;
  retryData?: any;
}

export interface FormField extends SharePointField {
  value: string | boolean | string[];
  isDirty: boolean;
  isValid: boolean;
}

export interface ClassifyTeamState {
  view?: "Welcome" | "Template" | "Metadata" | "Confirm" | "View" | "Edit";
  loading?: boolean;
  selectedTemplate?: string;
  fields?: { [key: string]: FormField };
  termSets?: { [key: string]: Array<Term> }
}

export interface BeginClassifyTeamAction {
  type: typeof BEGIN_CLASSIFY_TEAM,
}

export interface ClassifyTeamSuccessAction {
  type: typeof CLASSIFY_TEAM_SUCCESS,
}

export interface ClassifyTeamFailedAction {
  type: typeof CLASSIFY_TEAM_FAILED
}

export interface BeginExcludeTeamAction {
  type: typeof BEGIN_EXCLUDE_TEAM,
}

export interface ExcludeTeamSuccessAction {
  type: typeof EXCLUDE_TEAM_SUCCESS,
}

export interface ExcludeTeamFailedAction {
  type: typeof EXCLUDE_TEAM_FAILED
}

export interface SetSelectedClassificationTemplateAction {
  type: typeof SET_SELECTED_CLASSIFICATION_TEMPLATE,
  template: string
}

export interface SetClassificationFieldValueAction {
  type: typeof SET_CLASSIFICATION_FIELD_VALUE,
  fieldName: string,
  fieldValue: string | boolean
}

export interface InitialiseClassificationFieldsAction {
  type: typeof INITIALISE_CLASSIFICATION_FIELDS,
  fields: { [key: string]: FormField }
}

export type TeamClassicationActionTypes =
  BeginClassifyTeamAction |
  ClassifyTeamSuccessAction |
  ClassifyTeamFailedAction |
  BeginExcludeTeamAction |
  ExcludeTeamSuccessAction |
  ExcludeTeamFailedAction |
  SetSelectedClassificationTemplateAction |
  SetClassificationFieldValueAction |
  InitialiseClassificationFieldsAction
