import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';

interface IEmptySearchPanelProps { }

export const EmptySearchPanel: React.FunctionComponent<IEmptySearchPanelProps> = (props) => {

    return <div className="nag-emptysearch">
        <Icon iconName="Rain" />
        <div className="nag-emptymessage">Sorry, no matches could be found</div>
    </div>
}