import * as microsoftTeams from '@microsoft/teams-js';
import createConfig from "../config";

const scopeConfig = createConfig();

var scopeMap = {
    "general": scopeConfig.generalScopes,
    "graph": scopeConfig.graphScopes,
    "sharePoint": scopeConfig.sharePointScopes
}

export const getScopesForConsent = (sharePointUrl: string) => {

    const scopes = [
        ...getScopes("general"),
        ...getScopes("graph").map(s => `https://graph.microsoft.com/${s}`),
        ...getScopes("sharePoint").map(s => `${sharePointUrl}/${s}`)
    ];

    console.log("SCOPES FOR CONEST", scopes);
    return scopes;
}

const getScopes = (label: "graph" | "sharePoint" | "general") => {
    return scopeMap[label];
}

export const getSSOToken = (bypassCache: boolean = false): Promise<string> => {
    return new Promise<string>((resolve, reject) => {

        var request: microsoftTeams.authentication.AuthTokenRequest = {

            successCallback: async (teamsToken) => {
                resolve(teamsToken);
            },
            failureCallback: (error) => {
                reject(error);
            },
            resources: [],
        }
        microsoftTeams.authentication.getAuthToken(request);

    });
}

export const getSSOGraphToken = async (bypassCache: boolean = false): Promise<string> => {

    return new Promise<string>((resolve, reject) => {

        microsoftTeams.getContext((context) => {

            const tenantId = context.tid;
            const userId = context.userObjectId;

            var request: microsoftTeams.authentication.AuthTokenRequest = {

                successCallback: async (teamsToken) => {
                    var graphToken = await getGraphToken(tenantId, userId, teamsToken, bypassCache);
                    resolve(graphToken);
                },
                failureCallback: (error) => {
                    reject(error);
                },
                resources: [],
            }
            microsoftTeams.authentication.getAuthToken(request);
        });
    });
}

export const getSSOSharePointToken = async (bypassCache: boolean = false): Promise<string> => {

    return new Promise<string>((resolve, reject) => {

        microsoftTeams.getContext((context) => {

            const sharePointUrl = `https://${context.teamSiteDomain}`;
            const tenantId = context.tid;
            const userId = context.userObjectId;

            var request: microsoftTeams.authentication.AuthTokenRequest = {

                successCallback: async (teamsToken) => {
                    var graphToken = await getSharePointToken(tenantId, userId, teamsToken, sharePointUrl, bypassCache);
                    resolve(graphToken);
                },
                failureCallback: (error) => {
                    reject(error);
                },
                resources: [],
            }
            microsoftTeams.authentication.getAuthToken(request);
        });
    });
}

export const getSSOApplicationToken = async (bypassCache: boolean = false): Promise<string> => {

    return new Promise<string>((resolve, reject) => {

        microsoftTeams.getContext((context) => {

            const config = createConfig();
            const tenantId = context.tid;
            const userId = context.userObjectId;

            var request: microsoftTeams.authentication.AuthTokenRequest = {

                successCallback: async (teamsToken) => {
                    var graphToken = await getApplicationToken(tenantId, userId, teamsToken, config.clientId, bypassCache);
                    resolve(graphToken);
                },
                failureCallback: (error) => {
                    reject(error);
                },
                resources: [],
            }
            microsoftTeams.authentication.getAuthToken(request);
        });
    });
}

export const getGraphToken = (tenantId: string, userId: string, ssoToken: string, bypassCache: boolean = false): Promise<string> => {
    var scopes = getScopes("sharePoint").map(s => `https://graph.microsoft.com/${s}`);
    return getCachedToken("graph", tenantId, userId, ssoToken, scopes, bypassCache);
}

export const getSharePointToken = (tenantId: string, userId: string, ssoToken: string, sharePointUrl: string, bypassCache: boolean = false): Promise<string> => {
    var scopes = getScopes("sharePoint").map(s => `${sharePointUrl}/${s}`);
    return getCachedToken("sharePoint", tenantId, userId, ssoToken, scopes, bypassCache);
}

export const getApplicationToken = (tenantId: string, userId: string, ssoToken: string, clientId: string, bypassCache: boolean = false): Promise<string> => {
    var scopes = [`${clientId}/.default`];// getScopes("sharePoint").map(s => `${sharePointUrl}/${s}`);
    return getCachedToken("application", tenantId, userId, ssoToken, scopes, bypassCache);
}

function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

function getCachedToken(label: string, tenantId: string, userId: string, ssoToken: string, scopes: string[], bypassCache: boolean = false): Promise<string> {

    var key = `token.${label}.${tenantId}.${userId}`;

    return new Promise<string>((resolve, reject) => {
        var cached = localStorage.getItem(key);
        if (cached !== null && !bypassCache) {

            var token = parseJwt(cached);

            //console.log(token);

            var expiry = new Date(token.exp * 1000);
            var isTokenExpired = expiry < new Date();
            console.log(expiry, isTokenExpired ? "Expired" : "OK");

            if (!isTokenExpired) {
                resolve(cached);
                return;
            }
        }

        fetch("/token", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'tid': tenantId,
                'token': ssoToken,
                'scopes': scopes,
            }),
            mode: 'cors',
            cache: 'default'
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    reject(response.error);
                }
            })
            .then((responseJson) => {
                if (responseJson.error) {
                    reject(responseJson.error);
                } else {
                    const serverSideToken = responseJson;
                    //console.log("SST", serverSideToken);
                    localStorage.setItem(key, serverSideToken);
                    resolve(serverSideToken);
                }
            });
    });
}