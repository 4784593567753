import * as React from 'react';
import * as _ from 'lodash';
import * as _f from 'lodash/fp'

import {
    DetailsList, DetailsRow, IDetailsRowProps,
    SelectionMode,
    IColumn, IDetailsHeaderProps, DetailsHeader, IDetailsFooterProps, IDetailsListProps,
    ColumnActionsMode
} from 'office-ui-fabric-react/lib/DetailsList';

import { SharePointTenantProperties, } from '../types/sharePointTenantProperties';
import { DefaultButton, Link, ContextualMenu, IContextualMenuProps, Checkbox, IContextualMenuItem } from 'office-ui-fabric-react';
import { SearchResult, } from '../types';
import { Dispatch } from 'redux';
import { ContentContextMenu, } from './DiscoverContentContextMenu';
import { getMergedMetadataFields } from '../api/getMergedMetadataFields';
import { executeSearchMoreThunk } from '../actions/contentSearchActions';
import { AppState } from '../reducers';
import { FileLogo } from './FileLogo';
import { openInTeams, openInSharePoint } from '../api/openFile';
import { on } from 'cluster';
import { updateVisibleContentColumns } from '../actions/userPreferences';

export interface IDiscoverContentTableProps {
    appState: AppState,
    results: SearchResult[];
    tenantProperties: SharePointTenantProperties;
    dispatch: Dispatch<any>;
    showAuthor: boolean;
}

const renderMetadataColumn = (item: SearchResult, index: number, column: IColumn): JSX.Element => {

    if (item && item.extraProperties) {
        const val = item.extraProperties[column.key];
        return <span>{val}</span>
    }
    else {
        return <span></span>
    }
}

const renderItemColumn = (item: any, index: number, column: IColumn): JSX.Element => {

    const content = item[column.fieldName] as string;

    return <span>{content}</span>
}

const renderIcon = (item: SearchResult, index: number, column: IColumn): any => {

    return <FileLogo fileExtension={item.fileType} />
}

const renderModified = (item: SearchResult, index: number, column: IColumn): any => {
    if (!item.modified) {
        return "";
    }
    const m = new Date(item.modified);

    return m.toLocaleDateString("en-AU");
    //return `${m.getDate()}/${m.getMonth() + 1}/${m.getFullYear()}`
}

const renderDocumentName = (item: SearchResult, index: number, column: IColumn): any => {

    return <Link className="nag-tableLink" onClick={() => openInTeams(item)}>{item.itemName}</Link>
}

const onRenderActions = (dispatch: Dispatch<any>) => (item: SearchResult, index: number, column: IColumn): JSX.Element => {

    return <ContentContextMenu result={item}
        openInTeams={(item: SearchResult) => openInTeams(item)}
        openInSharePoint={(item: SearchResult) => openInSharePoint(item)} />
}

const onRenderRow = (props: IDetailsRowProps): JSX.Element => {

    const className = `nag-tableRow`

    return <DetailsRow {...props} className={className} />
}

const renderFooterColumn = (load: any) => (props: any): JSX.Element => {

    return <div><DefaultButton className={"nag-showMore"} onClick={load}>Load more</DefaultButton></div>
}

const renderFooter = (load: any, appState: AppState) => (props: IDetailsFooterProps): JSX.Element => {

    const { resultCount, results } = appState.discoverContent;

    if (resultCount > results.length) {

        const cols: IColumn[] = [
            {
                key: "type",
                name: "",
                minWidth: 20,
                maxWidth: 40
            },
        ]
        return <DetailsRow className="nag-tableFooter" {...props} columns={cols} onRenderItemColumn={renderFooterColumn(load)} item={{}} itemIndex={-1} />
    }
    else {
        return null;
    }
}

const getMetadataColumns = (tenantProperties: SharePointTenantProperties): IColumn[] => {

    if (tenantProperties.hasCollaborateConfiguration) {
        const mergedFields = getMergedMetadataFields(tenantProperties.classifications);

        return mergedFields.map(f => {

            const r: IColumn = {
                key: f.staticName,
                name: f.displayName,
                minWidth: 80,
                isResizable: true,
                onRender: renderMetadataColumn
            }
            return r;
        });
    }
    else {
        return [];
    }
}

const renderHeader: IDetailsListProps['onRenderDetailsHeader'] = (props: IDetailsHeaderProps) => {
    if (props) {
        return <DetailsHeader {...props} />
    }
    return null;
}

const fixedColumnKeys = ["type", "itemname", "actions"];

const getCustomisableColumns = (columns: IColumn[]): IColumn[] => {

    const customisableColumns = columns.filter(c => fixedColumnKeys.indexOf(c.key) == -1);
    return customisableColumns;
}

const getActionsContextMenuProps = (columns: IColumn[], visibleColumns: string[], visibleColumnsWithContent: string[],
    event: React.MouseEvent<HTMLElement>, onDismiss: () => void, onItemChecked: (column: IColumn, checked: boolean) => void): IContextualMenuProps => {

    var customisableColumns = getCustomisableColumns(columns);
    customisableColumns = customisableColumns.filter(c => visibleColumnsWithContent.indexOf(c.key) >= 0);

    const menuItems = customisableColumns.map(c => {

        const checked = visibleColumns.indexOf(c.key) >= 0;

        return {
            key: c.key,
            text: c.name,
            onRender: (item: IContextualMenuItem) => {
                return <div className="nag-checkboxMenuItem"><Checkbox label={item.text} defaultChecked={checked} onChange={(event, checked) => onItemChecked(c, checked)} /></div>
            },
            onClick: (ev: React.MouseEvent<HTMLElement>) => { ev.preventDefault(); return false; }
        }
    });

    return {
        items: menuItems,
        target: event.currentTarget as HTMLElement,
        onDismiss
    }
}

const getColumnsWithContent = (items: SearchResult[], tableColumns: IColumn[]) => {
    const columns = getCustomisableColumns(tableColumns);
    return columns.filter(c => {
        // only check first 100 items for columns - don't want extra columns spawning after loading next page
        for (var i = 0; i < Math.min(items.length, 99); i++) {
            const item = items[i];
            const val = c.fieldName ? _.get(item, c.fieldName) : _.get(item, `extraProperties.${c.key}`);
            if (val) {
                return true;
            }
        }

        return false;
    }).map(c => c.key);
}

interface IDiscoverContentTableState {
    actionsContextMenuProps: IContextualMenuProps,
    tableColumns: IColumn[];
    visibleColumns: string[];
    columnsWithContent: string[];
}

export class DiscoverContentTable extends React.Component<IDiscoverContentTableProps, IDiscoverContentTableState>  {
    private listContainer = React.createRef<HTMLDivElement>();

    constructor(props: IDiscoverContentTableProps) {
        super(props);

        this.state = {
            actionsContextMenuProps: null,
            visibleColumns: null,
            tableColumns: [],
            columnsWithContent: [],
        }
    }

    public componentDidMount() {

        const { tenantProperties, dispatch, results } = this.props;

        const metaCols: IColumn[] = getMetadataColumns(tenantProperties);

        const tableColumns: IColumn[] = [
            {
                key: "type",
                name: "",
                minWidth: 22,
                maxWidth: 22,
                onRender: renderIcon
            },
            {
                key: "itemname",
                name: "File Name",
                minWidth: 130,
                fieldName: "itemName",
                isResizable: true,
                onRender: renderDocumentName
            },
            {
                key: "team",
                name: "Team",
                minWidth: 90,
                maxWidth: 180,
                fieldName: "siteTitle",
                isResizable: true,
            },
            {
                key: "modifiedBy",
                name: "Modified by",
                minWidth: 85,
                maxWidth: 140,
                fieldName: "modifiedBy",
                isResizable: true,
            },
            {
                key: "modified",
                name: "Modified",
                minWidth: 72,
                maxWidth: 100,
                fieldName: "modified",
                isResizable: true,
                onRender: renderModified,
            },
            ...metaCols,
            {
                key: "actions",
                name: "Actions",
                minWidth: 40,
                maxWidth: 40,
                onRender: onRenderActions(dispatch),
                className: "nag-actionColumn",
                headerClassName: "nag-actionColumnHeader",
                isIconOnly: true,
                iconName: 'ColumnOptions',
                onColumnClick: (event: React.MouseEvent<HTMLElement>, column: IColumn) => {
                    this.openActionsMenu(event);
                },
                onColumnContextMenu: (column: IColumn, event: React.MouseEvent<HTMLElement>) => {
                    this.openActionsMenu(event);
                },
                columnActionsMode: ColumnActionsMode.clickable,
            }
        ]

        const { discoverContentColumns } = this.props.appState.userPreferences;

        this.setState({
            tableColumns,
            visibleColumns: discoverContentColumns == null ? getCustomisableColumns(tableColumns).map(c => c.key) : discoverContentColumns,
            columnsWithContent: getColumnsWithContent(results, tableColumns)
        });
    }

    public componentDidUpdate(prevProps: IDiscoverContentTableProps) {
        const { results } = this.props;
        const { tableColumns } = this.state;

        if (JSON.stringify(prevProps.results) !== JSON.stringify(results)) {
            this.setState({
                columnsWithContent: getColumnsWithContent(results, tableColumns)
            });
        }
    }

    private dismissActionsMenu = () => {
        this.setState({
            actionsContextMenuProps: null
        });
    }

    private contextMenuItemChecked = (column: IColumn, checked: boolean) => {

        const { dispatch } = this.props;

        console.log("Checked", column.key, checked);

        if (checked) {
            const newCols = [...this.state.visibleColumns, column.key]
            this.setState({ visibleColumns: newCols });
            dispatch(updateVisibleContentColumns(newCols));
        }
        else {
            const newCols = this.state.visibleColumns.filter(x => x != column.key);
            this.setState({ visibleColumns: newCols });
            dispatch(updateVisibleContentColumns(newCols));
        }
    }

    private openActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
        const { tableColumns, visibleColumns, columnsWithContent: visibleColumnsWithContent } = this.state;

        this.setState({
            actionsContextMenuProps: getActionsContextMenuProps(tableColumns, visibleColumns, visibleColumnsWithContent, event, this.dismissActionsMenu, this.contextMenuItemChecked)
        });
    }

    public render() {

        const { results, dispatch, appState, } = this.props;
        const { tableColumns, visibleColumns, columnsWithContent: visibleColumnsWithContent } = this.state;
        const items = results;

        const loadNext = () => dispatch(executeSearchMoreThunk());

        var cols = tableColumns.filter(c => {
            if (fixedColumnKeys.indexOf(c.key) >= 0) {
                return true;
            }
            else if (visibleColumns.indexOf(c.key) >= 0) {
                return visibleColumnsWithContent.indexOf(c.key) >= 0;
            }
            else {
                return false;
            }
        });

        const reservedMinWidth = cols.filter(c => c.minWidth).map(c => c.minWidth).reduce((a, b) => a + b, 0);
        const reservedMaxWidth = cols.filter(c => c.maxWidth).map(c => c.maxWidth).reduce((a, b) => a + b, 0);
        const colsWithNoMaxWidth = cols.filter(c => !c.maxWidth && fixedColumnKeys.indexOf(c.key) === -1).length;
        const dynamicColCount = cols.length - fixedColumnKeys.length;
        // this will only calculate on render - we don't really need to care about window resizing and recalculating it
        // not worth the processing expense, given it'll fix itself next time they search something
        // for the first render we won't have anything, so default to 1600 which is the max width
        // 50 is arbitrary to take afterwards just to account for some padding etc, seemed to have a better end result
        const maxListWidth = _.get(this.listContainer, "current.clientWidth", 1600) - 50;
        const remainingListWidth = maxListWidth - reservedMaxWidth;
        const suggestedMaxColWidth = colsWithNoMaxWidth > 0 ? Math.min(remainingListWidth / colsWithNoMaxWidth, 160) : 0;
        const suggestedTitleMaxWidth = remainingListWidth - suggestedMaxColWidth * colsWithNoMaxWidth;

        cols = cols.map(c => {
            if (c.key === "itemname") {
                return {
                    ...c,
                    // want undefined if there are no columns so it's guaranteed to be greedy enough
                    maxWidth: dynamicColCount > 0 ? Math.max(suggestedTitleMaxWidth, 300) : undefined
                };
            }
            else if (c.maxWidth || fixedColumnKeys.indexOf(c.key) >= 0) {
                return c;
            }
            else {
                return {
                    ...c,
                    maxWidth: suggestedMaxColWidth
                };
            }
        });

        const actionsMenuProps = this.state.actionsContextMenuProps;

        return <div>
            {actionsMenuProps && <ContextualMenu {...actionsMenuProps} />}
            <div ref={this.listContainer} className="nag-tableContainer">
                <DetailsList columns={cols}
                    selectionMode={SelectionMode.none}
                    onRenderItemColumn={renderItemColumn}
                    onRenderDetailsFooter={renderFooter(loadNext, appState)}
                    onRenderRow={onRenderRow}
                    items={items}
                    onRenderDetailsHeader={renderHeader}
                />
            </div>
        </div>
    }
}