import * as React from 'react';
import { connect } from 'react-redux';

import { DiscoverTeams } from './DiscoverTeams';
import { ConfigureTeam } from './ConfigureTeam';
import { DiscoverContent } from './DiscoverContent';
import { Settings } from './Settings';

import { AppState } from '../reducers';
import { LoadingGeneralComponent } from './LoadingGeneral';
import { TeamTab } from './TeamTab';
import { checkSharePointTenantPropertiesThunk, updateSharePointTenantPropertiesAction } from '../actions/getSharePointTenantProperties';
import createConfig, { INavigatorConfiguration } from '../config';
import { getSharePointTenantProperties } from '../api/getSharePointTenantProperties';
import { getSSOSharePointToken } from '../api/ssoTokens';

export interface IContainerState {
    loading: boolean;
}

export interface IContainerProps {
    appState?: AppState;
    dispatch?: any;
}

class ContainerComponent extends React.Component<IContainerProps, IContainerState>{

    private config: INavigatorConfiguration;

    constructor(props: IContainerProps, state: IContainerState) {
        super(props, state);

        this.config = createConfig();

        this.state = {
            loading: true,
        };
    }

    public async componentDidMount() {

        const { dispatch, appState } = this.props;

        const environment = appState && appState.environment;
        const { tenantProperties } = appState;

        if (!tenantProperties.lastRetrieved) {

            console.log("initial checking tenant properties")
            const sharePointToken = await getSSOSharePointToken();
            const newProperties = await getSharePointTenantProperties(environment.sharePointRoot, sharePointToken);
            dispatch(updateSharePointTenantPropertiesAction(newProperties))

            this.setState({ loading: false });
        }
        else {
            console.log("background checking tenant properties")
            dispatch(checkSharePointTenantPropertiesThunk());
            this.setState({ loading: false });
        }
    }

    private getComponent(route: string): any {

        if (this.state.loading) {
            return <LoadingGeneralComponent message="Loading..." delay={200} />
            //return <div></div>
        }
        return this.getComponentFromRoute(route);
    }

    private getComponentFromRoute(route: string) {
        let Component: any;

        switch (route) {
            case '/configureteam':
                Component = ConfigureTeam;
                break;
            case '/discoverteams':
                Component = DiscoverTeams;
                break;
            case '/discovercontent':
                Component = DiscoverContent;
                break;
            case '/settings':
                Component = Settings;
                break;
            case '/team':
                Component = TeamTab;
                break;
            default:
                Component = null;
                break;
        }
        return <Component />;
    }

    public render() {
        return this.getComponent(window.location.pathname)
    }
}

const mapStateToProps = (state: AppState) => ({ appState: state });

const connected = connect(mapStateToProps, null)(ContainerComponent);

export { connected as Container };
export default connected;