import * as microsoftTeams from '@microsoft/teams-js';

import { SearchResult } from "../types";
export const openInSharePoint = (searchResult: SearchResult): void => {

    console.log("open in sharepoint", searchResult.linkingUrl);
    window.open(searchResult.linkingUrl);
}

export const openInTeams = (searchResult: SearchResult): void => {

    openDocumentInTeams(searchResult.uniqueId, searchResult.path, searchResult.fileType);
}

export const openDocumentInTeams = (uniqueId: string, url: string, fileType: string): any => {

    console.log("View in teams", uniqueId, url);

    const deepLink = `https://teams.microsoft.com/l/file/${uniqueId}?fileType=${fileType}&objectUrl=${url}`;
    console.log(deepLink);

    microsoftTeams.executeDeepLink(deepLink);

}