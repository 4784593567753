import { LicenseStatus, ILicenseCheckResult } from "../reducers/license";


export const checkLicense = (tenantId: string, token: string, baseUrl: string): Promise<ILicenseCheckResult> => {

    return new Promise<ILicenseCheckResult>(async (resolve) => {

        console.log("checking license", tenantId, token);

        const checkUrl = `${baseUrl}/LicenseCheck/${tenantId}`;

        var headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", `application/json`);

        fetch(checkUrl, { headers: headers, method: "POST" })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                else {
                    resolve({
                        tenantId,
                        licenseStatus: "Error",
                        message: resp.statusText
                    });
                }
            })
            .then((result: ILicenseCheckResult) => {
                resolve(result);
            })
            .catch(reason => {
                resolve({
                    tenantId,
                    licenseStatus: "Error",
                    message: reason
                });
            });
    });
}

export const activateLicense = (tenantId: string, token: string, baseUrl: string, licenseKey: string): Promise<ILicenseCheckResult> => {

    return new Promise<ILicenseCheckResult>(async (resolve) => {

        console.log("activating license", tenantId, token);

        const checkUrl = `${baseUrl}/Activate/${tenantId}`;

        var headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);
        headers.append("Content-Type", `application/json`);

        fetch(checkUrl, { headers: headers, method: "POST", body: JSON.stringify({ licenseKey }) })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                }
                else {
                    resolve({
                        tenantId,
                        licenseStatus: "Error",
                        message: resp.statusText
                    });
                }
            })
            .then((result: ILicenseCheckResult) => {
                resolve(result);
            })
            .catch(reason => {
                resolve({
                    tenantId,
                    licenseStatus: "Error",
                    message: reason
                });
            });
    });
}