import { combineReducers } from 'redux';
import teams from './teams';
import discoverContent from './discoverContent';
import discoverTeams from './discoverTeams';
import tenantProperties from './tenantProperties';
import recentFiles from './recentFiles';
import trendingFiles from './trendingFiles';
import teamImages from './teamImages';
import auth from './auth';
import environment from './environment';
import userPreferences from './userPreferences';
import license from './license';
import classifyTeam from './classifyTeam';

export const rootReducer = combineReducers({
  auth,
  environment,
  teams,
  discoverTeams,
  discoverContent,
  tenantProperties,
  recentFiles,
  trendingFiles,
  teamImages,
  userPreferences,
  license,
  classifyTeam
});

export default rootReducer;
export type AppState = ReturnType<typeof rootReducer>