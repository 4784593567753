import * as React from 'react';
import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { connect } from 'react-redux';
import { getSharePointSiteProperties } from '../api/getSharePointSiteProperties';
//import { getSharePointToken } from "../api/authenticate";
import * as _f from 'lodash/fp';
import { SharePointClassification, SharePointField } from '../types/sharePointTenantProperties';
import { Icon } from 'office-ui-fabric-react';
import { EnvironmentState } from '../types/environment';
import { getSharePointToken, getSSOSharePointToken } from '../api/ssoTokens';

// Temporarily removing the team tab functionality because it requires additional CORS configuration on the back-end to function

const tryJsonParse = (str: string, defaultVal: any = str) => {
    try {
        return str ? JSON.parse(str) : defaultVal;
    } catch (e) {
        return defaultVal;
    }
}

const getFieldValueFromWebProps = (props: { [key: string]: string }, field: SharePointField) => {
    var val = props[field.staticName];
    if (field.type === "ManagedMetadata") {
        const valObj = tryJsonParse(val) as any;
        if (_f.isString(valObj)) {
            return valObj;
        }
        else if (_f.isArray(valObj)) {
            return _f.pipe(
                _f.map((v: any) => v.Value),
                _f.join(', ')
            )(valObj);
        }
        else {
            return valObj.Value;
        }
    }
    return val;
}

export interface ITeamTabProps {
    appState: AppState;
    dispatch: Dispatch<any>
}

export interface ITeamTabState {
    webProps: { [key: string]: string }
    environment: EnvironmentState;
}

class TeamTabComponent extends React.Component<ITeamTabProps, ITeamTabState> {
    public constructor(props: ITeamTabProps, state: ITeamTabState) {
        super(props, state);
        this.state = {
            webProps: null,
            environment: null,
        };
    }



    public async componentDidMount() {
        //const { dispatch } = this.props;
        //dispatch(goToTeamClassificationView("Welcome"));
        const environment = this.props.appState.environment;
        const { sharePointSiteUrl, sharePointRoot, tenantId } = environment;

        const token = await getSSOSharePointToken();
        //const token = await getSharePointToken(sharePointRoot, tenantId);

        getSharePointSiteProperties(sharePointSiteUrl, token).then(props => {
            this.setState({
                webProps: props,
                environment
            });
        });
    }

    public render() {
        const { webProps, environment } = this.state;
        const { appState } = this.props;
        //const { environment } = appState;
        const { classifications, hasCollaborateConfiguration } = appState.tenantProperties;

        if (environment == null) {
            return <div></div>
        }

        if (hasCollaborateConfiguration) {
            if (webProps) {
                const enrolmentState = webProps["FiveP_EnrolmentState"];
                if (enrolmentState === "FiveP_Enrolled") {
                    const enrolledTemplate = tryJsonParse(webProps["FiveP_Template"], {});
                    const matchingClassification = _f.pipe(
                        _f.filter((c: SharePointClassification) => c.key === enrolledTemplate.Key),
                        _f.first
                    )(classifications);

                    if (matchingClassification) {
                        return (
                            <div>
                                <div className="nag-selectedClassificationText">
                                    Your team has been classified and is ready for collaboration! Documents uploaded into this team will automatically be tagged with this data to make them easier for you to find later.
                                </div>
                                {
                                    <div className="nag-selectedClassificationPreview">
                                        {
                                            matchingClassification &&
                                            <div className="nag-selectedClassificationPreviewTitle">
                                                <Icon iconName={matchingClassification.icon} />
                                                {matchingClassification.name}
                                            </div>
                                        }
                                        <div className="nag-selectedClassificationPreviewFields">
                                            {
                                                matchingClassification.fields.map(field => (
                                                    <div key={field.staticName} className="nag-selectedClassificationPreviewField">
                                                        <div className="nag-selectedClassificationPreviewFieldLabel">
                                                            {field.displayName}
                                                        </div>
                                                        <div className="nag-selectedClassificationPreviewFieldValue">
                                                            {getFieldValueFromWebProps(webProps, field)}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                }
                                <div className="nag-selectedClassificationText">
                                    To change these classification settings, ask a site owner to navigate to the site <a target="_blank" href={environment.sharePointSiteUrl}>here</a> and update the site information.
                                </div>
                                {/* {
                                _f.pipe(
                                    _f.toPairs,
                                    _f.map(([key, value]) => (
                                        <div className="nag-classificationField">
                                            <div className="nag-classificationFieldLabel">
                                                {key}
                                            </div>
                                            <div className="nag-classificationFieldValue">
                                                {value}
                                            </div>
                                        </div>
                                    ))
                                )(webProps)
                            } */}
                            </div>
                        );
                    }
                    else {
                        return (
                            <div>
                                There was an error loading your classification data. Please try again, and if the issue persists, contact your administrator.
                            </div>
                        )
                    }
                }
                else {
                    return (
                        <div className="nag-selectedClassificationText">
                            Your team has not yet been classified. To view your classification settings, ask a site owner navigate to the site <a target="_blank" href={environment.sharePointSiteUrl}>here</a> and follow the enrolment wizard's instructions.
                        </div>
                    )
                }
            }
        }
        else {
            return (
                <div className="nag-noCollaborateConfigMessage">
                    Your tenant has not been configured for collaboration. Please <a target="_blank" href="https://fivep.com.au/microsoft-teams-navigator/">contact FiveP</a> if you're interested in understanding how to make the most of Navigator!
                </div>
            )
        }

        return null;
    }
}

const mapStateToProps = (state: AppState) => ({ appState: state });
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const teamConnected = connect(mapStateToProps, mapDispatchToProps)(TeamTabComponent);

export { teamConnected as TeamTab };
export default teamConnected;


// import * as React from 'react';
// import * as _ from 'lodash';
// import * as _f from 'lodash/fp';
// import { Dispatch } from 'redux';
// import { AppState } from '../reducers';
// import { TextField, Toggle, Dropdown, CompoundButton, css, FocusZone, Icon, Label } from 'office-ui-fabric-react';
// import { connect } from 'react-redux';
// import { FormField, SiteClassificationProgress, SiteClassificationStatus } from '../types/classifyTeam';
// import { getTermSetThunk as getTermSet } from '../actions/sharePointTermSetActions';
// import { getSharePointSiteProperties } from '../api/getSharePointSiteProperties';
// import { classifyTeam as submitClassifyTeam, excludeTeam, retryClassifyTeam } from '../api/classifyTeam';
// import ProgressBar from './ProgressBar';
// import { SubmitButton } from './SubmitButton';
// import { ErrorMessage } from './ErrorMessage';
// import { getSSOSharePointToken, getSSOGraphToken } from "../api/ssoTokens";
// import { getEnvironment } from "../api/getEnvironment";
// import { getGroupOwners } from "../api/teams";
// import { SharePointClassification, SharePointField } from '../types/sharePointTenantProperties';

// import { IWizardStep, Wizard, IWizardErrorStep, IWizardProgressStep } from './Wizard';

// import {
//     // classifyTeamThunk as classifyTeam,
//     // excludeTeamThunk as excludeTeam,
//     setClassificationFieldValue,
//     setSelectedClassificationTemplate,
//     initialiseClassificationFields
// } from '../actions/classifyTeamActions';

// enum EnrolmentState {
//     Unknown = "Unknown",
//     New = "FiveP_New",
//     Postponed = "FiveP_Postponed",
//     Excluded = "FiveP_Excluded",
//     Enrolled = "FiveP_Enrolled",
//     Enrolling = "FiveP_Enrolling"
// };

// const stringToEnrolmentState = (str: string): EnrolmentState => {
//     let keys = Object.keys(EnrolmentState).filter(x => EnrolmentState[x as keyof typeof EnrolmentState] === str);
//     return keys.length > 0 ? EnrolmentState[keys[0] as keyof typeof EnrolmentState] : EnrolmentState.Unknown;
// }

// const getFieldValueFromWebProps = (props: { [key: string]: string }, field: FormField | SharePointField) => {
//     var val = props[field.staticName];
//     if (field.type === "ManagedMetadata") {
//         const valObj = tryJsonParse(val) as any;
//         if (_f.isString(valObj)) {
//             return valObj;
//         }
//         else if (_f.isArray(valObj)) {
//             return _f.pipe(
//                 _f.map((v: any) => v.Key),
//                 _f.join(', ')
//             )(valObj);
//         }
//         else {
//             return valObj.Key;
//         }
//     }
//     else if (field.type === "Boolean") {
//         return val && val.toLowerCase() === "true";
//     }
//     return val;
// }

// const tryJsonParse = (str: string, defaultVal: any = str) => {
//     try {
//         return str ? JSON.parse(str) : defaultVal;
//     } catch (e) {
//         return defaultVal;
//     }
// }

// const getStatusFromMessage = (statusText: string) => {
//     var status = SiteClassificationStatus.Unknown;
//     if (statusText) {
//         if (statusText.toLowerCase() === "complete") {
//             status = SiteClassificationStatus.Complete;
//         }
//         else if (statusText.toLowerCase().indexOf("error") > -1) {
//             status = SiteClassificationStatus.Error;
//         }
//         else {
//             status = SiteClassificationStatus.InProgress;
//         }
//     }
//     return status;
// }

// const getSiteClassificationStatusFromProps = (props: { [key: string]: string }): SiteClassificationProgress => _f.pipe(
//     _f.get("FiveP_Status"),
//     statusPropStr => {
//         const siteStatus = tryJsonParse(statusPropStr);

//         if (typeof siteStatus === "string") {
//             return {
//                 status: getStatusFromMessage(siteStatus),
//                 description: statusPropStr,
//                 percentage: -1,
//                 retryData: null
//             };
//         }
//         else if (typeof siteStatus === "object") {
//             return {
//                 status: getStatusFromMessage(siteStatus.message),
//                 description: siteStatus.message,
//                 percentage: siteStatus.percentage,
//                 retryData: tryJsonParse(siteStatus.requestData, null)
//             };
//         }
//         else {
//             return null;
//         }
//     }
// )(props)

// export interface ITeamTabProps {
//     appState: AppState;
//     dispatch: Dispatch<any>
// }

// export interface ITeamTabState {
//     fieldSubmitAttempted: boolean;
//     submitted: boolean;
//     initialised: boolean;
//     initialEnrolmentState: EnrolmentState;
//     enrolmentProgressStatus: SiteClassificationProgress;
//     loading: boolean;
//     userIsAdmin: boolean;
//     error: string;
//     wizardStepIndex: number;
//     initialWebProps: { [key: string]: string };
//     isExclusion: boolean;
// }

// class TeamTabComponent extends React.Component<ITeamTabProps, ITeamTabState> {
//     public constructor(props: ITeamTabProps, state: ITeamTabState) {
//         super(props, state);
//         this.state = {
//             initialised: false,
//             initialEnrolmentState: EnrolmentState.Unknown,
//             fieldSubmitAttempted: false,
//             submitted: false,
//             loading: true,
//             userIsAdmin: false,
//             enrolmentProgressStatus: null,
//             error: null,
//             wizardStepIndex: 0,
//             initialWebProps: null,
//             isExclusion: false
//         };
//     }

//     public componentDidMount() {
//         Promise.all([
//             getSharePointSiteProperties(),
//             this.getUserIsGroupOwner()
//         ]).then(responses => {
//             const props = responses[0];
//             const isOwner = responses[1];

//             const existingTemplate = tryJsonParse(props["FiveP_Template"], null);
//             if (existingTemplate && existingTemplate.Key) {
//                 this.onTemplateSelectionChange(existingTemplate.Key);
//                 this.initialiseFields();
//                 this.initialiseFieldValues(existingTemplate.Key, props);
//             }

//             this.setState({
//                 initialised: true,
//                 loading: false,
//                 userIsAdmin: isOwner,
//                 initialEnrolmentState: stringToEnrolmentState(props["FiveP_EnrolmentState"]),
//                 enrolmentProgressStatus: getSiteClassificationStatusFromProps(props),
//                 initialWebProps: props
//             });
//         });
//     }

//     public render() {
//         const { initialised, initialEnrolmentState, error } = this.state;
//         const { appState } = this.props;
//         const { hasCollaborateConfiguration } = appState.tenantProperties;

//         const isNew = initialEnrolmentState === EnrolmentState.Unknown || initialEnrolmentState === EnrolmentState.New || initialEnrolmentState === EnrolmentState.Postponed;

//         return initialised ?
//             hasCollaborateConfiguration ?
//                 !error ?
//                     isNew ?
//                         this.renderEnrolmentWizard()
//                         :
//                         this.renderEditWizard()
//                     :
//                     <ErrorMessage text={error} />
//                 :
//                 <div className="nag-noCollaborateConfigMessage">
//                     Your tenant has not been configured for collaboration. Please <a target="_blank" href="https://fivep.com.au/microsoft-teams-navigator/">contact FiveP</a> if you're interested in understanding how to make the most of Navigator!
//                 </div>
//             :
//             null;
//     }

//     private renderEnrolmentWizard() {
//         const { userIsAdmin } = this.state;
//         const { appState } = this.props;
//         const { classifyTeam, tenantProperties } = appState;
//         const { fields: rawFields } = classifyTeam;
//         const fields = _.values(rawFields).filter(f => !f.hiddenInWizard);

//         const selectedTemplate = this.getTemplate();

//         const steps: Array<IWizardStep> = userIsAdmin ?
//             [
//                 {
//                     title: "Welcome",
//                     type: "Splash",
//                     content: (
//                         <div className="nag-enrolmentSplash">
//                             <div className="nag-enrolmentSplashTitle">
//                                 Welcome to Navigator
//                             </div>
//                             <div className="nag-enrolmentSplashDescription">
//                                 This wizard will step you through the process of classifying your team and its content. Once your team has been classified, you'll find that Navigator will be able assist you and your teammates to discover valuable content and minimise your time spent searching.
//                             </div>
//                         </div>
//                     ),
//                     prevText: "Exclude this team from Navigator"
//                 },
//                 {
//                     title: "Classify your team",
//                     description: "Please choose what this team will be used for. This will apply a template to the team.",
//                     content: (
//                         <FocusZone>
//                             {
//                                 tenantProperties.classifications.filter(c => !c.hidden).map(classification =>
//                                     <CompoundButton key={classification.key} className={css("nag-templateSelectionButton", classification.description && "hasDescription", classification.key === classifyTeam.selectedTemplate && "active")} text={classification.name} secondaryText={classification.description} iconProps={{ iconName: classification.icon }} onClick={() => { this.onTemplateSelectionChange(classification.key) }} data-is-focusable={true} />
//                                 )
//                             }
//                         </FocusZone>
//                     ),
//                     onLoad: this.initialiseSelectedTemplate.bind(this),
//                     onBeforeNext: this.validateTemplateSelection.bind(this)
//                 },
//                 {
//                     title: "Classification details",
//                     description: "Please confirm some of the details about your team.",
//                     content: (
//                         <div>
//                             {
//                                 fields.map(this.renderField.bind(this))
//                             }
//                         </div>
//                     ),
//                     onLoad: this.initialiseFields.bind(this),
//                     onBeforeNext: this.validateFields.bind(this, fields)
//                 },
//                 {
//                     title: "Confirm selection",
//                     description: "Please ensure you're happy with your selection. When you're ready, click on 'Get started' to classify your team!",
//                     content: this.renderClassificationDetails(selectedTemplate, fields),
//                     nextText: "Get started",
//                     onBeforeNext: this.submitClassifyTeam.bind(this)
//                 }
//             ]
//             :
//             [
//                 {
//                     title: "Welcome",
//                     type: "Splash",
//                     content: (
//                         <div className="nag-enrolmentSplash">
//                             <div className="nag-enrolmentSplashTitle">
//                                 Welcome to Navigator
//                             </div>
//                             <div className="nag-enrolmentSplashDescription">
//                                 Once your team has been classified, you'll find that Navigator will be able assist you and your teammates to discover valuable content and minimise your time spent searching. Unfortunately this team has not yet been classified, and you are not an owner of this team so cannot do this yourself. Ask a team owner to help you make the most of Navigator!
//                             </div>
//                         </div>
//                     ),
//                     prevText: "Exclude this team from Navigator",
//                     hideControls: true
//                 }
//             ];

//         return this.renderWizard(steps, {
//             onCancel: this.excludeTeam.bind(this),
//             cancelConfirmationTitle: "Confirm exclusion",
//             cancelConfirmationMessage: "Once you have excluded this site, you will no longer be asked to enrol your site. This tab will also be removed, however if you change your mind later you can re-add this tab to enrol your site. Are you sure you would like to proceed?"
//         });
//     }

//     private renderEditWizard() {
//         const { appState } = this.props;
//         const { userIsAdmin } = this.state;
//         const { classifyTeam } = appState;
//         const { fields: rawFields } = classifyTeam;
//         const fields = _.values(rawFields);
//         const currTemplate = this.getTemplate();

//         const steps: Array<IWizardStep> = [
//             {
//                 title: "Team classification",
//                 description: "Documents uploaded into this team will automatically be tagged with this data to make them easier for you to find later.",
//                 content: this.renderClassificationDetails(currTemplate, fields),
//                 // onLoad doesn't work for the first step - hack fix by just initialising fields on mount
//                 //onLoad: this.initialiseFields.bind(this),
//                 nextText: "Edit",
//                 hideNav: true,
//                 hideControls: !userIsAdmin
//             },
//             {
//                 title: "Update classification",
//                 description: "Update your team's details here. Existing documents will not have their classifications changed, but new content will reflect your new data.",
//                 content: (
//                     <div>
//                         {
//                             fields.map(this.renderField.bind(this))
//                         }
//                     </div>
//                 ),
//                 onBeforeNext: () => {
//                     return this.validateFields(fields) ? this.submitClassifyTeam(false) : false;
//                 },
//                 onBeforePrev: this.resetFieldValues.bind(this),
//                 nextText: "Save",
//                 prevText: "Cancel",
//                 hideNav: true
//             }
//         ];

//         return this.renderWizard(steps);
//     }

//     private renderWizard(steps: IWizardStep[], wizardProps?: any) {
//         const { submitted, loading, enrolmentProgressStatus, wizardStepIndex, isExclusion } = this.state;

//         const isError = enrolmentProgressStatus && enrolmentProgressStatus.status === SiteClassificationStatus.Error;
//         const errorStep: IWizardErrorStep = isError ? {
//             title: "An unexpected error occurred",
//             description: "Oops! Sorry about the inconvenience, but something went wrong. Please review the details below and let's see if we can get back on track.",
//             content: <ErrorMessage text={enrolmentProgressStatus.description} />,
//             nextText: "Retry",
//             onNext: this.retryClassifyTeam.bind(this),
//             prevText: "Start over",
//             onPrev: this.resetEnrolmentProgressError.bind(this)
//         } : null;

//         const progressStep: IWizardProgressStep = {
//             title: !isExclusion ? "Classification in progress" : "Exclusion in progress",
//             description: !isExclusion ?
//                 "Your team is being classified. Feel free to leave this page if you don't want to hang around, and the classification will be completed in the background!"
//                 :
//                 "We're just cleaning up a few things and excluding this team. Feel free to leave this page if you don't want to hang around, and the exclusion will be completed in the background.",
//             content: this.renderEnrolmentProgress()
//         }

//         return (
//             <Wizard steps={steps} onSubmit={this.onWizardSubmit.bind(this)} disableControls={loading} isError={isError} errorStep={errorStep} currStepIndex={wizardStepIndex} onStepChange={(index) => { this.setState({ wizardStepIndex: index }) }} isSubmitting={submitted} progressStep={progressStep} {...wizardProps} />
//         );
//     }

//     private renderClassificationDetails(template: SharePointClassification, fields: FormField[]) {
//         return (
//             <div className="nag-classificationDetails">
//                 {
//                     template &&
//                     <div className="nag-classificationDetailsTitle">
//                         {template.name}
//                     </div>
//                 }
//                 <div className="nag-classificationDetailsFields">
//                     {
//                         fields.map(this.renderFieldPreview.bind(this))
//                     }
//                 </div>
//             </div>
//         );
//     }

//     private getTemplate(templateKey?: string) {
//         const { appState } = this.props;
//         const { classifyTeam, tenantProperties } = appState;

//         return _.first(tenantProperties.classifications.filter(c => c.key === (templateKey || classifyTeam.selectedTemplate)));
//     }

//     private initialiseSelectedTemplate() {
//         const { appState } = this.props;
//         const { selectedTemplate } = appState.classifyTeam;
//         const { classifications } = appState.tenantProperties;

//         if (!selectedTemplate) {
//             const defaultTemplate = _.first(classifications.filter(c => !c.hidden));
//             if (defaultTemplate) {
//                 this.onTemplateSelectionChange(defaultTemplate.key);
//             }
//         }
//     }

//     private initialiseFields() {
//         const { appState, dispatch } = this.props;
//         const { classifyTeam, tenantProperties } = appState;

//         const selectedTemplate = tenantProperties.classifications.filter(c => c.key === classifyTeam.selectedTemplate)[0];
//         const templateFields = selectedTemplate.fields;

//         templateFields.forEach(field => {
//             if (field.type === "ManagedMetadata") {
//                 if (!classifyTeam.termSets[field.termSetId]) {
//                     dispatch(getTermSet(field.termSetId));
//                 }
//             }
//         });

//         dispatch(initialiseClassificationFields(templateFields));

//         this.setState({
//             fieldSubmitAttempted: false
//         });
//     }

//     private initialiseFieldValues(templateKey: string, webProps: { [key: string]: string }) {
//         const selectedTemplate = this.getTemplate(templateKey);
//         if (selectedTemplate) {
//             selectedTemplate.fields.forEach(field => {
//                 this.onFieldChange(field.staticName, getFieldValueFromWebProps(webProps, field));
//             });
//         }
//     }

//     private renderField(field: FormField) {
//         const { appState } = this.props;
//         const { classifyTeam } = appState;
//         const { fieldSubmitAttempted } = this.state;

//         const val = _.get(classifyTeam, `fields.${field.staticName}.value`);
//         const commonProps = {
//             className: css("nag-templateMetadataField", val && "has-value", !field.isValid && (field.isDirty || fieldSubmitAttempted) && "invalid"),
//             key: field.staticName,
//             label: field.displayName
//         }

//         switch (field.type) {
//             case "Boolean":
//                 return <Toggle {...commonProps} onChange={(e, checked: boolean) => { this.onFieldChange(field.staticName, checked); }} checked={val} onText="Yes" offText="No" />
//             case "ManagedMetadata":
//                 const termSet = classifyTeam.termSets[field.termSetId];
//                 const options = termSet ? termSet.map(term => ({
//                     key: term.id,
//                     text: term.name
//                 })) : [];
//                 const selectedTerm = termSet ? _.first(termSet.filter(term => term.id === val)) : null;
//                 return <Dropdown {...commonProps} options={options} onChange={(e, item) => { this.onFieldChange(field.staticName, item.key as string); }} selectedKey={val} required={field.required} />
//             case "Text":
//             default:
//                 return <TextField {...commonProps} onChange={(e, val: string) => { this.onFieldChange(field.staticName, val); }} value={val} required={field.required} />
//         }
//     }

//     private renderFieldPreview(field: FormField) {
//         const { appState } = this.props;
//         const { classifyTeam } = appState;

//         const val = _.get(classifyTeam, `fields.${field.staticName}.value`);

//         var valueStr = null;

//         switch (field.type) {
//             case "Boolean":
//                 valueStr = val ? "Yes" : "No";
//                 break;
//             case "ManagedMetadata":
//                 const termSet = classifyTeam.termSets[field.termSetId];
//                 const matchingTerm = termSet && val ? _.first(termSet.filter(t => t.id === val)) : null;
//                 valueStr = matchingTerm ? matchingTerm.name : "[None]";
//                 break;
//             case "Text":
//             default:
//                 valueStr = val || "[None]";
//         }

//         return (
//             <div className="nag-readOnlyField" key={field.staticName}>
//                 <div className="nag-readOnlyFieldLabel">
//                     {field.displayName}
//                 </div>
//                 <div className="nag-readOnlyFieldValue">
//                     {valueStr}
//                 </div>
//             </div>
//         );
//     }

//     private renderEnrolmentProgress() {
//         const { enrolmentProgressStatus, isExclusion } = this.state;

//         return (
//             <div className="nag-enrolmentProgressContainer">
//                 {
//                     enrolmentProgressStatus &&
//                     <ProgressBar text={enrolmentProgressStatus.description} percentage={enrolmentProgressStatus.percentage} />
//                 }
//                 {
//                     enrolmentProgressStatus && enrolmentProgressStatus.status === SiteClassificationStatus.Complete &&
//                     (
//                         !isExclusion ?
//                             <div className="nag-enrolmentProgressCompleteMessage">Your team's classification is complete! You can now upload content into your team, and users will be able to easily discover it in Navigator using the details you provided.</div>
//                             :
//                             <div className="nag-enrolmentProgressCompleteMessage">Your team has been excluded from Navigator. If you change your mind and would like to have Navigator classify your team and its content, re-add the Navigator tab to your team.</div>
//                     )
//                 }
//             </div>
//         )
//     }

//     private onTemplateSelectionChange(template: string) {
//         const { dispatch } = this.props;
//         dispatch(setSelectedClassificationTemplate(template));
//     }

//     private onFieldChange(fieldName: string, fieldValue: string | boolean) {
//         const { dispatch } = this.props;
//         dispatch(setClassificationFieldValue(fieldName, fieldValue));
//     }

//     private validateTemplateSelection(): boolean {
//         const { classifyTeam } = this.props.appState;
//         return !!classifyTeam.selectedTemplate;
//     }

//     private validateFields(fields: FormField[]): boolean {
//         this.setState({
//             fieldSubmitAttempted: true
//         });

//         return fields.filter(f => !f.isValid).length === 0;
//     }

//     private submitClassifyTeam(isNew: boolean = true) {
//         const { appState } = this.props;
//         const { classifyTeam } = appState;

//         this.setState({
//             submitted: false,
//             loading: true,
//             error: null
//         });

//         // back end expects to not have template details sent - just the field props
//         return submitClassifyTeam(appState, classifyTeam.fields, isNew ? classifyTeam.selectedTemplate : null)
//             .then(() => {
//                 this.setState({
//                     submitted: true,
//                     loading: false,
//                     enrolmentProgressStatus: {
//                         ...this.state.enrolmentProgressStatus,
//                         status: SiteClassificationStatus.Unknown,
//                         description: "Waiting",
//                         percentage: 0
//                     }
//                 });

//                 this.pollForEnrolmentStatus();
//                 return true;
//             })
//             .catch(err => {
//                 this.setState({
//                     loading: false,
//                     error: err.message
//                 });
//                 return false;
//             });
//     }

//     private resetEnrolmentProgressError() {
//         const { enrolmentProgressStatus } = this.state;
//         // don't want to reset the selected template if it's editing an existing one and they reset
//         // honestly doesn't matter anyway
//         //this.initialiseSelectedTemplate();
//         this.setState({
//             ...this.state,
//             submitted: false,
//             wizardStepIndex: 0,
//             enrolmentProgressStatus: {
//                 ...enrolmentProgressStatus,
//                 status: SiteClassificationStatus.Unknown,
//                 description: ""
//             }
//         })
//     }

//     private retryClassifyTeam() {
//         const { appState } = this.props;
//         const { enrolmentProgressStatus } = this.state;

//         this.setState({
//             submitted: false,
//             loading: true,
//             error: null
//         });

//         return retryClassifyTeam(appState, enrolmentProgressStatus.retryData)
//             .then(() => {
//                 this.setState({
//                     submitted: true,
//                     loading: false,
//                     enrolmentProgressStatus: {
//                         ...this.state.enrolmentProgressStatus,
//                         status: SiteClassificationStatus.Unknown,
//                         description: "Waiting",
//                         percentage: 0,
//                     }
//                 });

//                 this.pollForEnrolmentStatus();
//                 return true;
//             })
//             .catch(err => {
//                 this.setState({
//                     loading: false,
//                     error: err.message
//                 });
//                 return false;
//             })
//     }

//     private excludeTeam() {
//         this.setState({
//             submitted: false,
//             loading: true,
//             error: null,
//             isExclusion: true
//         });

//         return excludeTeam(this.props.appState)
//             .then(() => {
//                 this.setState({
//                     submitted: true,
//                     loading: false,
//                     enrolmentProgressStatus: {
//                         ...this.state.enrolmentProgressStatus,
//                         status: SiteClassificationStatus.Unknown,
//                         description: "Waiting",
//                         percentage: 0,
//                     }
//                 });

//                 this.pollForEnrolmentStatus();
//                 return true;
//             })
//             .catch(err => {
//                 this.setState({
//                     loading: false,
//                     error: err.message
//                 });
//                 return false;
//             })
//     }

//     private async pollForEnrolmentStatus() {
//         const siteUrl = (await getEnvironment()).sharePointSiteUrl;
//         const token = await getSSOSharePointToken();

//         getSharePointSiteProperties(siteUrl, token).then(props => {
//             const classificationStatus = getSiteClassificationStatusFromProps(props);
//             this.setState({
//                 enrolmentProgressStatus: classificationStatus
//             });

//             if (classificationStatus.status !== SiteClassificationStatus.Complete && classificationStatus.status !== SiteClassificationStatus.Error) {
//                 setTimeout(() => {
//                     this.pollForEnrolmentStatus();
//                 }, 3000);
//             }
//         });
//     }

//     private async getUserIsGroupOwner() {
//         const graphToken = await getSSOGraphToken();
//         const env = await getEnvironment();
//         const groupId = env.groupId;

//         return getGroupOwners(graphToken, groupId).then(owners => owners.map(o => o.userPrincipalName).indexOf(env.loginHint) > -1);
//     }

//     private onWizardSubmit() {
//         //console.log('SUBMITTED');
//     }

//     private resetFieldValues() {
//         const { initialWebProps } = this.state;
//         const { selectedTemplate } = this.props.appState.classifyTeam;

//         this.initialiseFieldValues(selectedTemplate, initialWebProps);
//         return true;
//     }
// }

// const mapStateToProps = (state: AppState) => ({ appState: state });
// const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

// const teamConnected = connect(mapStateToProps, mapDispatchToProps)(TeamTabComponent);

// export { teamConnected as TeamTab };
// export default teamConnected;