import {
    IDiscoverTeamsState, DiscoverTeamsActions, UPDATE_DISCOVER_TEAMS_QUERY,
    UPDATE_DISCOVER_TEAMS_CLASSIFICATION, UPDATE_DISCOVER_TEAMS_METADATAFILTER, UPDATE_SUGGESTED_TEAMS
} from "../types/discoverTeams";
import { TeamsActionTypes, BEGIN_RETRIEVE_TEAMS, RETRIEVE_TEAMS_FAILED, RETRIEVE_TEAMS_SUCCESS } from "../types/teams";
import { ClearStateAction, CLEAR_STATE } from "../types/clearState";

export const initialState: IDiscoverTeamsState = {
    loadingState: "None",
    lastLoaded: null,
    lastBackgroundLoaded: null,
    searchTerm: "",
    classification: null,
    metadata: {},
    suggestedTeamIds: [],
    suggestedTeamStatus: "None",
    suggestedTeamLastUpdated: null
}

type discoverTeamsReducerActions = DiscoverTeamsActions | TeamsActionTypes | ClearStateAction

export const discoverTeamsReducer = (state: IDiscoverTeamsState = initialState, action: discoverTeamsReducerActions): IDiscoverTeamsState => {

    switch (action.type) {
        case CLEAR_STATE:
            return initialState;
        case BEGIN_RETRIEVE_TEAMS:
            return {
                ...state,
                loadingState: "Loading"
            }
        case RETRIEVE_TEAMS_FAILED:
            return {
                ...state,
                loadingState: "Error"
            }
        case RETRIEVE_TEAMS_SUCCESS:
            return {
                ...state,
                loadingState: "Loaded"
            }
        case UPDATE_DISCOVER_TEAMS_QUERY:
            return {
                ...state,
                searchTerm: action.query
            }
        case UPDATE_DISCOVER_TEAMS_CLASSIFICATION:
            return {
                ...state,
                classification: action.query
            };
        case UPDATE_DISCOVER_TEAMS_METADATAFILTER:
            return {
                ...state,
                metadata: {
                    ...state.metadata,
                    [action.key]: action.value
                }
            };
        case UPDATE_SUGGESTED_TEAMS:
            return {
                ...state,
                suggestedTeamIds: action.suggestedTeamIds,
                suggestedTeamLastUpdated: action.suggestedTeamLastUpdated,
                suggestedTeamStatus: action.suggestedTeamStatus
            }
    }

    return state;
}

export default discoverTeamsReducer;