import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { PlaceholderFilesActions, BeginRecentFilesGetAction, BEGIN_RECENT_FILES_GET, RecentFilesGetSuccessAction, RECENT_FILES_GET_SUCCESS, RecentFilesGetFailedAction, RECENT_FILES_GET_FAILED, BEGIN_TRENDING_FILES_GET, TRENDING_FILES_GET_FAILED, TRENDING_FILES_GET_SUCCESS, TrendingFilesGetFailedAction, BeginTrendingFilesGetAction, TrendingFilesGetSuccessAction } from "../types/recentFiles";
import { AppState } from "../reducers";
import { AnyAction } from "redux";
import { getRecentFiles, ISuggestedFileState, getTrendingFiles } from "../api/getSuggestedFiles";
import { SearchResult, Dictionary } from "../types";
import { extractMetaSearchMappings } from "../api/extractMetaDictionary";
//import { getSharePointToken } from "../api/authenticate";
import { getSSOGraphToken, getSSOSharePointToken } from "../api/ssoTokens";

const beginGetRecentFiles = (): BeginRecentFilesGetAction => {
    return {
        type: BEGIN_RECENT_FILES_GET
    }
}

const getRecentFilesSuccess = (files: SearchResult[]): RecentFilesGetSuccessAction => {
    return {
        type: RECENT_FILES_GET_SUCCESS,
        files
    }
}

const getRecentFilesFailed = (): RecentFilesGetFailedAction => {
    return {
        type: RECENT_FILES_GET_FAILED
    }
}

export const getRecentFilesThunk = (): ThunkAction<Promise<void>, AppState, {}, PlaceholderFilesActions> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> => {

        return new Promise<void>(async resolve => {

            const { environment } = await getState();
            const { sharePointRoot } = environment;

            const graphToken = await getSSOGraphToken();
            const sharePointToken = await getSSOSharePointToken();

            const meta: Dictionary<string> = extractMetaSearchMappings(getState());

            dispatch(beginGetRecentFiles());

            try {
                const files = await getRecentFiles(graphToken, sharePointToken, sharePointRoot, meta);
                dispatch(getRecentFilesSuccess(files));
            }
            catch (err) {
                dispatch(getRecentFilesFailed());
            }
            resolve(null);
        });
    }

}

const beginGetTrendingFiles = (): BeginTrendingFilesGetAction => {
    return {
        type: BEGIN_TRENDING_FILES_GET
    }
}

const getTrendingFilesSuccess = (files: SearchResult[]): TrendingFilesGetSuccessAction => {
    return {
        type: TRENDING_FILES_GET_SUCCESS,
        files
    }
}

const getTrendingFilesFailed = (): TrendingFilesGetFailedAction => {
    return {
        type: TRENDING_FILES_GET_FAILED
    }
}

export const getTrendingFilesThunk = (): ThunkAction<Promise<void>, AppState, {}, PlaceholderFilesActions> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> => {

        return new Promise<void>(async resolve => {

            const { environment } = getState();
            const { tenantId, sharePointRoot } = environment;

            dispatch(beginGetTrendingFiles());

            try {
                const graphToken = await getSSOGraphToken();
                const sharePointToken = await getSSOSharePointToken();

                const meta: Dictionary<string> = extractMetaSearchMappings(getState());

                const files = await getTrendingFiles(graphToken, sharePointToken, sharePointRoot, meta);

                dispatch(getTrendingFilesSuccess(files));
            }
            catch (err) {
                dispatch(getTrendingFilesFailed());
            }
            resolve(null);
        });
    }
}