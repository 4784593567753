import { createStore, applyMiddleware, compose, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer, { AppState } from './reducers';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from './middleware/logger';

import { debounce } from 'lodash';

import { saveState } from './api/localStorage';

export default function configureStore(hostClientType: string, tenantId: string, userId: string, preLoadedState: AppState): Store<AppState> {

    const middleWares = [
        thunkMiddleware,
        loggerMiddleware
    ];

    const composed = composeWithDevTools(
        applyMiddleware(...middleWares)
    );

    const store: Store<AppState> = createStore(rootReducer, preLoadedState, composed);

    store.subscribe(debounce(() => saveState(hostClientType, tenantId, userId)(store.getState()), 1000));

    return store;
}