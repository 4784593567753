import * as React from 'react';
import { AppState } from '../reducers';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TextField, Stack } from 'office-ui-fabric-react';
import { PrimaryButton } from 'msteams-ui-components-react';
import createConfig from '../config';
import { activateLicense } from '../api/licenseCheck';
import { setLicenseStatus } from '../actions/licenseActions';
import { getSSOApplicationToken } from '../api/ssoTokens';
import { LoadingGeneralComponent } from './LoadingGeneral';

interface ILicensePageProps {
    appState: AppState;
    dispatch: Dispatch<any>;
}

interface ILicensePageState {

}

const LicenseStatusError: React.FunctionComponent<any> = () => {
    return <div>An error occured validating your product license. Please try again.</div>
}

const LicenseStatusNone: React.FunctionComponent<any> = () => {

    return <div>A license is required to use this product.
        Please visit <a target="_blank" href="https://fivep.com.au/microsoft-teams-navigator/">https://fivep.com.au/microsoft-teams-navigator/</a>
        to purchase a license
    </div>
}

interface ILicenseStatusInactiveProps {
    appState: AppState;
    dispatch: Dispatch<any>;
}

interface ILicenseStatusInactiveState {
    keyText: string;
    activationStatus: string;
}

class LicenseStatusInactive extends React.Component<ILicenseStatusInactiveProps, ILicenseStatusInactiveState> {

    constructor(props: ILicenseStatusInactiveProps) {
        super(props);

        this.state = {
            keyText: "",
            activationStatus: ""
        }
    }

    private activate = async () => {

        const { keyText } = this.state;

        console.log("Activating", this.state.keyText);
        this.setState({ activationStatus: "Activating..." });

        const { appState, dispatch } = this.props;
        const { environment } = appState;
        const { tenantId } = environment;

        const config = createConfig();
        const appToken = await getSSOApplicationToken();

        const activationResult = await activateLicense(tenantId, appToken, config.licenseCheckBaseUrl, keyText);

        if (activationResult.licenseStatus == "Valid") {
            this.setState({ activationStatus: "License activated successfully, please wait..." });

            setTimeout(() => {
                dispatch(setLicenseStatus(activationResult.licenseStatus))
            }, 1500);
        }
        else {
            this.setState({ activationStatus: activationResult.message });
        }
    }

    private updateKeyBox = (event: any, value: string) => {
        this.setState({
            keyText: value
        });
    }

    public render() {

        const enableButton = this.state.activationStatus != "Activating..." && this.state.keyText;

        return <div>
            <h2>Getting started</h2>
            <p>In order to use Teams Navigator you will need to get in touch with us and purchase a license.</p>
            <p>Please <a target="_blank" href="https://fivep.com.au/microsoft-teams-navigator/">click here</a> to get started.</p>
            <p>Once you have been provided with a license, please enter it below to activate.</p>
            <Stack horizontal >
                <TextField onChange={this.updateKeyBox} value={this.state.keyText} autoComplete={"off"} style={{ width: "400px" }} /> &nbsp;
                <PrimaryButton disabled={!enableButton} onClick={this.activate}>Activate</PrimaryButton>

            </Stack>
            <p>{this.state.activationStatus}</p>
        </div>
    }
}

const LicenseStatusUnknown: React.FunctionComponent<any> = () => {
    return <LoadingGeneralComponent message="Checking license..." />
}

class LicensePage extends React.Component<ILicensePageProps, ILicensePageState> {

    public render() {
        const { license } = this.props.appState;
        const { status } = license;

        switch (status) {
            case "Error":
                return <LicenseStatusError />
            case "NoLicense":
            //return <LicenseStatusNone />
            case "Inactive":
                return <LicenseStatusInactive
                    appState={this.props.appState}
                    dispatch={this.props.dispatch} />
            case "Unknown":
            default:
                return <LicenseStatusUnknown />
        }
    }
}

const mapStateToProps = (appState: AppState) => ({ appState });
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const connected = connect(mapStateToProps, mapDispatchToProps)(LicensePage);

export { connected as LicensePage }