import * as React from 'react';
import { IIconProps } from 'office-ui-fabric-react/lib/components/Icon';
import { IContextualMenuProps, IContextualMenuItem, IconButton } from 'office-ui-fabric-react';
import { TeamState } from '../types/teams';
import { joinTeam } from '../api/joinTeam';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { JoinTeamsActions } from '../types/joinTeam';
import { AppState } from '../reducers';
import { connect } from 'react-redux';
import { EnvironmentPermissions } from '../types/environment';

export interface ITeamsContextMenuProps {
    appPermissions: EnvironmentPermissions;
    team: TeamState,
    join: (id: string) => ThunkAction<Promise<void>, AppState, {}, JoinTeamsActions>,
    navigate: (id: string) => void,
    navigateToFiles: (id: string) => void
}

const TeamsContextMenuComponent: React.FunctionComponent<ITeamsContextMenuProps> = (props) => {
    const iconProp: IIconProps = {
        iconName: "More"
    }

    const menuIconProps: IIconProps = {

    }

    const { team, join, navigate, navigateToFiles, appPermissions } = props;
    const { id, isMember, visibility } = team;

    const menuItems: IContextualMenuItem[] = isMember ?
        [
            {
                key: "navigate",
                name: "Navigate to Team",
                iconProps: {
                    iconName: "TeamsLogoInverse"
                },
                onClick: () => navigate(id),
            },
            {
                key: "navigateToFiles",
                name: "Navigate to Team Files",
                iconProps: {
                    iconName: "TeamsLogoInverse"
                },
                onClick: () => navigateToFiles(id),
            }
        ]
        :
        visibility == "Public" && appPermissions.groupReadWriteAll ?
            [
                {
                    key: "join",
                    name: "Join Team",
                    iconProps: {
                        iconName: "TeamsLogo"
                    },
                    onClick: () => join(id),
                }
            ]
            :
            [
                {
                    key: "none",
                    name: "No actions available",
                    iconProps: {
                        iconName: "TeamsLogo"
                    },
                    onClick: () => { },
                }
            ]

    const menuProps: IContextualMenuProps = {
        items: menuItems
    }
    return <IconButton iconProps={iconProp} menuIconProps={menuIconProps} menuProps={menuProps} />
};

const mapStateToProps = (state: AppState) => ({ appPermissions: state.environment.permissions });

const connected = connect(mapStateToProps, null)(TeamsContextMenuComponent);

export { connected as TeamsContextMenu };
export default connected;