import { UpdateDiscoverTeamsQueryAction, UPDATE_DISCOVER_TEAMS_QUERY, UpdateDiscoverTeamsClassificationAction, UPDATE_DISCOVER_TEAMS_CLASSIFICATION, UpdateDiscoverTeamsMetadataFilterAction, UPDATE_DISCOVER_TEAMS_METADATAFILTER } from "../types/discoverTeams";

export const updateSearchTerm = (query: string): UpdateDiscoverTeamsQueryAction => ({
    type: UPDATE_DISCOVER_TEAMS_QUERY,
    query
});

export const updateSearchClassification = (query: string): UpdateDiscoverTeamsClassificationAction => ({
    type: UPDATE_DISCOVER_TEAMS_CLASSIFICATION,
    query
});

export const updateSearchMetadataFilter = (key: string, value: string): UpdateDiscoverTeamsMetadataFilterAction => ({
    type: UPDATE_DISCOVER_TEAMS_METADATAFILTER,
    key,
    value
});