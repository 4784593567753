import { SetUserPreferenceContentColumnsAction, SET_USER_PREFERENCE_CONTENT_COLUMNS, SetUserPreferenceTeamsColumnsAction, SET_USER_PREFERENCE_TEAMS_COLUMNS } from "../types/userPreferences";

export const updateVisibleContentColumns = (state: string[]): SetUserPreferenceContentColumnsAction => {
    return {
        type: SET_USER_PREFERENCE_CONTENT_COLUMNS,
        state
    }
}

export const updateVisibleTeamsColumns = (classification: string, state: string[]): SetUserPreferenceTeamsColumnsAction => {
    return {
        type: SET_USER_PREFERENCE_TEAMS_COLUMNS,
        classification,
        state
    }
}