import * as React from 'react';
import { TeamState, ITeamImage } from '../types/teams';
import TeamTile from './TeamTile';
import { SharePointTenantProperties } from '../types/sharePointTenantProperties';
import { MessageBar } from './MessageBar';
import { Dictionary } from '../types';

export interface ITeamsPlaceholderSectionProps {
    teams: TeamState[],
    images: Dictionary<ITeamImage>,
    heading: string,
    tenantProperties: SharePointTenantProperties,
    join: (teamId: string) => void,
    navigate: (teamId: string) => void
}

export const TeamsPlaceholderSection: React.FunctionComponent<ITeamsPlaceholderSectionProps> = (props) => {

    const { teams, tenantProperties, join, navigate, images } = props

    //    const Teams = () => 

    if (teams && teams.length) {
        return <div className="nag-teamsSuggestions"><div className="nag-gridRow">{teams.map((t: TeamState, i) => <TeamTile images={images} key={t.id} tenantProperties={tenantProperties} team={t} join={join} navigate={navigate} />)}</div></div>;
    }
    else {
        return <MessageBar message="There are no suggested teams currently available to view" />
    }

}
