import * as React from 'react';

import { TeamState, ITeamImage } from "../types/teams";
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { SharePointTenantProperties } from '../types/sharePointTenantProperties';
import { TeamLogo } from './TeamLogo';
import { Dictionary } from '../types';
import { AppState } from '../reducers';
import { connect } from 'react-redux';
import { EnvironmentPermissions } from '../types/environment';

interface ITeamTileProps {
    appPermissions: EnvironmentPermissions;
    join: (teamId: string) => void;
    navigate: (teamId: string) => void;
    team: TeamState;
    tenantProperties: SharePointTenantProperties;
    images: Dictionary<ITeamImage>;
}

interface ITeamTileState { }

const getManagedMetadataPropertiesForClassification = (tenantProperties: SharePointTenantProperties, classification: string): string[] => {

    const template = tenantProperties.classifications.find(c => c.name == classification);
    if (template) {
        return template.fields.filter(f => f.type == "ManagedMetadata" || f.type == "Text").map(f => f.displayName);
    }
    return [];
}

const extractMetadataAsString = (team: TeamState, tenantProperties: SharePointTenantProperties): string => {

    if (team.metaData) {
        const classification = team.metaData["classification"];
        if (classification) {
            const metaProps = getManagedMetadataPropertiesForClassification(tenantProperties, classification);
            const values = [classification, ...metaProps.map(m => team.metaData[m]).filter(m => m)];
            return values.join(", ");
        }
    }
    return "";
}

class TeamTileComponent extends React.Component<ITeamTileProps, ITeamTileState> {
    public componentDidMount() {

    }

    public render() {
        const t = this.props.team;
        const { join, navigate, tenantProperties, images, appPermissions } = this.props;

        if (t.joinStatus == "Joining") {

            return <div className="nag-threeTile">
                <div className="nag-teamTile">
                    <div className="nag-tileContent">
                        <div className="nag-tileContentMain">
                            <div className="nag-tileLogo"><TeamLogo team={t} /></div>
                            <div className="nag-teamHeading">
                                <div>{t.name}</div>
                            </div>
                        </div>
                        <div className="nag-tileLine"></div>
                        <div className="nag-buttonBar">
                            <DefaultButton className="nag-button nag-disabled" disabled onClick={() => { }}>Joining...</DefaultButton>
                        </div>
                    </div>
                </div>
            </div>
        }
        else if (t.isMember) {
            return <div className="nag-threeTile">
                <div className="nag-teamTile">
                    <div className="nag-tileContent">
                        <div className="nag-tileContentMain">
                            <div className="nag-tileLogo"><TeamLogo team={t} /></div>
                            <div className="nag-teamHeading">
                                <div>{t.name}</div>
                            </div>
                        </div>
                        <div className="nag-tileLine"></div>
                        <div className="nag-buttonBar">
                            <DefaultButton className="nag-button" onClick={() => navigate(t.id)}>Navigate</DefaultButton>
                        </div>
                    </div>
                </div>
            </div>
        }
        else {
            return <div className="nag-threeTile">
                <div className="nag-teamTile">
                    <div className="nag-tileContent">
                        <div className="nag-tileContentMain">
                            <div className="nag-tileLogo"><TeamLogo team={t} /></div>
                            <div className="nag-teamHeading">
                                <div>{t.name}</div>
                            </div>
                        </div>
                        <div className="nag-tileLine"></div>
                        <div className="nag-tileData">{extractMetadataAsString(t, tenantProperties)}</div>
                    </div>
                    {
                        appPermissions.groupReadWriteAll &&
                        <div className="nag-tileHover">
                            <div className="nag-buttonBar">
                                <DefaultButton className="nag-button" onClick={() => join(t.id)}>Join</DefaultButton>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
    }
}

const mapStateToProps = (state: AppState) => ({ appPermissions: state.environment.permissions });

const connected = connect(mapStateToProps, null)(TeamTileComponent);

export { connected as TeamTile };
export default connected;

// const TeamTileF: React.FunctionComponent<ITeamTileProps> = (props)  => {

//     const t = props.team;
//     const { join, navigate, tenantProperties } = props;

//     if(t.joinStatus == "Joining"){

//         return <div className="nag-threeTile">
//                     <div className="nag-teamTile">
//                         <div className="nag-tileContent">
//                             <div className="nag-tileLogo"><TeamLogo team={t} /></div>
//                             <div className="nag-teamHeading">
//                                 {t.name}
//                             </div>
//                             <div className="nag-tileLine"></div>
//                             <div className="nag-buttonBar">
//                             <DefaultButton className="nag-button nag-disabled" disabled onClick={() => {}}>Joining...</DefaultButton>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//     }
//     else if(t.isMember)
//     {
//         return <div className="nag-threeTile">
//             <div className="nag-teamTile">
//                 <div className="nag-tileContent">
//                     <div className="nag-tileLogo"><TeamLogo team={t} /></div>
//                     <div className="nag-teamHeading">
//                         {t.name}
//                     </div>
//                     <div className="nag-tileLine"></div>
//                     <div className="nag-buttonBar">
//                         <DefaultButton className="nag-button" onClick={() => navigate(t.id)}>Navigate</DefaultButton>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     }
//     else
//     {
//         return <div className="nag-threeTile">
//                     <div className="nag-teamTile">
//                         <div className="nag-tileContent">
//                             <div className="nag-tileLogo"><TeamLogo team={t} /></div>
//                             <div className="nag-teamHeading">
//                                 {t.name}
//                             </div>
//                             <div className="nag-tileLine"></div>
//                             <div className="nag-tileData">{extractMetadataAsString(t, tenantProperties)}</div>
//                         </div>
//                         <div className="nag-tileHover">
//                             <div className="nag-buttonBar">
//                                 <DefaultButton className="nag-button" onClick={() => join(t.id)}>Join</DefaultButton>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//     }
// };

// export { TeamTile }
// export default TeamTile;