import doFetch from './doFetch';
import { access } from 'fs';
import { Dictionary, SearchResult } from '../types';
import { searchSharePoint } from './sharePointSearch';
import * as _ from 'lodash';

export interface IAnalyticsResourceReference {
    webUrl: string;
    id: string;
    type: string;
}

export interface IAnalyticsResourceVisualisation {
    title: string;
    type: string;
    mediaType: string;
    previewImageUrl: string;
    previewText: string;
    containerWebUrl: string;
    containerDisplayName: string;
    containerType: string;
}

export interface IAnalyticsFileResult {
    id: string;
    resourceReference: IAnalyticsResourceReference;
    resourceVisualization: IAnalyticsResourceVisualisation;
}

export interface ISuggestedFileState {
    id: string;
    title: string;
    type: string;
    previewImageUrl: string;
    previewImageData: string;
    resourceReferenceId: string;
    uniqueId: string;
    teamName: string;
}

const extractUniqueId = (webUrl: string): string => {
    const re = new RegExp("sourcedoc=%7B([0-9A-F-]*?)%7D", "i");
    if (re.test(webUrl)) {
        var result = re.exec(webUrl)[1];
        return `${result.toLowerCase()}`;
    }
    return null;
}

const mapResultToState = (d: IAnalyticsFileResult): ISuggestedFileState => {

    const uniqueId = extractUniqueId(d.resourceReference.webUrl);

    return ({
        id: d.id,
        uniqueId,
        title: d.resourceVisualization.title,
        type: d.resourceVisualization.type,
        previewImageUrl: d.resourceVisualization.previewImageUrl,
        previewImageData: null,
        teamName: d.resourceVisualization.containerDisplayName,
        resourceReferenceId: d.resourceReference.id,
    });
}

const bufferToBase64 = (buffer: ArrayBuffer): string => {
    const base64 = Buffer.from(buffer).toString('base64');
    return base64;
}

const getImage = (token: string, imageUrl: string): Promise<string> => {

    const emptyImage = "";

    return new Promise<string>((resolve, reject) => {

        const url = imageUrl;

        var headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        fetch(url, { headers: headers })
            .then((resp: any) => {
                if (resp.ok) {
                    return resp.arrayBuffer();
                }
                else {
                    resolve(emptyImage);
                }
            })
            .then((buffer: ArrayBuffer) => {

                const base64 = bufferToBase64(buffer);
                resolve(base64);

            })
            .catch(err => {
                resolve(emptyImage);
            });
    });
}

const loadImage = (sharePointToken: string, file: ISuggestedFileState): Promise<ISuggestedFileState> => {

    return new Promise<ISuggestedFileState>(async (resolve, reject) => {

        const imageString = await getImage(sharePointToken, file.previewImageUrl);

        file = {
            ...file,
            previewImageData: imageString
        }
        resolve(file);
    });
}

export const loadFileImages = (sharePointToken: string, files: ISuggestedFileState[]): Promise<ISuggestedFileState[]> => {

    return new Promise<ISuggestedFileState[]>(async (resolve, reject) => {

        Promise.all(files.map(f => loadImage(sharePointToken, f)))
            .then((fileImages: ISuggestedFileState[]) => {
                resolve(fileImages);
            })
            .catch(err => {
                resolve(files)
            });
    });
}

const cleanUniqueId = (id: string) => {
    return id && id.toLowerCase().replace("{", "").replace("}", "");
}

const getFileDetails = (sharePointRootUrl: string, sharePointToken: string, managedProperties: Dictionary<string>, files: ISuggestedFileState[]): Promise<SearchResult[]> => {

    return new Promise<SearchResult[]>(async (resolve, reject) => {

        const idmap = files.map(f => f.uniqueId);

        const query = idmap.map(i => `UniqueId:${i}`).join(" ");

        const searchResults = await searchSharePoint(query, "", sharePointRootUrl, sharePointToken, managedProperties);

        const normalizedUniqueIds = searchResults.results.map(r => {
            return {
                ...r,
                uniqueId: cleanUniqueId(r.uniqueId),
            }
        });

        const keyed = _.keyBy(normalizedUniqueIds, r => r.uniqueId);

        const finalResults = files.map(i => {
            if (keyed[i.uniqueId]) {
                return {
                    ...keyed[i.uniqueId],
                }
            }
            else {
                return null;
            }
        }).filter(x => x != null);

        console.log("final results", finalResults);

        resolve(finalResults);
    });
}

const mergeImageData = (keyedmap: Dictionary<ISuggestedFileState>) => (m: SearchResult) => {

    const imageMapItem = keyedmap[m.uniqueId];
    if (imageMapItem && imageMapItem.previewImageData) {

        return {
            ...m,
            previewImageData: imageMapItem.previewImageData,
            previewImageUrl: imageMapItem.previewImageUrl
        }
    }
    else {
        return m;
    }
}

const getSuggestedFiles = (url: string, loadPreviewImage: boolean, graphToken: string, sharePointToken: string, sharePointRootUrl: string, managedProperties: Dictionary<string>): Promise<SearchResult[]> => {

    return new Promise<SearchResult[]>(async (resolve, reject) => {

        try {

            const text = await (doFetch(url, graphToken));

            const json = JSON.parse(text);
            const data: IAnalyticsFileResult[] = json.value as IAnalyticsFileResult[];

            const mapped = data.map(mapResultToState).filter(f => f.uniqueId != null);

            const mappedWithDetails = await getFileDetails(sharePointRootUrl, sharePointToken, managedProperties, mapped);

            if (!loadPreviewImage) {
                resolve(mappedWithDetails);
            }
            else {

                const mappedWithImages = await loadFileImages(sharePointToken, mapped);
                const keyedmap = _.keyBy(mappedWithImages, m => m.uniqueId);
                const finalResult = mappedWithDetails.map(mergeImageData(keyedmap));
                resolve(finalResult);
            }
        }
        catch (err) {
            console.log("Error in getSuggestedFiles", err);
            resolve([])
        }
    });
}

export const getTrendingFiles = async (graphToken: string, sharePointToken: string, sharePointRootUrl: string, managedProperties: Dictionary<string>): Promise<SearchResult[]> => {
    const url = `https://graph.microsoft.com/beta/me/insights/trending?$filter=ResourceVisualization/Type eq 'Excel' OR ResourceVisualization/Type eq 'PowerPoint' OR ResourceVisualization/Type eq 'Word' OR ResourceVisualization/Type eq 'Pdf'`

    const f = await getSuggestedFiles(url, true, graphToken, sharePointToken, sharePointRootUrl, managedProperties);
    return _.take(f, 6);
}


export const getRecentFiles = (graphToken: string, sharePointToken: string, sharePointRootUrl: string, managedProperties: Dictionary<string>): Promise<SearchResult[]> => {

    const url = `https://graph.microsoft.com/beta/me/insights/used?$filter=ResourceVisualization/Type eq 'Excel' OR ResourceVisualization/Type eq 'PowerPoint' OR ResourceVisualization/Type eq 'Word' OR ResourceVisualization/Type eq 'Pdf'`

    return getSuggestedFiles(url, false, graphToken, sharePointToken, sharePointRootUrl, managedProperties);
}