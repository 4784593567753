declare let module: any;
import * as microsoftTeams from '@microsoft/teams-js';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import configureStore from './configureStore';

import { Provider } from 'react-redux';
import { Container } from './components/Container';
import { AppState } from './reducers';
import { loadState } from './api/localStorage';
import { Text } from 'office-ui-fabric-react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { isAuthCallback, isAuthStart } from './api/isAuthRoute';
import { ThemeContainer } from './components/ThemeContainer';
import { AuthContainer } from './components/AuthContainer';
import { AuthSSOStart } from './components/AuthSSOStart';
import { AuthSSOEnd } from './components/AuthSSOEnd';
import { LicenseCheck } from './components/LicenseCheck';
import './styles/Styles.module.scss';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './components/ApplicationInsights';
initializeIcons();

if (isAuthStart(window.location.pathname)) {
    ReactDOM.render(
        <AuthSSOStart />,
        document.getElementById('root')
    );
}
else if (isAuthCallback(window.location.pathname)) {
    ReactDOM.render(
        <AuthSSOEnd />,
        document.getElementById('root')
    );
}
else {
    var renderTid = setTimeout(() => {

        console.log("Teams initialization and context loading took longer than 10 seconds, displaying error");
        ReactDOM.render(
            <Text>Microsoft Teams context was not loaded (timeout).</Text>,
            document.getElementById('root'));
    }, 10000);

    microsoftTeams.initialize(() => {
        microsoftTeams.getContext((context: microsoftTeams.Context) => {

            clearTimeout(renderTid);

            if (context.hostClientType == "ios" || context.hostClientType == "android") {
                ReactDOM.render(
                    <ThemeContainer>
                        <Text>Sorry, Navigator is currently not supported on mobile devices.</Text>
                    </ThemeContainer>,
                    document.getElementById('root'));
            }
            else {
                const { hostClientType, tid, userObjectId } = context;

                const state: AppState = loadState(hostClientType, tid, userObjectId);
                const store = configureStore(hostClientType, tid, userObjectId, state);

                ReactDOM.render(
                    <Provider store={store}>
                        <AppInsightsContext.Provider value={reactPlugin}>
                            <ThemeContainer>
                                <AuthContainer>
                                    <LicenseCheck>
                                        <Container />
                                    </LicenseCheck>
                                </AuthContainer>
                            </ThemeContainer>
                        </AppInsightsContext.Provider>
                    </Provider>,
                    document.getElementById('root')
                );
            }
        });
    });

    if (module.hot) {
        module.hot.accept();
    }
}