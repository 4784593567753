import { UPDATE_SHAREPOINT_TENANT_PROPERTIES, SharePointTenantProperties, UpdateSharePointTenantPropertiesAction } from "../types/sharePointTenantProperties"
import { AppState } from "../reducers"
import { AnyAction } from "redux"
import { ThunkAction, ThunkDispatch } from "redux-thunk"
import { getSharePointTenantProperties } from "../api/getSharePointTenantProperties"
import { createConfig } from '../config'
import { checkLicense } from "../api/licenseCheck"
import { setLicenseStatus } from "./licenseActions"
import { licenseInit } from "../reducers/license"
import { getSSOApplicationToken, getSSOSharePointToken } from "../api/ssoTokens"

export const updateSharePointTenantPropertiesAction = (data: SharePointTenantProperties): UpdateSharePointTenantPropertiesAction => {
    return {
        type: UPDATE_SHAREPOINT_TENANT_PROPERTIES,
        data
    }
}

export const checkLicenseThunk = (): ThunkAction<Promise<void>, AppState, {}, UpdateSharePointTenantPropertiesAction> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState, extraArgument: {}): Promise<void> => {

        return new Promise<void>(async resolve => {

            console.log("Checking license...");

            try {
                const config = createConfig();

                const { licenseCheckRequired } = config;
                const { license, environment } = getState();

                console.log("License check required", licenseCheckRequired)

                if (licenseCheckRequired && license.status != "Valid") {
                    const appToken = await getSSOApplicationToken();
                    const checkResult = await checkLicense(environment.tenantId, appToken, config.licenseCheckBaseUrl);
                    dispatch(setLicenseStatus(checkResult.licenseStatus))
                }
                else {
                    dispatch(setLicenseStatus("Valid"));
                }

                resolve();
            }
            catch (err) {
                console.log(err);
                resolve();
            };
        });
    }
}


export const checkSharePointTenantPropertiesThunk = (): ThunkAction<Promise<void>, AppState, {}, UpdateSharePointTenantPropertiesAction> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState, extraArgument: {}): Promise<void> => {

        return new Promise<void>(async resolve => {

            console.log("Checking SharePoint tenant properties...");

            try {
                const config = createConfig();

                const { environment } = getState();
                //TODO - check last retrieved to avoid pulling every time
                //if(tenantProperties && tenantProperties.lastRetrieved)
                const sharePointToken = await getSSOSharePointToken();

                const newProperties = await getSharePointTenantProperties(environment.sharePointRoot, sharePointToken);
                dispatch(updateSharePointTenantPropertiesAction(newProperties))
                resolve();
            }
            catch (err) {
                console.log(err);
                resolve();
            };
        });
    }
}

