import { DiscoverContentState } from '../types';
import { ContentSearchActionTypes, BEGIN_CONTENT_SEARCH, CONTENT_SEARCH_SUCCESS, CONTENT_SEARCH_FAILED, UPDATE_CONTENT_SEARCH_TERM, BEGIN_CONTENT_SEARCH_MORE, CONTENT_SEARCH_SUCCESS_MORE, CONTENT_SEARCH_FAILED_MORE, ADD_CONTENT_SEARCH_REFINER, REMOVE_CONTENT_SEARCH_REFINER, BEGIN_REFINEMENT_SEARCH, REFINEMENT_SEARCH_SUCCESS, REFINEMENT_SEARCH_FAILED, BEGIN_PROMOTED_CONTENT_SEARCH, PROMOTED_CONTENT_SEARCH_SUCCESS, PROMOTED_CONTENT_SEARCH_FAILED, CLEAR_PROMOTED_RESULTS } from '../types/contentSearch';
import { ClearStateAction, CLEAR_STATE } from '../types/clearState';

export const initialState: DiscoverContentState = {
    searchStatus: "",
    refinerSearchStatus: "",
    searchTerm: "",
    searchRefiners: [],
    resultTerm: "",
    resultSummary: "",
    results: [],
    resultRefiners: [],
    resultCount: 0,
    moreResultsStatus: "",
    promotedResults: []
}

type discoverContentReducerActions = ContentSearchActionTypes | ClearStateAction;

export const discoverContentReducer = (state: DiscoverContentState = initialState, action: discoverContentReducerActions): DiscoverContentState => {

    switch (action.type) {
        case CLEAR_STATE:
            return initialState;
        case BEGIN_REFINEMENT_SEARCH:
            return {
                ...state,
                refinerSearchStatus: "Refining"
            }
        case REFINEMENT_SEARCH_SUCCESS:
            return {
                ...state,
                refinerSearchStatus: "",
                results: action.results,
                resultSummary: action.summary,
                resultRefiners: action.resultRefiners,
                resultTerm: action.resultTerm,
                resultCount: action.resultCount
            }
        case REFINEMENT_SEARCH_FAILED:
            return {
                ...state,
                refinerSearchStatus: ""
            }
        case ADD_CONTENT_SEARCH_REFINER:
            return {
                ...state,
                searchRefiners: [...state.searchRefiners, action.refiner]
            }
        case REMOVE_CONTENT_SEARCH_REFINER:
            return {
                ...state,
                searchRefiners: state.searchRefiners.filter(r => r != action.refiner)
            };
        case BEGIN_CONTENT_SEARCH:
            return {
                ...state,
                searchStatus: action.status,
                refinerSearchStatus: "",
                searchRefiners: [],
                resultSummary: "",
                results: [],
                resultRefiners: [],
                resultCount: 0,
                moreResultsStatus: ""
            };
        case CONTENT_SEARCH_SUCCESS:
            return {
                ...state,
                searchStatus: "",
                results: action.results,
                resultSummary: action.summary,
                resultRefiners: action.resultRefiners,
                resultTerm: action.resultTerm,
                resultCount: action.resultCount
            }
        case CONTENT_SEARCH_FAILED:
            return {
                ...state,
                searchStatus: "Error"
            }
        case UPDATE_CONTENT_SEARCH_TERM:
            if (action.query == "") {
                return initialState;
            }
            else {
                return {
                    ...state,
                    searchTerm: action.query
                }
            }
        case BEGIN_CONTENT_SEARCH_MORE:
            return {
                ...state,
                moreResultsStatus: "Loading..."
            };
        case CONTENT_SEARCH_SUCCESS_MORE:
            return {
                ...state,
                moreResultsStatus: "",
                results: [
                    ...state.results,
                    ...action.results
                ]
            };
        case CONTENT_SEARCH_FAILED_MORE:
            return {
                ...state,
                moreResultsStatus: "Error"
            };
        case BEGIN_PROMOTED_CONTENT_SEARCH:
            return {
                ...state,
                promotedResults: []
            };
        case PROMOTED_CONTENT_SEARCH_SUCCESS:
            return {
                ...state,
                promotedResults: action.results
            };
        case PROMOTED_CONTENT_SEARCH_FAILED:
            return {
                ...state,
                promotedResults: []
            };
        case CLEAR_PROMOTED_RESULTS:
            return {
                ...state,
                promotedResults: []
            };
        default:
            return state;
    }
}

export default discoverContentReducer;