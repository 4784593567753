
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { TeamState, BeginRetrieveTeamsInBackgroundAction, RetrieveTeamsInBackgroundSuccessAction, RetrieveTeamsInBackgroundFailedAction, BEGIN_RETRIEVE_TEAMS_IN_BACKGROUND, RETRIEVE_TEAMS_IN_BACKGROUND_SUCCESS, RETRIEVE_TEAMS_IN_BACKGROUND_FAILED, UpdateTeamStateAction, UPDATE_TEAM_STATE, UpdateTeamImageAction, UPDATE_TEAM_IMAGE, ITeamImage, UpdateTeamBulkAction, UPDATE_TEAM_BULK, RemoveDeletedTeamAction, REMOVE_DELETED_TEAMS } from '../types/teams';

import {
    TeamsActionTypes,
    BeginRetrieveTeamsAction, BEGIN_RETRIEVE_TEAMS,
    RetrieveTeamsSuccessAction, RETRIEVE_TEAMS_SUCCESS,
    RetrieveTeamsFailedAction, RETRIEVE_TEAMS_FAILED
} from '../types/teams';
import { AppState } from '../reducers';
import { getAllGroups, getMyTeamIds, getTeamImage, getTeamInfo } from '../api/teams';
import * as _ from 'lodash';
import { UpdateSuggestedTeamsAction, UPDATE_SUGGESTED_TEAMS } from '../types/discoverTeams';
import { getSuggestedTeams } from '../api/getSuggestedTeams';
import { cacheImage, loadCachedImage } from '../api/localStorage';
import teamImagesReducer from '../reducers/teamImages';
import { navigateToTeam, navigateToTeamFiles } from '../api/navigateToTeam';
import {
    getSSOGraphToken,
} from '../api/ssoTokens';

const beginRetrieveTeamsInBackground = (): BeginRetrieveTeamsInBackgroundAction => ({ type: BEGIN_RETRIEVE_TEAMS_IN_BACKGROUND });

const retrieveTeamsInBackgroundSuccess = (): RetrieveTeamsInBackgroundSuccessAction => ({ type: RETRIEVE_TEAMS_IN_BACKGROUND_SUCCESS });

const retrieveTeamsInBackgroundFailed = (): RetrieveTeamsInBackgroundFailedAction => ({ type: RETRIEVE_TEAMS_IN_BACKGROUND_FAILED });

export const updateSuggestedTeams = (appState: AppState): UpdateSuggestedTeamsAction => {

    const teamIds: string[] = getSuggestedTeams(appState).map(s => s.id);

    return {
        type: UPDATE_SUGGESTED_TEAMS,
        suggestedTeamStatus: "Loaded",
        suggestedTeamIds: teamIds,
        suggestedTeamLastUpdated: new Date()
    };
}

const updateTeamImage = (image: ITeamImage): UpdateTeamImageAction => ({
    type: UPDATE_TEAM_IMAGE,
    image,
});

const removeTeamsById = (ids: string[]): RemoveDeletedTeamAction => ({ type: REMOVE_DELETED_TEAMS, ids });

const beginRetrieveTeams = (): BeginRetrieveTeamsAction => ({ type: BEGIN_RETRIEVE_TEAMS });

const retrieveTeamsSuccess = (): RetrieveTeamsSuccessAction => ({ type: RETRIEVE_TEAMS_SUCCESS });

const retrieveTeamsFailed = (): RetrieveTeamsFailedAction => ({ type: RETRIEVE_TEAMS_FAILED });

export const retrieveTeamImageThunk = (groupId: string): ThunkAction<Promise<void>, AppState, string, TeamsActionTypes> => {

    return (dispatch: ThunkDispatch<AppState, string, AnyAction>, getState: () => AppState, extra: string): Promise<void> => {

        return new Promise<void>(async resolve => {

            //const appState = getState();
            const graphToken = await getSSOGraphToken();

            let teamImage: ITeamImage = loadCachedImage(groupId);

            if (teamImage == null) {
                teamImage = await getTeamImage(graphToken, groupId);
            }

            if (teamImage && teamImage.base64Data) {

                cacheImage(groupId, teamImage);

                dispatch(updateTeamImage(teamImage))
            }
        });
    }
}

export const navigateToTeamFilesThunk = (groupId: string): ThunkAction<Promise<void>, AppState, {}, TeamsActionTypes> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> => {

        return new Promise<void>(async resolve => {

            const appState = getState();
            const t = appState.teams[groupId];
            if (t && t.internalId) {
                navigateToTeamFiles(t.internalId);
                resolve();
            }
            else {
                const graphToken = await getSSOGraphToken();

                const teamInfo = await getTeamInfo(graphToken, groupId);

                if (teamInfo) {
                    const team = appState.teams[groupId];

                    if (teamInfo.internalId != team.internalId || teamInfo.isArchived != team.isArchived) {
                        dispatch(updateTeamData({
                            ...team,
                            internalId: teamInfo.internalId,
                            isArchived: teamInfo.isArchived,
                        }));
                    }
                    navigateToTeamFiles(teamInfo.internalId);
                }
                else {
                    alert("Unable to get team info. Navigate cancelled");
                }

                resolve();
            }
        });
    }
}

export const navigateToTeamThunk = (groupId: string): ThunkAction<Promise<void>, AppState, {}, TeamsActionTypes> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> => {

        return new Promise<void>(async resolve => {

            const appState = getState();
            const t = appState.teams[groupId];
            if (t && t.internalId) {
                navigateToTeam(t.internalId);
                resolve();
            }
            else {
                const graphToken = await getSSOGraphToken();

                const teamInfo = await getTeamInfo(graphToken, groupId);

                if (teamInfo) {
                    const team = appState.teams[groupId];

                    if (teamInfo.internalId != team.internalId || teamInfo.isArchived != team.isArchived) {
                        dispatch(updateTeamData({
                            ...team,
                            internalId: teamInfo.internalId,
                            isArchived: teamInfo.isArchived,
                        }));
                    }
                    navigateToTeam(teamInfo.internalId);
                }
                else {
                    alert("Unable to get team info. Navigate cancelled");
                }

                resolve();
            }
        });
    }
}

export const retrieveTeamInfoThunk = (groupId: string): ThunkAction<Promise<void>, AppState, {}, TeamsActionTypes> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> => {

        return new Promise<void>(async resolve => {

            const appState = getState();
            const graphToken = await getSSOGraphToken();

            const teamInfo = await getTeamInfo(graphToken, groupId);

            if (teamInfo) {
                const team = appState.teams[groupId];

                if (teamInfo.internalId != team.internalId || teamInfo.isArchived != team.isArchived) {
                    dispatch(updateTeamData({
                        ...team,
                        internalId: teamInfo.internalId,
                        isArchived: teamInfo.isArchived,
                    }));
                }
            }
        });
    }
}

export const retrieveTeamsThunk = (): ThunkAction<Promise<void>, AppState, {}, TeamsActionTypes> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> => {

        return new Promise<void>(async resolve => {

            dispatch(beginRetrieveTeams());

            const state = getState();
            const token = await getSSOGraphToken();

            const tenantProperties = state.tenantProperties;

            try {
                var myTeamIds = await getMyTeamIds(token);
                var groups = await getAllGroups(token, tenantProperties);

                var dicIds = _.keyBy(myTeamIds, i => i);

                groups = groups.map(g => ({ ...g, isMember: dicIds[g.id] ? true : false }))

                // groups.forEach(g => {
                //     dispatch(updateTeamData(g));
                //     //dispatch(retrieveTeamImageThunk(g.id));
                //     dispatch(retrieveTeamInfoThunk(g.id));
                // });

                let updates: TeamState[] = [];

                groups.forEach(g => {

                    let current = getState().teams[g.id];

                    if (!current) {
                        updates.push(g);
                        //dispatch(updateTeamData(g));
                    }
                    else if (hasTeamChanged(current, g)) {
                        updates.push(g);
                        //dispatch(updateTeamData(g));
                    }

                    // if(current && current.isMember && current.internalId)
                    // {
                    //     //do nothing
                    // } 
                    // else if(g.isMember && !g.internalId)
                    // {
                    //     dispatch(retrieveTeamInfoThunk(g.id));
                    // }
                })

                if (updates.length > 0) {
                    dispatch(updateTeamDataBulk(updates));
                }

                dispatch(retrieveTeamsSuccess());

                dispatch(updateSuggestedTeams(getState()));
            }
            catch (err) {
                console.log("retrieveTeamsThunkError", err);
                dispatch(retrieveTeamsFailed());
            }
        });
    }
}

export const retrieveTeamsInBackgroundThunk = (): ThunkAction<Promise<void>, AppState, {}, TeamsActionTypes> => {

    return (dispatch: ThunkDispatch<AppState, {}, AnyAction>, getState: () => AppState): Promise<void> => {

        return new Promise<void>(async resolve => {

            dispatch(beginRetrieveTeamsInBackground());

            const state = getState();
            const token = await getSSOGraphToken();
            const tenantProperties = state.tenantProperties;

            try {
                var myTeamIds = await getMyTeamIds(token);
                var groups = await getAllGroups(token, tenantProperties);

                var dicIds = _.keyBy(myTeamIds, i => i);

                groups = groups.map(g => ({ ...g, isMember: dicIds[g.id] ? true : false }))

                const groupIds = groups.map(g => g.id);

                let updates: TeamState[] = [];

                let removals = _.values(getState().teams).filter(g => groupIds.indexOf(g.id) === -1).map(g => g.id);

                console.log("REMOVE", removals);


                groups.forEach(g => {

                    let current = getState().teams[g.id];

                    if (!current) {
                        updates.push(g);
                        //dispatch(updateTeamData(g));
                    }
                    else if (hasTeamChanged(current, g)) {
                        updates.push(g);
                        //dispatch(updateTeamData(g));
                    }

                    // if(current && current.isMember && current.internalId)
                    // {
                    //     //do nothing
                    // } 
                    // else if(g.isMember && !g.internalId)
                    // {
                    //     dispatch(retrieveTeamInfoThunk(g.id));
                    // }
                })

                if (updates.length > 0) {
                    dispatch(updateTeamDataBulk(updates));
                }

                if (removals.length) {
                    dispatch(removeTeamsById(removals))
                }

                dispatch(retrieveTeamsInBackgroundSuccess());

                dispatch(updateSuggestedTeams(getState()));
            }
            catch (err) {
                console.log("retrieveTeamsThunkError", err);
                dispatch(retrieveTeamsInBackgroundFailed());
            }
        });
    }
}

const hasTeamMetadataChanged = (oldVersion: TeamState, newVersion: TeamState): boolean => {

    const oldString = JSON.stringify(oldVersion.metaData);
    const newString = JSON.stringify(newVersion.metaData);

    return oldString == newString ? false : true;
}

const hasTeamChanged = (oldVersion: TeamState, newVersion: TeamState): boolean => {

    if (oldVersion.name != newVersion.name
        || oldVersion.description != newVersion.description
        || oldVersion.isMember != newVersion.isMember
        || hasTeamMetadataChanged(oldVersion, newVersion)
    ) {
        return true;
    }

    return false;
}

export const updateTeamDataBulk = (teams: TeamState[]): UpdateTeamBulkAction => ({
    type: UPDATE_TEAM_BULK,
    teams
});

export const updateTeamData = (team: TeamState): UpdateTeamStateAction => ({
    type: UPDATE_TEAM_STATE,
    team
});