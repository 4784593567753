import * as React from 'react';
import * as _ from 'lodash';
import * as _f from 'lodash/fp'
import { DetailsList, DetailsRow, IDetailsRowProps, SelectionMode, IDetailsRowStyles, IColumn, IDetailsHeaderProps, DetailsHeader, ColumnActionsMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { TeamState } from '../types/teams';
import { SharePointTenantProperties, SharePointClassification, SharePointField } from '../types/sharePointTenantProperties';
import { TeamsContextMenu } from './DiscoverTeamsContextMenu';
import { Dispatch } from 'redux';
import { joinTeamThunk } from '../actions/joinTeamActions';
import { navigateToTeam, navigateToTeamFiles } from '../api/navigateToTeam';
import { MessageBar } from './MessageBar';
import { TeamLogo } from './TeamLogo';
import { SectionHeading } from './SectionHeading';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { navigateToTeamThunk, navigateToTeamFilesThunk } from '../actions/teamsActions';
import recentFilesReducer from '../reducers/recentFiles';
import { render } from 'react-dom';
import { IContextualMenuProps, ContextualMenu, IContextualMenuItem, Checkbox } from 'office-ui-fabric-react';
import { AppState } from '../reducers';
import { updateVisibleTeamsColumns } from '../actions/userPreferences';
import { DefaultButton } from 'office-ui-fabric-react';

export interface IDiscoverTeamsTableProps {
    appState: AppState;
    teams: TeamState[];
    tenantProperties: SharePointTenantProperties;
    dispatch: Dispatch;
    title?: string;
    selectedClassificationName?: string;
    useDynamicColumns?: boolean;
    maxVisibleResults?: number;
}

const renderMetadataColumn = (item: TeamState, index: number, column: IColumn): JSX.Element => {

    if (item.metaData && item.metaData[column.key]) {
        return <span>{item.metaData[column.key]}</span>
    }
    return <span></span>
}

const getTeamMetadataValueForDynamicColumn = (item: TeamState, column: IColumn): string => {
    var val = null;
    if (item.metaData) {
        const vals = _f.values(item.metaData).filter(value => value);
        // add one because we want to ignore the 'classification', which will always be first     
        const colIndex = +column.key + 1;
        val = vals[colIndex];
    }
    return val;
}

const renderDynamicMetadataColumn = (item: TeamState, index: number, column: IColumn): JSX.Element => {
    return <span>{getTeamMetadataValueForDynamicColumn(item, column)}</span>
}

const onRenderActions = (dispatch: Dispatch<any>) => (item: TeamState, index: number, column: IColumn): JSX.Element => {

    const join = (id: string) => dispatch(joinTeamThunk(id));
    const navigate = (id: string) => dispatch(navigateToTeamThunk(id));
    const navigateToFiles = (id: string) => dispatch(navigateToTeamFilesThunk(id));

    return <TeamsContextMenu team={item} navigateToFiles={navigateToFiles} join={join} navigate={navigate} />
}

const renderItemColumn = (item: any, index: number, column: IColumn): JSX.Element => {

    const content = item[column.key] as string;

    return <span>{content}</span>
}

const renderTeamNameColumn = (dispatch: Dispatch<any>) => (item: TeamState, index: number, column: IColumn): JSX.Element => {

    if (item.isMember) {
        return <Link className="nag-tableLink" onClick={() => dispatch(navigateToTeamThunk(item.id))}>{item.name}</Link>
    }
    else {
        return <span>{item.name}</span>
    }
}

const onRenderIcon = (item: TeamState, index: number, column: IColumn): JSX.Element => {
    return <TeamLogo className="nag-teamLogoList" team={item} />
}

const onRenderVisibility = (item: TeamState, index: number, column: IColumn): JSX.Element => {
    return <div>
        <Icon iconName={item.visibility == "Public" ? "Globe" : "LockSolid"} title={item.visibility} />
    </div>
}

const onRenderRow = (props: IDetailsRowProps): JSX.Element => {
    const className = `nag-tableRow`
    return <DetailsRow {...props} className={className} />
}

const getValidTableColumns = _f.pipe(
    _f.values,
    _f.filter(f => f.type == "ManagedMetadata" || f.type == "Text"),
    _f.map(f => f.displayName),
    _f.uniq
)

const getMergedMetadataColumns = (classifications: SharePointClassification[]): string[] => {

    return _f.pipe(
        _f.flatMap<SharePointClassification, SharePointField[]>((v) => v.fields),
        getValidTableColumns
    )(classifications);
}

const fixedColumnKeys = ["icon", "name", "visibility", "actions"];

const getCustomisableColumns = (columns: IColumn[]): IColumn[] => {

    const customisableColumns = columns.filter(c => fixedColumnKeys.indexOf(c.key) == -1);
    return customisableColumns;
}

interface IDiscoverTeamsTableState {
    actionsContextMenuProps: IContextualMenuProps,
    tableColumns: IColumn[];
    visibleColumns: string[];
    dynamicColumnsWithContent: string[];
    showTruncatedResults: boolean;
}

const getActionsContextMenuProps = (columns: IColumn[], visibleColumns: string[],
    event: React.MouseEvent<HTMLElement>, onDismiss: () => void, onItemChecked: (column: IColumn, checked: boolean) => void): IContextualMenuProps => {

    const customisableColumns = getCustomisableColumns(columns);

    const menuItems = customisableColumns.map(c => {

        const checked = visibleColumns.indexOf(c.key) >= 0;

        return {
            key: c.key,
            text: c.name,
            onRender: (item: IContextualMenuItem) => {
                return <div className="nag-checkboxMenuItem"><Checkbox label={item.text} defaultChecked={checked} onChange={(event, checked) => onItemChecked(c, checked)} /></div>
            },
            onClick: (ev: React.MouseEvent<HTMLElement>) => { ev.preventDefault(); return false; }
        }
    });

    return {
        items: menuItems,
        target: event.currentTarget as HTMLElement,
        onDismiss
    }
}

const getDynamicColumnsWithContent = (teams: TeamState[], tableColumns: IColumn[]): string[] => {
    return tableColumns ? tableColumns.filter(c => {
        if (fixedColumnKeys.indexOf(c.key) >= 0) {
            return false;
        }

        // only check the first 100 teams to avoid scaling issues
        for (var i = 0; i < Math.min(teams.length, 99); i++) {
            const team = teams[i];
            const val = getTeamMetadataValueForDynamicColumn(team, c);
            if (val) {
                return true;
            }
        }

        return false;
    }).map(c => c.key) : [];
}

export class DiscoverTeamsTable extends React.Component<IDiscoverTeamsTableProps, IDiscoverTeamsTableState> {

    constructor(props: IDiscoverTeamsTableProps) {
        super(props);

        this.state = {
            actionsContextMenuProps: null,
            visibleColumns: null,
            tableColumns: [],
            dynamicColumnsWithContent: [],
            showTruncatedResults: false
        }
    }

    public componentDidMount() {
        this.initialiseTableColumns();
    }

    public componentDidUpdate(prevProps: IDiscoverTeamsTableProps) {
        const { teams, useDynamicColumns } = this.props;

        if (JSON.stringify(prevProps.teams) !== JSON.stringify(teams) || prevProps.useDynamicColumns !== useDynamicColumns) {
            this.initialiseTableColumns();
        }
    }

    private initialiseTableColumns = () => {
        const { tenantProperties, dispatch, selectedClassificationName, teams, useDynamicColumns } = this.props;

        const metaCols: IColumn[] = this.getMetadataColumns(tenantProperties);

        const tableColumns: IColumn[] = [
            {
                key: "icon",
                name: "",
                minWidth: 40,
                maxWidth: 40,
                className: "nag-logoColumn",
                onRender: onRenderIcon
            },
            {
                key: "name",
                name: "Team name",
                minWidth: 100,
                onRender: renderTeamNameColumn(dispatch),
                isResizable: true,
            },
            ...metaCols,
            {
                key: "visibility",
                name: "",
                minWidth: 40,
                maxWidth: 40,
                onRender: onRenderVisibility,
                className: "nag-visibilityColumn"
            },
            {
                key: "actions",
                name: "Actions",
                minWidth: 40,
                maxWidth: 40,
                onRender: onRenderActions(dispatch),
                className: "nag-actionColumn",
                headerClassName: "nag-actionColumnHeader",
                isIconOnly: true,
                iconName: !useDynamicColumns && 'ColumnOptions',
                onColumnClick: (event: React.MouseEvent<HTMLElement>, column: IColumn) => {
                    if (!useDynamicColumns) {
                        this.openActionsMenu(event);
                    }
                },
                onColumnContextMenu: (column: IColumn, event: React.MouseEvent<HTMLElement>) => {
                    if (!useDynamicColumns) {
                        this.openActionsMenu(event);
                    }
                },
                columnActionsMode: ColumnActionsMode.clickable,
            }
        ]

        const { discoverTeamsColumns } = this.props.appState.userPreferences;
        const classificationColumnPrefs = discoverTeamsColumns && (discoverTeamsColumns.hasOwnProperty(selectedClassificationName) ? discoverTeamsColumns[selectedClassificationName] : discoverTeamsColumns["__default"]);
        const visibleColumns = classificationColumnPrefs == null ? getCustomisableColumns(tableColumns).map(c => c.key) : classificationColumnPrefs;

        this.setState({
            tableColumns,
            visibleColumns,
            dynamicColumnsWithContent: getDynamicColumnsWithContent(teams, tableColumns)
        });
    }

    private getMetadataColumns = (tenantProperties: SharePointTenantProperties): IColumn[] => {
        const { hasCollaborateConfiguration, classifications } = tenantProperties;
        const { useDynamicColumns, selectedClassificationName } = this.props;

        if (hasCollaborateConfiguration) {
            if (!useDynamicColumns) {
                const selectedClassification = selectedClassificationName ? classifications.find(f => f.name === selectedClassificationName) : null;
                const fieldNames = selectedClassification ? getValidTableColumns(selectedClassification.fields) : getMergedMetadataColumns(classifications);

                return fieldNames.map(f => {
                    return {
                        key: f,
                        name: f,
                        minWidth: 150,
                        onRender: renderMetadataColumn,
                        isResizable: true,
                    }
                });
            }
            else {
                const allClassificationsColCounts = classifications.map(classification => getValidTableColumns(classification.fields).length);
                const dynamicColumnCount = Math.max(...allClassificationsColCounts);

                return Array(dynamicColumnCount).fill(null).map((nothing, i) => ({
                    key: i.toString(),
                    name: '',//`Column ${i + 1}`,
                    minWidth: 150,
                    onRender: renderDynamicMetadataColumn,
                    isResizable: false
                }));
            }
        }
        return [];
    }

    private dismissActionsMenu = () => {
        this.setState({
            actionsContextMenuProps: null
        });
    }

    private contextMenuItemChecked = (column: IColumn, checked: boolean) => {

        const { dispatch, selectedClassificationName } = this.props;

        //console.log("Checked", column.key, checked);

        if (checked) {
            const newCols = [...this.state.visibleColumns, column.key]
            this.setState({ visibleColumns: newCols });
            dispatch(updateVisibleTeamsColumns(selectedClassificationName, newCols));
        }
        else {
            const newCols = this.state.visibleColumns.filter(x => x != column.key);
            this.setState({ visibleColumns: newCols });
            dispatch(updateVisibleTeamsColumns(selectedClassificationName, newCols));
        }
    }

    private openActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            actionsContextMenuProps: getActionsContextMenuProps(this.state.tableColumns, this.state.visibleColumns,
                event, this.dismissActionsMenu, this.contextMenuItemChecked)
        });
    }

    public render() {
        const { teams, title, selectedClassificationName, useDynamicColumns, maxVisibleResults } = this.props;
        const { tableColumns, visibleColumns, dynamicColumnsWithContent, showTruncatedResults } = this.state;
        //const metaCols: IColumn[] = getMetadataColumns(tenantProperties);

        //const cols = this.state.tableColumns;

        if (teams && teams.length) {
            const actionsMenuProps = this.state.actionsContextMenuProps;

            const cols = tableColumns.filter(c => {
                if (fixedColumnKeys.indexOf(c.key) >= 0) {
                    return true;
                }

                if (!useDynamicColumns) {
                    return visibleColumns.indexOf(c.key) >= 0;
                }
                else {
                    return dynamicColumnsWithContent.indexOf(c.key) >= 0;
                }
            });

            // adding a little leeway so 'show more' doesn't just show one or two extra results
            const truncateResults = maxVisibleResults ? teams.length > (maxVisibleResults + 2) : false;

            return (
                <div>
                    {title && <SectionHeading heading={title} hasBorder={selectedClassificationName && selectedClassificationName.length > 0} />}
                    {actionsMenuProps && <ContextualMenu {...actionsMenuProps} />}
                    <div className="nag-tableContainer">
                        <DetailsList columns={cols}
                            selectionMode={SelectionMode.none}
                            onRenderItemColumn={renderItemColumn}
                            onRenderRow={onRenderRow}
                            items={!truncateResults || showTruncatedResults ? teams : _.take(teams, maxVisibleResults)}
                        />
                        {
                            truncateResults &&
                            <DefaultButton className={"nag-showMore"} iconProps={{ iconName: !showTruncatedResults ? "ChevronDown" : "ChevronUp" }} onClick={() => { this.setState({ showTruncatedResults: !showTruncatedResults }) }}>{!showTruncatedResults ? "Show more" : "Show less"}</DefaultButton>
                        }
                    </div>
                </div>
            );
        }
        else {
            return (
                <React.Fragment>
                    {title && <SectionHeading heading={title} />}
                    <MessageBar message="There are no teams currently available to view" />
                </React.Fragment>
            );
        }
    }
}