import * as microsoftTeams from '@microsoft/teams-js';
import * as React from 'react';

//import TeamsBaseComponent, { ITeamsBaseComponentState, ITeamsBaseComponentProps} from './TeamsBaseComponent';

export interface IConfigureTeamTabState {
  graphData?: string;
  token?: string;
}

export interface IConfigureTeamTabProps {

}

export class ConfigureTeam extends React.Component<IConfigureTeamTabProps, IConfigureTeamTabState> {


  componentDidMount() {

    microsoftTeams.initialize();

    microsoftTeams.settings.registerOnSaveHandler(function (saveEvent) {
      microsoftTeams.settings.setSettings({
        suggestedDisplayName: "Navigator",
        contentUrl: window.location.protocol + "//" + window.location.host + "/team?name={loginHint}&tenant={tid}&theme={theme}",
        entityId: "5p.navigator.team.tab",
        websiteUrl: window.location.protocol + "//" + window.location.host,
      });
      saveEvent.notifySuccess();
    });

    microsoftTeams.settings.setValidityState(true);

  }

  render() {

    return (
      <div>
        <div className="nag-configSplashTitle">
          Welcome to Navigator
        </div>
        <div className="nag-configSplashDescription">
          Navigator can help you classify your team and its content. Once your team has been classified, you'll find that Navigator will be able to more easily assist you and your teammates in discovering valuable content and minimise time spent searching.
        </div>
      </div>
    );
  }
}