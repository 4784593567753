import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';

import { TextField, ITextFieldProps, Dropdown, IDropdownProps, IDropdownOption, SearchBox } from 'office-ui-fabric-react';

import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { updateSearchTerm, updateSearchClassification } from '../actions/discoverTeams';
import { SharePointClassification } from '../types/sharePointTenantProperties';
import { ClearableDropdown } from './ClearableDropdown';

interface IDiscoverTeamsSearchBarProps {
    dispatch: Dispatch<any>;
    appState: AppState;
}

const getClassificationsForDropDown = (props: SharePointClassification[]): string[] => {
    return props.map(c => c.name);
}

const getClassificationDropDownItems = (values: string[]): IDropdownOption[] => {

    // return [{key: "all", text: "All Classifications"}, ...values.map(v => {
    //     return {
    //         key: v,
    //         text: v,
    //     }
    // })];

    return values.map(v => {
        return {
            key: v,
            text: v,
            hidden: false
        }
    });
}

const DiscoverTeamsSearchBar: React.FunctionComponent<IDiscoverTeamsSearchBarProps> = (props: IDiscoverTeamsSearchBarProps) => {

    const { dispatch, appState } = props;
    const { searchTerm, classification } = appState.discoverTeams;

    const { hasCollaborateConfiguration, classifications } = appState.tenantProperties;

    const updateSearchText = (value: string) => {
        dispatch(updateSearchTerm(value));
    }

    const updateClassificationFilter = (option: IDropdownOption) => {
        if (option != null) {

            dispatch(updateSearchClassification(option.key.toString()));
        }
        else {
            dispatch(updateSearchClassification(null));
        }
    }

    const clearSearch = () => {
        dispatch(updateSearchTerm(""));
    }

    const key = classification ? classification : ""

    const options = getClassificationDropDownItems(getClassificationsForDropDown(classifications));
    const classificationFilter = hasCollaborateConfiguration
        ?
        <div className="nag-gridcol-12-6">
            <ClearableDropdown placeholder="Classification"
                onChanged={option => updateClassificationFilter(option)}
                selectedKey={key} options={options} />

            {/* <Dropdown onChanged={option => updateClassificationFilter(option)} 
                                        selectedKey={classification} 
                                        options={options} /> */}

        </div>
        :
        null;

    const queryChange = (e: any, v: any) => updateSearchText(v);

    return (
        <div className="nag-gridrow">
            <div className="nag-gridcol-12-6">

                {/* <TextField autoComplete="off" iconProps={{iconName: "Search"}}  
                value={searchTerm} onChange={queryChange} 
                placeholder="What are you looking for?" /> */}

                <SearchBox autoComplete="off"
                    value={searchTerm}
                    onClear={clearSearch}
                    onSearch={updateSearchText}
                    // onChange={queryChange} changing to use onSearch for consistency with DiscoverContent
                    placeholder="What are you looking for?" />
            </div>
            {classificationFilter}
        </div>)
}

const mapStateToProps = (state: AppState) => ({ appState: state });
const mapDispatchToProps = (dispatch: Dispatch) => ({ dispatch });

const connected = connect(mapStateToProps, mapDispatchToProps)(DiscoverTeamsSearchBar);

export { connected as DiscoverTeamsSearchBar };
export default connected;