import { Dictionary } from "../types";
import { ITeamImage, UpdateTeamImageAction, UPDATE_TEAM_IMAGE } from "../types/teams";


const initialState: Dictionary<ITeamImage> = {}

export const teamImagesReducer = (state: Dictionary<ITeamImage> = initialState, action: UpdateTeamImageAction) => {

    switch (action.type) {

        case UPDATE_TEAM_IMAGE:
            return {
                ...state,
                [action.image.id]: action.image
            }
        default: return state;
    }
}

export default teamImagesReducer;