import { PlaceholderFilesActions, BEGIN_RECENT_FILES_GET, BEGIN_TRENDING_FILES_GET, RECENT_FILES_GET_SUCCESS, RECENT_FILES_GET_FAILED, TRENDING_FILES_GET_FAILED, TRENDING_FILES_GET_SUCCESS } from "../types/recentFiles";

import { PlaceholderFilesState } from "./recentFiles";

const initialState: PlaceholderFilesState = {
    status: "None",
    files: []
}

export const trendingFilesReducer = (state: PlaceholderFilesState = initialState, action: PlaceholderFilesActions) => {

    switch (action.type) {
        case BEGIN_TRENDING_FILES_GET:
            return state;
        case TRENDING_FILES_GET_SUCCESS:
            return {
                ...state,
                files: action.files
            };
        case TRENDING_FILES_GET_FAILED:
            return state;
    }
    return state;
}

export default trendingFilesReducer;