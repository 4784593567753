import * as React from 'react';

export interface IMessageBarProps {
    message: string;
}

export const MessageBar: React.FunctionComponent<IMessageBarProps> = (props) => {

    return <div className="nag-messageBar">{props.message}</div>

}