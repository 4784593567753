import { AppState } from '../reducers';
import * as _ from 'lodash';
import { isAuthRoute } from './isAuthRoute';
import { initialState as discoverTeamsInit } from '../reducers/discoverTeams';
import { initialState as discoverContentInit } from '../reducers/discoverContent';
import { initialState as filesInit } from '../reducers/recentFiles';
import { initialState as environmentInit } from "../reducers/environment";

import { ITeamImage } from '../types/teams';
import { licenseInit } from '../reducers/license';

const storageKey: string = 'navigator';
const localStorageKey: string = `${storageKey}.local`;
const sessionStorageKey: string = `${storageKey}.session`;

// const parseDate = (d: any): Date => {
//     if(d && d.length){
//         try{
//             return new Date(d);
//         }
//         catch(err){
//             return null;
//         }
//     }
//     return null;
// }

export const loadState = (hostClientType: string, tenantId: string, userId: string): AppState => {
    try {
        const id = `${tenantId}.${userId}`;
        const localKey = `${localStorageKey}.${id}`;
        const sessionKey = `${sessionStorageKey}.${id}`;

        let localState: any;
        let sessionState: any;

        localState = localStorage.getItem(localKey);
        //console.log("localstate", localKey, localState);

        if (hostClientType == "desktop") {
            sessionState = localStorage.getItem(sessionKey);
        }
        else {
            sessionState = sessionStorage.getItem(sessionKey);
        }

        //console.log("sessionstate", sessionKey, sessionState);

        if (localState != null) {
            let appState = JSON.parse(localState) as AppState;

            appState.teamImages = {};

            if (sessionState != null) {
                const sessionObj = JSON.parse(sessionState) as AppState;

                appState.discoverTeams = { ...discoverTeamsInit, ...sessionObj.discoverTeams };
                appState.discoverContent = { ...discoverContentInit, ...sessionObj.discoverContent };
                appState.trendingFiles = { ...filesInit, ...sessionObj.trendingFiles };
                appState.recentFiles = { ...filesInit, ...sessionObj.recentFiles };
                appState.license = { ...licenseInit, ...sessionObj.license };
                appState.environment = { ...environmentInit, ...sessionObj.environment };
            }
            else {
                appState.discoverTeams = discoverTeamsInit;
                appState.discoverContent = discoverContentInit;
                appState.trendingFiles = filesInit;
                appState.recentFiles = filesInit;
                appState.license = licenseInit;
                appState.environment = environmentInit;
            }

            if (appState.discoverTeams.loadingState == "Loading") {
                appState.discoverTeams.loadingState = "None";
            }

            return appState;
        }
    }
    catch (err) {

    }
    return undefined;
}

export const saveState = (hostClientType: string, tenantId: string, userId: string) => (state: AppState): void => {

    const id = `${tenantId}.${userId}`;
    const localKey = `${localStorageKey}.${id}`;
    const sessionKey = `${sessionStorageKey}.${id}`;

    try {
        if (isAuthRoute(window.location.pathname)) {
            console.log("not saving auth route state")
        }
        else {
            console.log("saving to local storage");

            // var s = (new Date()).getTime();

            // const teamData = JSON.stringify(state.teams);

            // var e = (new Date()).getTime();

            // console.log("ser t", e-s);

            //const sessionD = _.omitBy(state, )

            const sessionData: Partial<AppState> = {
                discoverContent: state.discoverContent,
                discoverTeams: state.discoverTeams,
                recentFiles: state.recentFiles,
                trendingFiles: state.trendingFiles,
                license: state.license
            }

            const localCleaned: Partial<AppState> = {
                teams: state.teams,
                tenantProperties: state.tenantProperties,
                userPreferences: state.userPreferences
            };

            const stringed = JSON.stringify(localCleaned);

            localStorage.setItem(localKey, stringed);

            const sessionStringed = JSON.stringify(sessionData);

            if (hostClientType == "desktop") {
                localStorage.setItem(sessionKey, sessionStringed);
            }
            else {
                sessionStorage.setItem(sessionKey, sessionStringed);
            }
        }
    }
    catch (err) {
        console.log("ERROR", err);
    }
}

export const cacheImage = (groupId: string, image: ITeamImage): void => {

    localStorage.setItem(`nag.images.${groupId}`, JSON.stringify(image));
}

export const loadCachedImage = (groupId: string): ITeamImage => {

    var cached = localStorage.getItem(`nag.images.${groupId}`);
    if (cached && cached.length) {
        return JSON.parse(cached) as ITeamImage;
    }
    return null;
}