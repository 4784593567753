
const doFetch = (url: string, accessToken: string, format: "text" | "json" = "text"): Promise<string> => {

    console.log("doFetch", url);

    return new Promise<string>((resolve, reject) => {

        var headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);
        headers.append("Accept", "application/json");

        fetch(url, { headers: headers })
            .then((resp: any) => {
                if (resp.ok) {
                    return format === "text" ? resp.text() : resp.json();
                }
                else {
                    reject(resp.statusText);
                }
            },
                (err: any) => {
                    reject(err);
                })
            .then((text: string) => {
                resolve(text);
            });
    });
}

export default doFetch;