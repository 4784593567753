import * as microsoftTeams from '@microsoft/teams-js';
import * as React from 'react';
import { Dispatch } from 'redux';
import { AppState } from '../reducers';
import { connect } from 'react-redux';


import {
    retrieveTeamsThunk as retrieveTeams,
    retrieveTeamsInBackgroundThunk as retrieveTeamsInBackground,
    updateSuggestedTeams,
    navigateToTeamThunk
} from '../actions/teamsActions';
import { joinTeamThunk as joinTeam } from '../actions/joinTeamActions';
import * as _ from 'lodash';
import { DiscoverTeamsSearchBar } from './DiscoverTeamsSearchBar';
import { filterTeams } from '../api/filterTeams';
import { SpinnerSize, Spinner, Label } from 'office-ui-fabric-react';

import { getSuggestedTeams } from '../api/getSuggestedTeams';
import { TeamsPlaceholderSection, ITeamsPlaceholderSectionProps } from './TeamsPlaceholderSection';

import { DiscoverTeamsTable } from './DiscoverTeamsTable';
import { SectionHeading } from './SectionHeading';
import { getMyTeams } from '../api/getMyTeams';
import { MessageBar } from './MessageBar';
import { generateTeamsFilterSummary } from '../api/generateTeamsFilterSummary';
import { LoadingGeneralComponent } from './LoadingGeneral';
import { navigateToTeam } from '../api/navigateToTeam';
import { EmptySearchPanel } from './EmptySearchPanel';

export interface IDiscoverTeamsState {

}

export interface IDiscoverTeamsProps {
    appState: AppState;
    dispatch: Dispatch<any>
}

const hasFilters = (appState: AppState): boolean => {

    const { discoverTeams } = appState;
    const { searchTerm, classification } = discoverTeams;
    if ((searchTerm && searchTerm.length) || (classification && classification.length && classification != "" && classification != "all")) {
        return true;
    }
    else {
        return false;
    }
}

class DiscoverTeamsComponent extends React.Component<IDiscoverTeamsProps, IDiscoverTeamsState> {

    public constructor(props: IDiscoverTeamsProps, state: IDiscoverTeamsState) {
        super(props, state);
        this.join = this.join.bind(this);
        this.navigate = this.navigate.bind(this);
    }

    public componentDidMount() {
        //console.log("DT CDM");
        const { appState, dispatch } = this.props;
        const { loadingState } = appState.discoverTeams;

        if (loadingState == "None") {
            dispatch(retrieveTeams());
        }
        else if (loadingState == "Loaded") {
            dispatch(retrieveTeamsInBackground())
        }
    }

    // public componentWillMount() {

    //     //console.log("DT CWM");



    //     //dispatch(updateSuggestedTeams(appState))
    // }

    private join(groupName: string) {
        this.props.dispatch(joinTeam(groupName));
    }

    private navigate(groupId: string) {
        //alert("Not implemented")
        this.props.dispatch(navigateToTeamThunk(groupId));
        //const internalId = this.props.appState.teams[groupId].internalId;
        //console.log("NAV", groupId, internalId);
        //navigateToTeam(internalId);
    }

    public render() {

        const { appState, dispatch } = this.props;
        const { searchTerm, classification, loadingState } = appState.discoverTeams;
        const { tenantProperties, teamImages } = appState;
        const { hasCollaborateConfiguration, classifications } = appState.tenantProperties;

        var content = null;

        if (loadingState == "Loading" || loadingState == "None") {
            return <LoadingGeneralComponent message="Loading your list of teams, please wait..." />
        }
        else if (!hasFilters(appState)) {
            const suggestedTeams = appState.discoverTeams.suggestedTeamIds.map(i => {
                return appState.teams[i];
            }).filter(i => i != null);

            const props: ITeamsPlaceholderSectionProps = {
                teams: suggestedTeams,
                images: teamImages,
                tenantProperties,
                join: this.join,
                navigate: this.navigate,
                heading: "Suggested for you",
            }

            const myTeams = getMyTeams(appState);

            const teamsTables = hasCollaborateConfiguration && classifications.length > 0 ?
                classifications.map(classification => {
                    const classificationTeams = myTeams.filter(team => team.metaData.classification === classification.name);
                    return classificationTeams.length > 0 ?
                        <DiscoverTeamsTable key={classification.name} title={classification.name} appState={appState} dispatch={dispatch} tenantProperties={tenantProperties} teams={classificationTeams} selectedClassificationName={classification.name} maxVisibleResults={10} />
                        :
                        null;
                })
                :
                <DiscoverTeamsTable title="Your teams" appState={appState} dispatch={dispatch} tenantProperties={tenantProperties} teams={myTeams} selectedClassificationName={classification} />

            content = (
                <React.Fragment>
                    <SectionHeading heading="Suggested for you" />
                    <TeamsPlaceholderSection {...props} />
                    {teamsTables}
                </React.Fragment>
            );
        }
        else {
            const teamData = filterTeams(_.values(appState.teams), searchTerm, classification);

            if (teamData.length > 0) {
                content = <DiscoverTeamsTable appState={appState} dispatch={dispatch} tenantProperties={tenantProperties} teams={teamData} selectedClassificationName={classification} useDynamicColumns={!classification} />;
            }
            else {
                content = <EmptySearchPanel />;
            }
        }

        return (
            <React.Fragment>
                <div className="nag-filterWidth">
                    <DiscoverTeamsSearchBar />
                </div>
                <div className="nag-contentWidth">
                    {content}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: AppState) => ({ appState: state });

const discoverConnected = connect(mapStateToProps, null)(DiscoverTeamsComponent);

export { discoverConnected as DiscoverTeams };
export default discoverConnected;