import { SearchResult, Dictionary } from "../types";
import * as _ from 'lodash';
import * as _f from 'lodash/fp';


export interface RefinerItem {
    field: string;
    token: string;
    count: number;
    value: string
}

export interface Refiner {
    name: string;
    items: RefinerItem[]
}

export interface RefinerResult {
    refiners: Refiner[]
}

export interface ISharePointSearchResult {
    resultCount: number,
    results: SearchResult[],
    resultTerm: string,
    resultRefiners: RefinerResult,
    summary: string,
}

const nonNull = (f: any) => f != null;

const generateSharePointPreviewLink = (result: any) => {
    const isDocument = _.get(result, "IsDocument.Value", "false") === "true";
    const path = _.get(result, "Path.Value", "");
    const parentLink = _.get(result, "ParentLink.Value", "");
    const fileType = _.get(result, "FileType.Value", "");

    if (isDocument && path && parentLink && fileType !== "aspx") {
        // ParentLink: https://whatever.sharepoint.com/sites/somesite/Documents/Forms/AllItems.aspx
        // Path: https://whatever.sharepoint.com/sites/somesite/Documents/MyDocument.docx
        // Need to return in format of: ParentLink?id={web app relative doc url}&parent={web app relative library url}
        // Eg: https://whatever.sharepoint.com/sites/somesite/Documents/Forms/AllItems.aspx?id=/sites/somesite/Documents/MyDocument.docx&parent=/sites/somesite/Documents

        // Decoding before encoding because some characters (eg: %) will come pre-encoded, which causes issues when done twice
        return `${parentLink}?id=${encodeURIComponent(decodeURIComponent(path.split("sharepoint.com")[1]))}&parent=${parentLink.split("sharepoint.com")[1].replace("/Forms/AllItems.aspx", "")}`;
    }
    return path;
}

export const searchSharePoint = (searchTerm: string, queryTemplate: string, sharePointRoot: string, token: string,
    managedProperties: Dictionary<string>, refiners: string[] = [], startRow = 0, pageSize = 100): Promise<ISharePointSearchResult> => {

    const queryModification = `ContentClass=STS_listitem_documentlibrary IsDocument:true -FileType:aspx`

    return new Promise<ISharePointSearchResult>((resolve, reject) => {

        const apiUrl = `${sharePointRoot}/search/_api/search/postquery`;

        const extraProperties = _.values(managedProperties);

        console.log("Extra properties", extraProperties, managedProperties);

        const selectedFields: string[] = [
            "ModifiedBy",
            "UniqueId",
            "FileType",
            "IsDocument",
            "FileName",
            "LastModifiedTime",
            "Path",
            "LinkingUrl",
            "DefaultEncodingURL",
            "SiteName",
            "SiteTitle",
            "WebId",
            "SiteID",
            "Size",
            "HitHighlightedSummary",
            "OriginalPath",
            "ParentLink",
            ...extraProperties
        ]

        const refinementFilters = [
            "filetype:not(equals('html'))",
            "filetype:not(equals('aspx'))",
            ...refiners
        ]

        const refinementFiltersText = refinementFilters.filter(nonNull)
            .map(e => `"${e.replace(/"/g, "\\\"")}"`)
            .join(",");

        const fieldText: string = selectedFields.filter(nonNull)
            .map(e => `"${e}"`)
            .join(",");

        const refinersText: string = extraProperties.filter(nonNull)
            .join(",") || "";

        var body = `{
            "request": {
                "Querytext": "${searchTerm} ${queryModification}",
                "QueryTemplate":"{searchterms}${queryTemplate ? ` ${queryTemplate}` : ''}",
                "Refiners": "${refinersText}",
                "SelectProperties": [
                    ${fieldText}
                ],
                "ClientType": "SkypeTeams_FileSearch",
                "Properties": [
                    {
                        "Name": "EnableDynamicGroups",
                        "Value": {
                            "BoolVal": true,
                            "QueryPropertyValueTypeIndex": 3
                        }
                    },
                    {
                        "Name": "ClientFunction",
                        "Value": {
                            "StrVal": "AllSites",
                            "QueryPropertyValueTypeIndex": 1
                        }
                    }
                ],
                "HiddenConstraints": "",
                "RefinementFilters": [
                    ${refinementFiltersText}
                ],
                "RowLimit": ${pageSize},
                "StartRow": ${startRow},
                "HitHighlightedProperties": [
                    "Title",
                    "ModifiedBy"
                ],
                "TrimDuplicates": false,
                "RankingModelId": "ABBAABBA-AAAA-AAAA-CCCC-000000000426"
            }
        }`;

        fetch(apiUrl, {
            method: "POST",
            body,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                "Accept": "application/json"
            }
        })
            .then((resp: Response) => {
                return resp.json();
            }).then((json: any) => {

                const resultCount = json.PrimaryQueryResult.RelevantResults.TotalRows;
                const rowCount = json.PrimaryQueryResult.RelevantResults.RowCount;

                console.log("Result counts", resultCount, rowCount);

                const { Rows } = json.PrimaryQueryResult.RelevantResults.Table;
                const { RefinementResults } = json.PrimaryQueryResult;

                const refinerResult: RefinerResult = {
                    refiners: []
                };

                if (RefinementResults) {

                    refinerResult.refiners = RefinementResults.Refiners.map((r: any) => {

                        const refiner: Refiner = {
                            name: r.Name,
                            items: r.Entries.map((e: any) => {

                                const item: RefinerItem = {
                                    field: r.Name,
                                    token: e.RefinementToken,
                                    value: e.RefinementValue.replace(/(\r\n|\n|\r)/gm, " "),
                                    count: parseInt(e.RefinementCount)
                                }
                                return item;
                            })
                        }

                        return refiner;
                    });

                    console.log("Refiners", RefinementResults.Refiners, refinerResult);
                }
                else {
                    console.log("No refinement");
                }

                const results = Rows.map((r: any) => {

                    const keyed = extractValues(r);

                    let re: SearchResult = {
                        itemName: _.get(keyed, "FileName.Value", ""),
                        path: _.get(keyed, "Path.Value", ""),
                        // only office files have a linkingUrl - pdfs etc don't, so use sp preview
                        // not using default value from _.get because it won't fallback if it has a "null" value, only if it finds nothing
                        linkingUrl: _.get(keyed, "LinkingUrl.Value", "") || generateSharePointPreviewLink(keyed),
                        modifiedBy: _.get(keyed, "ModifiedBy.Value", ""),
                        fileType: _.get(keyed, "FileType.Value", ""),
                        uniqueId: _.get(keyed, "UniqueId.Value", ""),
                        siteUrl: _.get(keyed, "SiteName.Value", ""),
                        siteTitle: _.get(keyed, "SiteTitle.Value", ""),
                        webId: _.get(keyed, "WebId.Value", ""),
                        modified: new Date(keyed.LastModifiedTime.Value)
                    }

                    re.extraProperties = _f.pipe(
                        _f.keys,
                        _f.map((k: string): nameValue => {
                            return {
                                name: k,
                                value: _.get(keyed, `${managedProperties[k]}.Value`, ``),
                            };
                        }),
                        _f.keyBy((n) => n.name),
                        _f.mapValues((v) => v.value)
                    )(managedProperties);

                    return re;
                });

                console.log("RESULT", results);

                const refinerSummary = `${refiners.length ? " where" : ""} ${refiners.join(" and ")}`;
                const summary = `Found ${resultCount} documents in SharePoint matching "${searchTerm}" ${refinerSummary}`

                const result: ISharePointSearchResult = {
                    resultCount,
                    results,
                    resultTerm: searchTerm,
                    resultRefiners: refinerResult,
                    summary
                }
                resolve(result);
            });
    });
}

interface nameValue {
    name: string,
    value: string
}

interface resultCell {
    Key: string;
    Value: string;
    ValueType: string;
}

const extractValues = (searchRawResult: any): any => {
    return _.keyBy(searchRawResult.Cells, "Key")
}