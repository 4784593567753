import * as _f from 'lodash/fp';

import { AppState } from "../reducers";
import { Dictionary } from "../types";
import { getMergedMetadataFields } from "./getMergedMetadataFields";
import { SharePointField } from "../types/sharePointTenantProperties";

export const extractMetaSearchMappings = (appState: AppState): Dictionary<string> => {

    const { tenantProperties } = appState;

    if (tenantProperties && tenantProperties.hasCollaborateConfiguration) {

        const mergedFields = getMergedMetadataFields(tenantProperties.classifications);

        const dict = _f.pipe(
            _f.map((m: SharePointField) => ({ key: m.staticName, value: m.searchAlias ? m.searchAlias : m.staticName })),
            _f.keyBy(m => m.key),
            _f.mapValues(m => m.value)
        )(mergedFields);

        console.log("extractMetaDictionary", dict);

        return dict;
    }
    return {};
}