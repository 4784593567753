import * as microsoftTeams from '@microsoft/teams-js';
import { EnvironmentState } from '../types/environment';
import { ThemeStyle } from 'msteams-ui-styles-core';
import { getSSOGraphToken } from '../api/ssoTokens';
import { parseToken } from './jwt';

export const getThemeFromString = (themeStr: string): ThemeStyle => {
    let theme: ThemeStyle;
    switch (themeStr) {
        case "dark":
            theme = ThemeStyle.Dark;
            break;
        case "contrast":
            theme = ThemeStyle.HighContrast;
            break;
        case "default":
        default:
            theme = ThemeStyle.Light;
    }
    return theme;
}

export const getEnvironment = (): Promise<EnvironmentState> => {

    return new Promise<EnvironmentState>(resolve => {

        microsoftTeams.getContext(async (context: microsoftTeams.Context) => {
            const graphToken = await getSSOGraphToken(true);
            const graphAuthPayload = parseToken(graphToken);

            console.log('graph auth payload', {
                graphAuthPayload
            })

            const hasPermission = (permission: string) => graphAuthPayload.scp.indexOf(permission) >= 0;

            const environmentState: EnvironmentState = {
                sharePointRoot: `https://${context.teamSiteDomain}`,
                loginHint: context.loginHint,
                tenantId: context.tid,
                theme: context.theme,
                themeType: getThemeFromString(context.theme),
                groupId: context.groupId || null,
                teamId: context.teamId || null,
                teamName: context.teamName || null,
                sharePointSiteUrl: context.teamSiteUrl || null,
                permissions: {
                    allSitesRead: hasPermission("AllSites.Read"),
                    directoryReadAll: hasPermission("Directory.Read.All"),
                    groupReadAll: hasPermission("Group.Read.All"),
                    groupReadWriteAll: hasPermission("Group.ReadWrite.All"),
                    sitesReadAll: hasPermission("Sites.Read.All"),
                    sitesSearchAll: hasPermission("Sites.Search.All"),
                    termStoreReadAll: hasPermission("TermStore.Read.All"),
                    userRead: hasPermission("User.Read"),
                },
            };

            resolve(environmentState);
        });
    });
}