import { TeamState } from "../types/teams";
import createConfig from '../config';
import * as _f from 'lodash/fp';

export const filterTeams = (teams: TeamState[], filter: string, classification: string): TeamState[] => {

    const config = createConfig();

    let filtered = [...teams];

    if (classification && classification.length && classification != "all") {

        filtered = filtered.filter(f => {

            if (f.metaData && f.metaData["classification"] && f.metaData["classification"] == classification) {
                return true;
            }
            else {
                return false;
            }
        });
    }

    if (filter && filter.length > 0) {
        const terms = filter.split(" ")
            .filter(f => f.length)
            .map(removeNonAlphaNumeric)
            .map(f => new RegExp(f, "i"));

        filtered = filtered.filter((t: TeamState, i: number) => {

            if (!config.showPrivateTeamsInSearchResults &&
                t.visibility == "Private" &&
                !t.isMember) {
                return false;
            }
            //console.log(t.name, t.isMember, t.visibility);

            // var x = new RegExp(filter, "gi");

            // var match = x.test(t.name);
            var match = terms.find(re => re.test(t.name));
            //var match = terms.find(re => new RegExp(re, "gi").test(t.name));

            if (match) {
                //console.log("M", t.name);
                return true;
            }
            else {
                //console.log("N", t.name);
                return false;
            }
        })
    }
    else {
        filtered = filtered.filter((t: TeamState, i: number) => {

            if (!config.showPrivateTeamsInSearchResults &&
                t.visibility == "Private" &&
                !t.isMember) {
                return false;
            }
            else {
                return true;
            }
        });
    }

    if (filtered.length > 200) {
        filtered = filtered.slice(0, 200);
    }

    filtered = _f.pipe(
        _f.sortBy((g: TeamState) => g.created),
        _f.reverse
    )(filtered);

    return filtered;
}

const removeNonAlphaNumeric = (s: string) => {
    return s.replace(/[^A-Za-z0-9]/gi, "");
}